import React from 'react';
import { Card, Row, Col, Progress, Button, Spin } from 'antd';
import { 
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer 
} from 'recharts';
import { Brain, MessageCircle, TrendingUp, AlertCircle, ArrowRight, RefreshCw } from 'lucide-react';

// Types for the analysis response
interface TopicAnalysis {
  name: string;
  confidence: number;
}

interface SentimentAnalysis {
  positive: number;
  neutral: number;
  negative: number;
}

interface TranscriptAnalysis {
  keyPoints: string[];
  topics: TopicAnalysis[];
  sentiment: SentimentAnalysis;
  suggestedFollowUp: string[];
}

const TranscriptAnalysisView = ({ 
  analysis,
  isLoading,
  onRegenerate,
  clientId
}: { 
  analysis: TranscriptAnalysis | null;
  isLoading: boolean;
  onRegenerate: (clientId: string) => void;
  clientId: string;
}) => {
  if (!analysis) {
    return (
      <div style={{ width: '100%', padding: '16px', textAlign: 'center', color: '#999' }}>
        No analysis data available
      </div>
    );
  }

  // Convert sentiment data for chart
  const sentimentData = [
    { name: 'Positive', value: analysis.sentiment.positive * 100 },
    { name: 'Neutral', value: analysis.sentiment.neutral * 100 },
    { name: 'Negative', value: analysis.sentiment.negative * 100 }
  ];

  return (
    <Spin spinning={isLoading} tip="Analysing transcript...">
      <>
        {/* Regenerate Button */}
        <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            type="primary"
            onClick={() => onRegenerate(clientId)}
            loading={isLoading}
          >
            <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <RefreshCw size={16} />
              <span>Regenerate Analysis</span>
            </span>
          </Button>
        </div>

        <Row gutter={[16, 16]}>
          {/* Key Points Section */}
          <Col span={24}>
            <Card 
              title={
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Brain size={20} />
                  Key Discussion Points
                </span>
              }
            >
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {analysis.keyPoints.map((point, index) => (
                  <li key={index} style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginBottom: '12px' }}>
                    <ArrowRight size={20} style={{ flexShrink: 0, color: '#1890ff' }} />
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
            </Card>
          </Col>

          {/* Topics Section */}
          <Col xs={24} md={12}>
            <Card 
              title={
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <TrendingUp size={20} />
                  Most Occurring Words
                </span>
              }
            >
              {analysis.topics.map((topic, index) => (
                <div key={index} style={{ marginBottom: '16px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                    <span>{topic.name}</span>
                    <span>{(topic.confidence * 100).toFixed(0)}%</span>
                  </div>
                  <Progress 
                    percent={topic.confidence * 100} 
                    showInfo={false}
                    strokeColor="#1890ff"
                  />
                </div>
              ))}
            </Card>
          </Col>

          {/* Sentiment Analysis Section */}
          <Col xs={24} md={12}>
            <Card 
              title={
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <MessageCircle size={20} />
                  Sentiment Analysis
                </span>
              }
            >
              <div style={{ height: '300px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={sentimentData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip 
                      formatter={(value: number) => [`${value.toFixed(1)}%`, 'Percentage']}
                    />
                    <Bar dataKey="value" fill="#1890ff" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>

          {/* Follow-up Suggestions Section */}
          <Col span={24}>
            <Card 
              title={
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <AlertCircle size={20} />
                  Suggested Follow-up Topics
                </span>
              }
            >
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {analysis.suggestedFollowUp.map((suggestion, index) => (
                  <li key={index} style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginBottom: '12px' }}>
                    <ArrowRight size={20} style={{ flexShrink: 0, color: '#1890ff' }} />
                    <span>{suggestion}</span>
                  </li>
                ))}
              </ul>
            </Card>
          </Col>
        </Row>
      </>
    </Spin>
  );
};

export default TranscriptAnalysisView;