import React, { useState } from 'react';
import { Timeline, Space, Button, Input, Popconfirm, message, Modal, Typography } from 'antd';
import {
    UserOutlined,
    AudioOutlined,
    EditOutlined,
    DeleteOutlined,
    SaveOutlined,
    FilePdfOutlined,
    CopyOutlined
} from '@ant-design/icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import { ApiClient } from 'src/services/api-client';
import { TTranscriptSegment } from 'src/interfaces/transcript-interface';
import { useAppDispatch } from 'src/stores';
import { updateTranscriptSegmentAction } from 'src/stores/clients/clients-actions';

const { TextArea } = Input;
const { Text } = Typography;

interface EditableTranscriptProps {
    segments?: TTranscriptSegment[];
    onSegmentsChange?: (segments: TTranscriptSegment[]) => void;
    recordingDate: Date | string;
}

const EditableTranscript: React.FC<EditableTranscriptProps> = ({
    segments: initialSegments = [], // Provide default empty array
    onSegmentsChange,
    recordingDate
}) => {
    const [segments, setSegments] = useState<TTranscriptSegment[]>(initialSegments);
    const [editingSegment, setEditingSegment] = useState<string | null>(null);
    const [editedText, setEditedText] = useState<string>('');

    const dispatch = useAppDispatch();

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleEdit = (segment: TTranscriptSegment) => {
        setEditingSegment(segment.id);
        setEditedText(segment.text);
    };

    const handleSave = async (segmentId: string) => {
        try {
            const segment = segments.find(s => s.id === segmentId);
            if (!segment) return;

            await dispatch(updateTranscriptSegmentAction({
                transcriptId: segment.transcriptId,
                segmentId,
                text: editedText,
                clientId: segment.clientId
            })).unwrap();

            const updatedSegments = segments.map(segment =>
                segment.id === segmentId
                    ? { ...segment, text: editedText }
                    : segment
            );
            setSegments(updatedSegments);
            setEditingSegment(null);
            onSegmentsChange?.(updatedSegments);
            message.success('Segment updated successfully');
        } catch (error) {
            message.error('Failed to update segment');
            console.error('Update error:', error);
        }
    };

    const handleDelete = (segmentId: string) => {
        const updatedSegments = segments.filter(segment => segment.id !== segmentId);
        setSegments(updatedSegments);
        onSegmentsChange?.(updatedSegments);
        message.success('Segment deleted successfully');
    };

    const handleExport = async () => {
        try {
            Modal.confirm({
                title: 'Export Options',
                content: (
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Button
                            icon={<FilePdfOutlined />}
                            onClick={() => exportAsPDF()}
                            block
                        >
                            Export as PDF
                        </Button>
                        <Button
                            icon={<CopyOutlined />}
                            onClick={() => exportAsText()}
                            block
                        >
                            Copy to Clipboard
                        </Button>
                    </Space>
                ),
                footer: null,
                width: 400
            });
        } catch (error) {
            message.error('Failed to export transcript');
        }
    };

    const exportAsText = async () => {
        const text = segments.map(segment =>
            `[${formatTime(segment.startTime)}] ${segment.speaker}:\n${segment.text}\n`
        ).join('\n');

        try {
            await navigator.clipboard.writeText(text);
            message.success('Transcript copied to clipboard');
        } catch (error) {
            message.error('Failed to copy transcript');
        }
    };

    const getSpeakerColor = (speakerNumber: string) => {
        // Array of distinct colors for different speakers
        const colors = [
            { bg: '#f0f5ff', border: '#1890ff' }, // Blue
            { bg: '#f6ffed', border: '#52c41a' }, // Green
            { bg: '#fff7e6', border: '#fa8c16' }, // Orange
            { bg: '#fff1f0', border: '#f5222d' }, // Red
            { bg: '#f9f0ff', border: '#722ed1' }, // Purple
            { bg: '#e6fffb', border: '#13c2c2' }, // Cyan
            { bg: '#fcffe6', border: '#a0d911' }, // Lime
            { bg: '#fff0f6', border: '#eb2f96' }, // Pink
            { bg: '#f5f5f5', border: '#595959' }, // Grey
            { bg: '#fffbe6', border: '#fadb14' }  // Yellow
        ];

        // Extract speaker number and use it to determine color
        const speakerIndex = parseInt(speakerNumber.match(/\d+/)?.[0] || '0');
        return colors[speakerIndex % colors.length];
    };

    const exportAsPDF = async () => {
        if (!segments.length) return;
        
        message.loading({ content: 'Generating PDF...', key: 'pdfExport' });
        
        try {
            // Initialize PDF
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });
            
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const margin = 40;
            let yOffset = margin;
            
            // Add title
            pdf.setFontSize(16);
            pdf.text(`Transcript from ${dayjs(recordingDate).format('MMMM D, YYYY')}`, margin, yOffset);
            yOffset += 30;
            
            // Set font for content
            pdf.setFontSize(12);
            
            segments.forEach((segment, index) => {
                // Calculate heights
                const speakerText = `Person ${segment.speaker} [${formatTime(Number(segment.startTime))}]`;
                const contentText = segment.text;
                
                // Split text into lines that fit the page width
                const splitSpeaker = pdf.splitTextToSize(speakerText, pageWidth - (2 * margin));
                const splitContent = pdf.splitTextToSize(contentText, pageWidth - (2 * margin + 20));
                
                const speakerHeight = splitSpeaker.length * 15;
                const contentHeight = splitContent.length * 15;
                const totalHeight = speakerHeight + contentHeight + 20;
                
                // Check if we need a new page
                if (yOffset + totalHeight > pageHeight - margin) {
                    pdf.addPage();
                    yOffset = margin;
                }
                
                // Draw speaker and timestamp
                pdf.setFont('poppins-400', 'bold');
                pdf.text(splitSpeaker, margin, yOffset);
                yOffset += speakerHeight + 5;
                
                // Draw content
                pdf.setFont('poppins-400', 'normal');
                pdf.text(splitContent, margin + 20, yOffset);
                yOffset += contentHeight + 15;
            });
            
            pdf.save(`transcript_${dayjs(recordingDate).format('YYYY-MM-DD')}.pdf`);
            message.success({ content: 'PDF generated successfully!', key: 'pdfExport' });
        } catch (error) {
            console.error('PDF generation error:', error);
            message.error({ content: 'Failed to generate PDF', key: 'pdfExport' });
        }
    };

    return (
        <div className="transcript-content">
            <Space style={{ marginBottom: 16, justifyContent: 'space-between', width: '100%' }}>
                <Text strong>
                    Transcript from {dayjs(recordingDate).format('MMMM D, YYYY')}
                </Text>
                <Space>
                    <Button
                        icon={<CopyOutlined />}
                        onClick={exportAsText}
                    >
                        Copy
                    </Button>
                    <Button
                        icon={<FilePdfOutlined />}
                        onClick={exportAsPDF}
                        type="primary"
                    >
                        Export as PDF
                    </Button>
                </Space>
            </Space>

            <Timeline>
                {segments.map((segment) => (
                    <Timeline.Item
                        key={segment.id}
                        dot={segment.speaker.toLowerCase().includes('client') ? <UserOutlined /> : <AudioOutlined />}
                        className={`timeline-item ${segment.speaker.toLowerCase().includes('client') ? 'client' : 'practitioner'}`}
                    >
                        <div className="segment-content">
                            <div className="segment-header">
                                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                    <Space>
                                        <Text strong>Speaker #{segment.speaker}</Text>
                                        <Text type="secondary">{formatTime(segment.startTime)}</Text>
                                    </Space>
                                    <Space>
                                        {editingSegment === segment.id ? (
                                            <Button
                                                icon={<SaveOutlined />}
                                                type="primary"
                                                size="small"
                                                onClick={() => handleSave(segment.id)}
                                            >
                                                Save
                                            </Button>
                                        ) : (
                                            <>
                                                <Button
                                                    icon={<EditOutlined />}
                                                    size="small"
                                                    onClick={() => handleEdit(segment)}
                                                >
                                                    Edit
                                                </Button>
                                                <Popconfirm
                                                    title="Delete this segment?"
                                                    description="This action cannot be undone."
                                                    onConfirm={() => handleDelete(segment.id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        hidden
                                                        icon={<DeleteOutlined />}
                                                        size="small"
                                                        danger
                                                    >
                                                        Delete
                                                    </Button>
                                                </Popconfirm>
                                            </>
                                        )}
                                    </Space>
                                </Space>
                            </div>

                            {editingSegment === segment.id ? (
                                <TextArea
                                    value={editedText}
                                    onChange={(e) => setEditedText(e.target.value)}
                                    autoSize={{ minRows: 2 }}
                                    style={{ marginTop: 8 }}
                                />
                            ) : (
                                <Text>{segment.text}</Text>
                            )}
                        </div>
                    </Timeline.Item>
                ))}
            </Timeline>
        </div>
    );
};

export default EditableTranscript;