import { Modal, Skeleton, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from 'src/components/button';
import { BaseText } from 'src/components/typography';
import {
  EHomeworkType,
  TActivityData,
  TGetHomeworkResultParams,
  TGetAiConversationParams,
  THomework,
  TTaskItemData,
  TUpdateAiConversationParams,
} from 'src/interfaces/clients-interface';
import { TFile } from 'src/interfaces/common-interface';
import { TRootState, useAppDispatch } from 'src/stores';
import {
  EClientsAction,
  getHomeworkResultAction,
  getAiConversationAction,
  summariseConversationAction
} from 'src/stores/clients';
import ActivityCard from './ActivityCard';
import HomeworkActivityItem from './HomeworkActivityItem';
import HomeworkInfo from './HomeworkInfo';
import QuestionResults from './QuestionResults';
import './HomeworkHistoryDetailsModal.scss';
import { downloadHomeworkFile } from 'src/utils/common-utils';
import { EHomeworkResultStatus } from 'src/variables/enum-variables';
import {
  LikeFilled,
  LikeOutlined,
  DislikeFilled,
  DislikeOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { updateAiConversationDataAction } from 'src/stores/clients/clients-actions';
import { getSocket } from 'src/configs/socket-config';
import jsPDF from 'jspdf';


interface IProps {
  open: boolean;
  data?: TActivityData;
  historyData?: TTaskItemData;
  onPreviewFiles?: (files: TFile[]) => void;
  onClose: () => void;
}

const HomeworkHistoryDetailsModal = ({
  open,
  data,
  historyData,
  onClose,
  onPreviewFiles,
}: IProps) => {
  const dispatch = useAppDispatch();
  const isFromAssigned = !!historyData;

  // State for summarization
  const [summaryData, setSummaryData] = useState<string | null>(null);
  const [summaryWithCursor, setSummaryWithCursor] = useState<string>('');
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);

  // State to track the index for showing the refined input or thank-you message
  const [refinedInputIndex, setRefinedInputIndex] = useState<number | null>(
    null,
  );
  const [submitted, setSubmitted] = useState<boolean[]>([]); // Tracks which indexes have submitted responses
  const socket = getSocket();

  const {
    homeworkDetails,
    homeworkResult,
    loading,
    downloading,
    clientId,
    clientName,
    aiConversationData,
  } = useSelector((state: TRootState) => ({
    homeworkDetails: state.clients.homeworkDetail,
    homeworkResult: state.clients.homeworkResult,
    loading: state.loading[EClientsAction.GET_HOMEWORK_RESULT],
    downloading: state.loading[EClientsAction.DOWNLOAD_HOMEWORK_FILE],
    clientId: state.clients.clientInfo?.clientId,
    clientName: state.clients.clientInfo?.firstName,
    aiConversationData: state.clients.aiConversationData,
  }));

  const homework = isFromAssigned
    ? homeworkResult?.homework
    : homeworkDetails?.homework;
  const formattedActivityData: TActivityData | undefined =
    homeworkResult && {
      id: homeworkResult.id,
      resultText: homeworkResult.clientResponse?.responseText,
      comment: homeworkResult.feedback,
      createdAt: homeworkResult.createdAt,
      filesUrls: homeworkResult.clientAnswerImages,
      homeworkType: homeworkResult.homework?.type,
      rate: homeworkResult.rate,
      rejectText: homeworkResult.rejectReason,
      status: homeworkResult.status,
      classification: homeworkResult.classification,
    };
  const activityData = data ?? formattedActivityData;
  const isSkipped = activityData?.status === EHomeworkResultStatus.SKIPPED;

  const handleExportToPDF = () => {
    const doc = new jsPDF();

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 15;
    const maxLineWidth = pageWidth - margin * 2;
    const lineHeight = 7;
    let yOffset = margin;

    // Centered title
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    const title = 'AI Conversation and Summary';
    const titleWidth = doc.getTextWidth(title);
    doc.text(title, (pageWidth - titleWidth) / 2, yOffset);
    yOffset += lineHeight * 2;

    // Append the conversation
    if (aiConversationData && aiConversationData.length > 0) {
      aiConversationData.forEach((message, index) => {
        const isUser = message.role === 'user';
        const role = isUser ? `${clientName}:` : 'jAImee:';
        const messageContent = message.content;

        // Prepare message text
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(0, 0, 0);

        const textLines = doc.splitTextToSize(messageContent, maxLineWidth - 20);
        const blockHeight = textLines.length * lineHeight + lineHeight * 2;

        // Check if new page is needed
        if (yOffset + blockHeight > pageHeight - margin) {
          doc.addPage();
          yOffset = margin;
        }

        // Draw a rectangle (chat bubble)
        const rectX = margin;
        const rectY = yOffset - lineHeight / 2;
        const rectWidth = maxLineWidth;
        const rectHeight = blockHeight;

        // Set background color
        const fillColor = isUser ? '#e6e6e6' : '#f0f0f0'; // Light shades of gray
        doc.setFillColor(fillColor);
        doc.roundedRect(rectX, rectY, rectWidth, rectHeight, 5, 5, 'F');

        // Add role text
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text(
          role,
          rectX + 5,
          yOffset + lineHeight / 2,
          { maxWidth: rectWidth - 10 }
        );
        yOffset += lineHeight * 1.5;

        // Add message content
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(0, 0, 0);
        doc.text(
          textLines,
          rectX + 5,
          yOffset,
          { maxWidth: rectWidth - 10 }
        );

        yOffset += blockHeight - lineHeight * 2;
        yOffset += lineHeight;
      });
    } else {
      doc.setFontSize(12);
      doc.text("No AI Conversation Data Available", margin, yOffset);
      yOffset += lineHeight;
    }

    // Append the summary if it exists
    if (summaryData) {
      if (yOffset + lineHeight * 3 > pageHeight - margin) {
        doc.addPage();
        yOffset = margin;
      }
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text('Summary:', margin, yOffset);
      yOffset += lineHeight * 1.5;

      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      const wrappedSummary = doc.splitTextToSize(summaryData, maxLineWidth);
      wrappedSummary.forEach((line: string) => {
        if (yOffset + lineHeight > pageHeight - margin) {
          doc.addPage();
          yOffset = margin;
        }
        doc.text(line, margin, yOffset);
        yOffset += lineHeight;
      });
    }

    doc.save(`conversation_summary_${clientName}.pdf`);
  };


  const getHomeworkResult = (params: TGetHomeworkResultParams) => {
    dispatch(getHomeworkResultAction(params));
  };

  const getAiConversation = (params: TGetAiConversationParams) => {
    dispatch(getAiConversationAction(params));
  };

  const handleThumbsUpClick = (index: number) => {
    if (aiConversationData) {
      const conversationItem = aiConversationData[index];
      if (conversationItem.thumbsUp !== true && clientId) {
        const updatedAiConversationData = [...aiConversationData];
        updatedAiConversationData[index] = {
          ...conversationItem,
          thumbsUp: true,
        };

        const updatedParameters: TUpdateAiConversationParams = {
          messageId: aiConversationData[index].id,
          thumbsUp: true,
          clientId: clientId,
        };

        dispatch(updateAiConversationDataAction(updatedParameters));

        const newSubmitted = [...submitted];
        newSubmitted[index] = false;
        setSubmitted(newSubmitted);

        if (refinedInputIndex === index) {
          setRefinedInputIndex(null);
        }
      }
    }
  };

  const handleThumbsDownClick = (index: number) => {
    if (aiConversationData) {
      const conversationItem = aiConversationData[index];
      if (conversationItem.thumbsUp !== false && clientId) {
        const updatedAiConversationData = [...aiConversationData];
        updatedAiConversationData[index] = {
          ...conversationItem,
          thumbsUp: false,
        };

        const updatedParameters: TUpdateAiConversationParams = {
          messageId: aiConversationData[index].id,
          thumbsUp: false,
          clientId: clientId,
        };

        dispatch(updateAiConversationDataAction(updatedParameters));
        setRefinedInputIndex(index);
      }
    }
  };

  const handleDownloadFile = (attachmentId: string, fileName?: string) => {
    const homeworkAssignId = isFromAssigned
      ? historyData?.homeworkAssignId
      : homeworkDetails?.id;

    downloadHomeworkFile(
      {
        attachmentId,
        homeworkAssignId: homeworkAssignId ?? '',
        homeworkResultId: activityData?.id ?? '',
        fileName,
      },
      dispatch,
    );
  };

  useEffect(() => {
    if (!open) {
      // Clear summary data when modal is closed
      setSummaryData(null);
      setLoadingSummary(false);
    }
  }, [open]);


  useEffect(() => {
    let params: TGetHomeworkResultParams | undefined;
    if (historyData) {
      params = {
        id: historyData.homeworkAssignId ?? '',
        homeworkHistoryId: historyData.id ?? '',
      };

      if (historyData.homeworkAssignId && clientId) {
        const aiParams: TGetAiConversationParams = {
          homeworkAssignId: historyData.homeworkAssignId,
          clientId: clientId,
        };
        getAiConversation(aiParams);
      }
    }

    if (data) {
      params = {
        id: homeworkDetails?.id ?? '',
        homeworkHistoryId: data?.id ?? '',
      };
    }

    if (params) {
      getHomeworkResult(params);
    }
  }, [historyData, data]);

  interface SocketMessage {
    content: string;
  }

  const handleSummariseConversation = () => {
    if (!clientId || !historyData?.homeworkAssignId) {
      console.error("Missing clientId or homeworkAssignId");
      return;
    }

    // Connect to the specific profile room
    socket.connectGPT(clientId);

    setLoadingSummary(true); // Set loading state
    setSummaryData(''); // Clear previous summary data

    // Listen for summary data chunks in real-time
    socket.listenGptResChunk((message) => {
      if (message.content === '[END]') {
        setLoadingSummary(false);
        socket.offGptResChunk(); // Unsubscribe after streaming completes
      } else if (message.content === '[ERROR]') {
        setLoadingSummary(false);
        socket.offGptResChunk(); // Unsubscribe on error
        console.error('Error streaming summary.');
      } else {
        // Append the incoming chunk
        setSummaryData((prev) => prev + message.content); // Update summaryData with each new chunk
      }
    });

    dispatch(
      summariseConversationAction({
        clientId,
        homeworkAssignIds: [historyData.homeworkAssignId], // Pass as array
      })
    );
  };

  const renderAiConversation = () => {
    if (!aiConversationData || aiConversationData.length === 0) {
      return <div>No AI Conversation Data Available</div>;
    }

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    };

    const isSameDay = (date1: string, date2: string) => {
      return new Date(date1).toDateString() === new Date(date2).toDateString();
    };

    const isToday = (dateString: string) => {
      const date = new Date(dateString);
      const today = new Date();
      return date.toDateString() === today.toDateString();
    };

    let previousDate: string | null = null;

    return (
      <div className="conversation">
        <div className="conversation-summary">
          <div style={{ marginBottom: '20px' }}>
            {aiConversationData && aiConversationData.length >= 2 && (
              loadingSummary ? (
                <Spin />
              ) : (
                <Button type="primary" onClick={handleSummariseConversation}>
                  Summarise Conversation
                </Button>
              )
            )}
          </div>


          {/* Disclaimer message */}
          {aiConversationData && aiConversationData.length >= 2 && (
            <div style={{ fontSize: '12px', color: '#888', marginTop: '8px', maxWidth: '500px' }}>
              Note: The AI-generated summary may contain errors or omissions. Please review all details carefully. If needed, click the &quot;Summarise Conversation&quot; button again to regenerate the summary.
            </div>
          )}
          {/* Conditionally render the summary only if there's data */}
          {summaryData && (
            <div className="summary-display">
              {summaryData}
            </div>
          )}
        </div>
        {aiConversationData.map((data, index) => {
          const showDateSeparator = !isSameDay(
            previousDate ?? '',
            data.createdAt,
          );
          previousDate = data.createdAt;

          return (
            <div key={index}>
              {showDateSeparator && (
                <div className="date-separator">
                  <span>
                    {isToday(data.createdAt)
                      ? 'Today'
                      : formatDate(data.createdAt)}
                  </span>
                  <hr />
                </div>
              )}
              <div
                className={`message ${data.role} ${data.softDelete ? 'softDeleted' : ''
                  }`}
              >
                <div className="role">
                  {data.role === 'user' ? `${clientName}:` : 'jAImee:'}
                </div>
                <div className="content">{data.content}</div>
                {data.role === 'assistant' &&
                  renderThumbsControls(index, data.thumbsUp)}
                {renderAdditionalInputOrThankYou(index)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderThumbsControls = (index: number, thumbsUp: boolean | null) => (
    <div className="thumbs-container">
      <Button
        type="link"
        icon={
          thumbsUp === true ? (
            <LikeFilled style={{ color: '#48abe2' }} />
          ) : (
            <LikeOutlined style={{ color: 'gray' }} />
          )
        }
        onClick={() => handleThumbsUpClick(index)}
      />
      <Button
        type="link"
        icon={
          thumbsUp === false ? (
            <DislikeFilled style={{ color: '#ff7777' }} />
          ) : (
            <DislikeOutlined style={{ color: 'gray' }} />
          )
        }
        onClick={() => handleThumbsDownClick(index)}
      />
    </div>
  );

  const renderAdditionalInputOrThankYou = (index: number) => {
    if (submitted[index]) {
      return <div>Thank you for your feedback!</div>;
    } else if (refinedInputIndex === index) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <textarea
            id={`textarea-${index}`}
            style={{
              width: '100%',
              boxSizing: 'border-box',
              marginTop: '10px',
              marginBottom: '10px',
            }}
            placeholder="Please provide a refined response..."
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              onClick={() => {
                const textarea = document.getElementById(
                  `textarea-${index}`,
                ) as HTMLTextAreaElement;
                const additionalComments = textarea ? textarea.value : '';
                handleRefinedResponseSubmit(index, additionalComments);
              }}
              style={{
                float: 'right',
                boxSizing: 'border-box',
                padding: '5px 10px',
              }}
            >
              <CheckCircleOutlined /> Submit
            </button>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleRefinedResponseSubmit = (
    index: number,
    additionalComments: string,
  ) => {
    if (aiConversationData && aiConversationData[index] && clientId) {
      const updatedParameters: TUpdateAiConversationParams = {
        messageId: aiConversationData[index].id,
        thumbsUp: false,
        clientId: clientId,
        additionalComments: additionalComments,
      };

      dispatch(updateAiConversationDataAction(updatedParameters));
    }

    const newSubmitted = [...submitted];
    newSubmitted[index] = true;
    setSubmitted(newSubmitted);
    setRefinedInputIndex(null);
  };

  return (
    <Modal
      className="HomeworkHistoryDetailsModal"
      open={open}
      width={1020}
      closable={false}
      footer={null}
      onCancel={onClose}
    >
      {loading ? (
        <>
          <Skeleton />
          <center>
            <Spin />
          </center>
        </>
      ) : (
        <>
          <BaseText
            type="title"
            textAlign="center"
            className="HomeworkHistoryDetailsModal__title"
          >
            {homework?.title}
          </BaseText>
          {isFromAssigned && <HomeworkInfo homework={homework as THomework} />}

          {activityData && (
            <HomeworkActivityItem data={activityData} isCard={false}>
              {homework?.type === EHomeworkType.QUESTIONNAIRES &&
                !isSkipped && <QuestionResults homework={homeworkResult} />}
              {homework?.type === EHomeworkType.AI && renderAiConversation()}
              {homework?.type !== EHomeworkType.QUESTIONNAIRES &&
                activityData.resultText &&
                !isSkipped && (
                  <ActivityCard
                    data={activityData}
                    type="result"
                    className="HomeworkHistoryDetailsModal__result"
                    isCard={false}
                    downloading={downloading}
                    onPreviewFiles={onPreviewFiles}
                    onDownload={handleDownloadFile}
                  />
                )}
              {(activityData.comment ||
                activityData.rejectText ||
                activityData.rate) && (
                  <ActivityCard
                    type="comment"
                    isCard={false}
                    className="HomeworkHistoryDetailsModal__comment"
                    data={{
                      comment: activityData.comment,
                      rate: activityData.rate,
                      rejectText: activityData.rejectText,
                      title: 'Client’s comment',
                      id: 'fake Id',
                      classification: activityData.classification,
                    }}
                  />
                )}
            </HomeworkActivityItem>
          )}
        </>
      )}
      {/* Button container */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
        {homework?.type === EHomeworkType.AI && aiConversationData && aiConversationData.length >= 2 && (
          <Button
            type="primary"
            onClick={handleExportToPDF}
          >
            Export
          </Button>
        )}
        <Button
          type="default"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default HomeworkHistoryDetailsModal;
