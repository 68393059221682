import React, { useState, useEffect, Key } from 'react';
import {
  Table, Space, DatePicker, Card, Modal,
  Statistic, Select, Form, Spin,
  Typography, Tag, Alert, Empty, message, Dropdown, Menu, Checkbox, Tooltip, Tabs, Input
} from 'antd';
import {
  SearchOutlined, FileTextOutlined, BarChartOutlined,
  CalendarOutlined, CheckCircleOutlined, ClockCircleOutlined,
  MessageOutlined, LoadingOutlined, PlusOutlined,
  MobileOutlined, DownOutlined, AudioOutlined,
  FileSearchOutlined
} from '@ant-design/icons';
import { BaseText } from 'src/components/typography';
import Button from 'src/components/button';
import type { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppDispatch } from 'src/stores';
import './SessionsTab.scss';
import { DocumentType, generateDocumentAction, getTranscriptDetailsAction, getTranscriptsAction, deleteTranscriptsAction } from 'src/stores/clients/clients-actions';
import { RangePickerProps } from 'antd/es/date-picker';
import TranscriptModal from './TranscriptModal';
import { BaseDocumentType, BULK_SESSION_DOCUMENT_TYPES } from 'src/pages/clients/client-details-page-constants';
import GeneratedDocumentView from './components/GeneratedDocument';
import { GeneratedDocument } from 'src/interfaces/document-interface';
import { TTranscript } from 'src/interfaces/transcript-interface';

const { Title, Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

interface SessionsTabProps {
  clientId: string;
}

interface ProgressMetrics {
  totalSessions: number;
  averageConfidence: number;
  topTopics: { name: string; count: number }[];
  engagementScore: number;
}

interface DocumentGroup {
  name: string;
  types: BaseDocumentType[];
}

const SessionsTab: React.FC<SessionsTabProps> = ({ clientId }) => {
  const dispatch = useAppDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [progressMetrics, setProgressMetrics] = useState<ProgressMetrics | null>(null);
  const [generatingDocument, setGeneratingDocument] = useState(false);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([null, null]);
  const [analyzingMetrics, setAnalyzingMetrics] = useState(false);
  const [activeTab, setActiveTab] = useState('sessions');

  const [searchText, setSearchText] = useState('');
  const [selectedTranscript, setSelectedTranscript] = useState<TTranscript | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transcripts, setTranscripts] = useState<TTranscript[]>([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [documents, setDocuments] = useState<GeneratedDocument[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<GeneratedDocument | null>(null);
  const [generatingDocumentIds, setGeneratingDocumentIds] = useState<string[]>([]);

  // Group document types by category
  const documentTypesByCategory = BULK_SESSION_DOCUMENT_TYPES.reduce((acc, type) => {
    const category = acc.find(c => c.name === type.category);
    if (category) {
      category.types.push(type);
    } else {
      acc.push({ name: type.category, types: [type] });
    }
    return acc;
  }, [] as DocumentGroup[]);

  const fetchTranscripts = async (page = 1) => {
    if (!clientId) return;

    setLoading(true);
    try {
      const result = await dispatch(getTranscriptsAction({
        clientId,
        page,
        limit: 10,
        startDate: dateRange[0]?.startOf('day').toISOString(),
        endDate: dateRange[1]?.endOf('day').toISOString(),
      })).unwrap();

      // Add clientId to each transcript
      const transcriptsWithClientId = result.data.map((transcript: TTranscript) => ({
        ...transcript,
        clientId,
      }));

      setTranscripts(transcriptsWithClientId);
      setTotal(result.total);
      setCurrentPage(result.page);
    } catch (error) {
      console.error('Failed to fetch transcripts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewTranscript = async (record: TTranscript) => {
    if (!clientId) return;

    setModalVisible(true);
    setSelectedTranscript({
      ...record,
      segments: [],
      isLoading: true
    });
    
    try {
      const result = await dispatch(getTranscriptDetailsAction({
        transcriptId: record.id,
        clientId
      })).unwrap();

      setSelectedTranscript({
        ...result,
        id: record.id,
        clientId: clientId,
        segments: result.segments?.map(segment => ({
          ...segment,
          transcriptId: record.id,
          clientId: clientId
        })),
        isLoading: false
      });
    } catch (error) {
      console.error('Failed to fetch transcript details:', error);
      message.error('Failed to load transcript details');
      setSelectedTranscript(prev => prev ? { ...prev, isLoading: false } : null);
    }
  };

  const handleDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (dates) {
      setDateRange([
        dates[0]?.startOf('day') || null,
        dates[1]?.endOf('day') || null
      ]);
    } else {
      setDateRange([null, null]);
    }
    setCurrentPage(1); // Reset to first page when changing filters
  };

  useEffect(() => {
    fetchTranscripts();
  }, [clientId, dateRange]);

  const handleRowSelectionChange = (selectedKeys: Key[], selectedRows: TTranscript[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  const handleGenerateDocument = async (type: DocumentType) => {
    if (!clientId || selectedRowKeys.length === 0) return;

    setGeneratingDocument(true);

    // Get the selected transcripts
    const selectedTranscriptDetails = transcripts.filter(t => 
      selectedRowKeys.includes(t.id)
    );

    // Create a mapping of transcript IDs to their dates
    const transcriptDates = selectedTranscriptDetails.reduce((acc, t) => ({
      ...acc,
      [t.id]: t.recordingDate
    }), {});

    // Create a placeholder document
    const placeholderDocument: GeneratedDocument = {
      id: `doc_${Date.now()}`,
      documentType: type,
      content: "Generating document...",
      metadata: {
        transcriptIds: selectedRowKeys as string[],
        transcriptDates,
        wordCount: 0,
        estimatedReadTime: 0
      },
      generatedAt: new Date().toISOString()
    };

    // Add the placeholder and switch tabs
    setDocuments(prev => [placeholderDocument, ...prev]);
    setGeneratingDocumentIds(prev => [...prev, placeholderDocument.id]);
    setActiveTab('documents');

    try {
      const result = await dispatch(generateDocumentAction({
        transcriptId: selectedRowKeys.join(','),
        clientId,
        documentType: type
      })).unwrap();
      
      // Update the placeholder with the real document
      setDocuments(prev => prev.map(doc => 
        doc.id === placeholderDocument.id
          ? {
              ...result,
              id: placeholderDocument.id,
              documentType: type,
              metadata: {
                ...result.metadata,
                transcriptIds: selectedRowKeys as string[],
                transcriptDates
              }
            }
          : doc
      ));
      setGeneratingDocumentIds(prev => prev.filter(id => id !== placeholderDocument.id));
      message.success('Document generated successfully');
    } catch (error) {
      console.error('Failed to generate document:', error);
      // Remove the placeholder on error
      setDocuments(prev => prev.filter(doc => doc.id !== placeholderDocument.id));
      setGeneratingDocumentIds(prev => prev.filter(id => id !== placeholderDocument.id));
      Modal.error({
        title: 'Unable to Generate Document',
        content: 'There was an issue whilst generating your document. Please try again.',
      });
    } finally {
      setGeneratingDocument(false);
    }
  };

  const handleDelete = async () => {
    if (selectedRowKeys.length === 0) {
      message.warning('Please select at least one session to delete');
      return;
    }

    Modal.confirm({
      title: 'Delete Sessions',
      content: `Are you sure you want to delete ${selectedRowKeys.length} session${selectedRowKeys.length > 1 ? 's' : ''}?`,
      okText: 'Delete',
      okButtonProps: { danger: true },
      onOk: async () => {
        try {
          await dispatch(deleteTranscriptsAction({
            transcriptIds: selectedRowKeys as string[],
            clientId
          })).unwrap();
          
          message.success('Sessions deleted successfully');
          setSelectedRowKeys([]);
          fetchTranscripts(currentPage);
        } catch (error) {
          message.error('Unable to delete sessions. Please try again.');
        }
      }
    });
  };

  const columns: ColumnsType<TTranscript> = [
    {
      title: 'Date',
      dataIndex: 'recordingDate',
      key: 'recordingDate',
      sorter: (a, b) => dayjs(a.recordingDate).unix() - dayjs(b.recordingDate).unix(),
      render: (date: Date) => dayjs(date).format('MMMM D, YYYY'),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (duration: number) => `${Math.floor(duration / 60)} mins`,
    },
    {
      title: 'Segments',
      dataIndex: 'totalSegments',
      key: 'totalSegments',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: TTranscript) => (
        <Space size="middle" className="SessionsTab__actions">
          <Tooltip title="Select for document generation">
            <div className="SessionsTab__checkboxWrapper">
              <Checkbox
                checked={selectedRowKeys.includes(record.id)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleRowSelectionChange(
                    selectedRowKeys.includes(record.id) 
                      ? selectedRowKeys.filter(key => key !== record.id)
                      : [...selectedRowKeys, record.id],
                    []
                  );
                }}
              >
                Include in document
              </Checkbox>
            </div>
          </Tooltip>
          <Tooltip title="View session transcript">
            <Button
              type="primary"
              icon={<FileTextOutlined />}
              onClick={() => handleViewTranscript(record)}
            >
              View Transcript
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="SessionsTab">
      <Alert
        style={{ marginBottom: 15 }}
        message="Stream & Transcribe Sessions"
        description={
          <Space direction="vertical" size={8}>
            <span>To stream and transcribe your sessions:</span>
            <ol style={{ marginBottom: 0, paddingLeft: '20px' }}>
              <li>Log into your account on the ANTSA For Professionals application</li>
              <li>Select a client from the dropdown</li>
              <li>Tap on Notes</li>
              <li>Tap on the microphone icon</li>
              <li>After streaming, tap the upload button and your transcript will appear here</li>
            </ol>
            <div style={{ marginTop: 8, fontSize: '14px', color: 'rgba(0, 0, 0, 0.65)' }}>
              <strong>Note:</strong> ANTSA prioritises privacy and security. We don&apos;t record or store audio - 
              instead, we convert speech to text in real-time as you speak. This means your session audio 
              is never saved, only the text transcription is stored. <strong>You maintain complete control 
              over your data</strong> and can manage or delete transcripts at any time.
            </div>
            <div style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.65)' }}>
              <strong>Document Generation:</strong> When you generate documents from your transcripts, 
              they are created dynamically and exist only whilst you view them. Once you close a generated 
              document, it&apos;s immediately discarded - nothing is stored. You maintain absolute 
              privacy and control over your session analysis and documentation.
            </div>
          </Space>
        }
        type="info"
        showIcon
        icon={<MobileOutlined />}
        className="mb-6"
        closable
      />

      <Tabs 
        activeKey={activeTab} 
        onChange={setActiveTab}
        className="SessionsTab__tabs"
      >
        <TabPane 
          tab={
            <span>
              <AudioOutlined /> Sessions
            </span>
          } 
          key="sessions"
        >
          <div className="SessionsTab__header">
            <div className="SessionsTab__headerLeft">
              <BaseText type="caption">
                Filter sessions by date range
              </BaseText>
              <RangePicker
                placeholder={['Start Date', 'End Date']}
                onChange={handleDateRangeChange}
                className="SessionsTab__datePicker"
              />
            </div>
            <div className="SessionsTab__headerRight">
              <Space>
                <Button
                  danger
                  onClick={handleDelete}
                  disabled={selectedRowKeys.length === 0}
                >
                  Delete Selected
                </Button>
                <Dropdown
                  overlay={
                    <Menu>
                      {documentTypesByCategory.map((category) => (
                        <Menu.ItemGroup key={category.name} title={category.name}>
                          {category.types.map((docType) => (
                            <Menu.Item
                              key={docType.type}
                              icon={docType.icon ? <docType.icon /> : <PlusOutlined />}
                              onClick={() => handleGenerateDocument(docType.type)}
                              disabled={generatingDocument}
                            >
                              {docType.title}
                            </Menu.Item>
                          ))}
                        </Menu.ItemGroup>
                      ))}
                    </Menu>
                  }
                  trigger={['click']}
                  disabled={selectedRowKeys.length === 0 || generatingDocument}
                >
                  <Button
                    type="primary"
                    icon={<BarChartOutlined />}
                    loading={generatingDocument}
                  >
                    Generate Document ({selectedRowKeys.length} selected) <DownOutlined />
                  </Button>
                </Dropdown>
              </Space>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={transcripts}
            rowKey="id"
            className="SessionsTab__table"
            loading={loading}
            pagination={{
              total,
              current: currentPage,
              pageSize: 10,
              showTotal: (total) => `Total ${total} transcripts`,
              onChange: (page) => fetchTranscripts(page),
            }}
          />
        </TabPane>

        <TabPane 
          tab={
            <span>
              <FileSearchOutlined /> Documents ({documents.length})
            </span>
          } 
          key="documents"
        >
          <GeneratedDocumentView
            documents={documents}
            documentTypes={BULK_SESSION_DOCUMENT_TYPES}
            onGenerateDocument={handleGenerateDocument}
            isGenerating={false}
            transcriptId={selectedRowKeys.join(',')}
            generatingDocumentIds={generatingDocumentIds}
          />
        </TabPane>
      </Tabs>

      <TranscriptModal
        transcript={selectedTranscript}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          setSelectedTranscript(null);
        }}
      />

      <Input
        placeholder="Search documents..."
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ maxWidth: '300px' }}
      />
    </div>
  );
};

export default SessionsTab;