import React, { FC, useState, useEffect } from 'react';
import './AITab.scss';
import { BaseText } from 'src/components/typography';
import Button from 'src/components/button';
import { showSuccessToast, showErrorToast } from 'src/components/toast/Toast';
import { getAIConversationsAction, getAiConversationAction } from 'src/stores/clients/clients-actions';
import { IAIConversation } from 'src/interfaces/clients-interface';
import { Spin, Tabs, Modal, Card, Empty, Table, Space, Tag, Tooltip, DatePicker } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { RangePickerProps } from 'antd/es/date-picker';
import { useAppDispatch } from 'src/stores';
import { Checkbox } from 'antd';
import { summariseConversationAction } from 'src/stores/clients';
import { getSocket } from 'src/configs/socket-config';
import { 
  DownloadOutlined, 
  MessageOutlined, 
  FileTextOutlined,
  BarChartOutlined,
  CalendarOutlined,
  RobotOutlined,
  FileSearchOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import jsPDF from 'jspdf';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Alert } from 'antd';
import dayjs from 'dayjs';
import { GeneratedDocument } from 'src/interfaces/document-interface';
import GeneratedDocumentView from '../sessions/components/GeneratedDocument';
import { BaseDocumentType } from 'src/pages/clients/client-details-page-constants';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

// Define the document types for AI summaries
const AI_DOCUMENT_TYPES: BaseDocumentType[] = [
  {
    type: 'summary',
    title: 'AI Summary',
    description: 'AI-generated summary of selected conversations',
    category: 'Analysis',
    icon: BarChartOutlined
  }
];

const AITab: FC<{ clientId: string }> = ({ clientId }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingConversation, setLoadingConversation] = useState(false);
  const [conversations, setConversations] = useState<IAIConversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<IAIConversation | null>(null);
  const [selectedConversationIds, setSelectedConversationIds] = useState<string[]>([]);
  const [summaryData, setSummaryData] = useState<string | null>(null);
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const socket = getSocket();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('conversations');
  const [documents, setDocuments] = useState<GeneratedDocument[]>([]);
  const [generatingDocumentIds, setGeneratingDocumentIds] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([null, null]);

  // Fix the date range change handler with proper typing
  const handleDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (dates) {
      setDateRange([
        dates[0]?.startOf('day') || null,
        dates[1]?.endOf('day') || null
      ]);
    } else {
      setDateRange([null, null]);
    }
  };

  // Fetch conversations on mount
  useEffect(() => {
    const fetchConversations = async () => {
      setLoading(true);
      try {
        const result = await dispatch(getAIConversationsAction({ 
          clientId,
          startDate: dateRange[0]?.toISOString(),
          endDate: dateRange[1]?.toISOString()
        })).unwrap();
        setConversations(result.conversations);
      } catch (error) {
        showErrorToast('Failed to load AI conversations');
        console.error('Error fetching conversations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchConversations();
  }, [dispatch, clientId, dateRange]);

  // Handle selecting a conversation
  const handleSelectConversation = async (conversation: IAIConversation) => {
    setIsModalVisible(true);
    setSelectedConversation({
      ...conversation,
      messages: []
    });
    setLoadingConversation(true);
    
    try {
      const result = await dispatch(getAiConversationAction({
        clientId,
        homeworkAssignId: conversation.homeworkAssignId
      })).unwrap();
      
      setSelectedConversation({
        ...conversation,
        messages: result
      });
    } catch (error) {
      showErrorToast('Failed to load conversation details');
      console.error('Error fetching conversation:', error);
    } finally {
      setLoadingConversation(false);
    }
  };

  // Modified summary handler
  const handleSummariseConversations = async () => {
    if (!clientId || selectedConversationIds.length === 0) {
      showErrorToast('Please select at least one conversation to summarise');
      return;
    }

    // Get the selected conversations
    const selectedConvos = conversations.filter(conv => 
      selectedConversationIds.includes(conv.homeworkAssignId)
    );

    // Create a mapping of conversation IDs to their dates
    const conversationDates = selectedConvos.reduce((acc, conv) => ({
      ...acc,
      [conv.homeworkAssignId]: conv.createdAt
    }), {});

    // Create a placeholder document
    const placeholderDocument: GeneratedDocument = {
      id: `summary_${Date.now()}`,
      content: "Generating summary...",
      documentType: 'summary',
      generatedAt: new Date().toISOString(),
      metadata: {
        wordCount: 0,
        estimatedReadTime: 0,
        transcriptIds: selectedConversationIds,
        transcriptDates: conversationDates
      }
    };

    // Add the placeholder and switch tabs
    setDocuments(prev => [placeholderDocument, ...prev]);
    setGeneratingDocumentIds(prev => [...prev, placeholderDocument.id]);
    setActiveTab('summaries');
    setLoadingSummary(true);

    socket.connectGPT(clientId);
    let newSummary = '';

    return new Promise<void>((resolve, reject) => {
      socket.listenGptResChunk((message) => {
        if (message.content === '[END]') {
          setLoadingSummary(false);
          socket.offGptResChunk();
          
          // Calculate word count and read time
          const wordCount = newSummary.split(/\s+/).length;
          const estimatedReadTime = Math.ceil(wordCount / 200); // Assuming 200 words per minute

          // Update the placeholder with the real summary
          setDocuments(prev => prev.map(doc => 
            doc.id === placeholderDocument.id 
              ? { 
                  ...doc, 
                  content: newSummary,
                  metadata: {
                    ...doc.metadata,
                    wordCount,
                    estimatedReadTime
                  }
                }
              : doc
          ));
          setGeneratingDocumentIds(prev => prev.filter(id => id !== placeholderDocument.id));
          resolve();
        } else if (message.content === '[ERROR]') {
          setLoadingSummary(false);
          socket.offGptResChunk();
          // Remove the placeholder on error
          setDocuments(prev => prev.filter(doc => doc.id !== placeholderDocument.id));
          setGeneratingDocumentIds(prev => prev.filter(id => id !== placeholderDocument.id));
          showErrorToast('Error generating summary');
          reject(new Error('Failed to generate summary'));
        } else {
          newSummary += message.content;
          setSummaryData(newSummary);
        }
      });

      dispatch(
        summariseConversationAction({
          clientId,
          homeworkAssignIds: selectedConversationIds
        })
      );
    });
  };

  // Add handler for checkbox selection
  const handleConversationSelect = (conversationId: string) => {
    setSelectedConversationIds(prev => 
      prev.includes(conversationId)
        ? prev.filter(id => id !== conversationId)
        : [...prev, conversationId]
    );
  };

  const conversationColumns: ColumnsType<IAIConversation> = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a: IAIConversation, b: IAIConversation) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      render: (date: string) => dayjs(date).format('MMMM D, YYYY'),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title: string, record: IAIConversation) => 
        title || `Conversation from ${dayjs(record.createdAt).format('MMMM D, YYYY')}`,
    },
    {
      title: 'Messages',
      dataIndex: 'messageCount',
      key: 'messageCount',
      render: (_: unknown, record: IAIConversation) => record.messages?.length || 0,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: IAIConversation) => (
        <Space size="middle" className="AITab__actions">
          <Tooltip title="Select for summary generation">
            <div className="AITab__checkboxWrapper">
              <Checkbox
                checked={selectedConversationIds.includes(record.homeworkAssignId)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleConversationSelect(record.homeworkAssignId);
                }}
              >
                Include in summary
              </Checkbox>
            </div>
          </Tooltip>
          <Tooltip title="View conversation details">
            <Button
              type="primary"
              icon={<MessageOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                setIsModalVisible(true);
                handleSelectConversation(record);
              }}
            >
              View Conversation
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  // Show loading state
  if (loading) {
    return (
      <div className="AITab" style={{ textAlign: 'center', padding: '40px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="AITab">
      <Alert
        closable
        message="Note"
        description="The AI-generated summary may contain errors or omissions. Please review all details carefully."
        type="info"
        showIcon
        style={{ marginBottom: '24px' }}
      />

      <Tabs 
        activeKey={activeTab} 
        onChange={setActiveTab}
        className="AITab__tabs"
      >
        <TabPane 
          tab={
            <span>
              <RobotOutlined /> Conversations
            </span>
          } 
          key="conversations"
        >
          <div className="AITab__header">
            <div className="AITab__headerLeft">
              <BaseText type="caption">
                Filter conversations by date range
              </BaseText>
              <RangePicker
                placeholder={['Start Date', 'End Date']}
                onChange={handleDateRangeChange}
                className="AITab__datePicker"
                value={dateRange}
              />
            </div>
            <div className="AITab__headerRight">
              <Button
                type="primary"
                onClick={handleSummariseConversations}
                loading={loadingSummary}
                disabled={selectedConversationIds.length === 0}
                icon={<BarChartOutlined />}
                size="middle"
                className="AITab__actionButton"
              >
                Generate Summary ({selectedConversationIds.length} selected)
              </Button>
            </div>
          </div>

          <Table
            columns={conversationColumns}
            dataSource={conversations}
            rowKey="id"
            className="AITab__table"
            loading={loading}
            pagination={{
              pageSize: 10,
              showTotal: (total) => `Total ${total} conversations`,
            }}
          />
        </TabPane>

        <TabPane 
          tab={
            <span>
              <FileSearchOutlined /> Summaries ({documents.length})
            </span>
          } 
          key="summaries"
        >
          <GeneratedDocumentView
            documents={documents}
            documentTypes={AI_DOCUMENT_TYPES}
            onGenerateDocument={handleSummariseConversations}
            isGenerating={loadingSummary}
            transcriptId={selectedConversationIds.join(',')}
            generatingDocumentIds={generatingDocumentIds}
          />
        </TabPane>
      </Tabs>

      <Modal
        title={selectedConversation?.title || "Conversation Details"}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedConversation(null);
        }}
        footer={
          <div className="AITab__modalFooter">
            <Space>
              <Button onClick={() => {
                setIsModalVisible(false);
                setSelectedConversation(null);
              }}>
                Close
              </Button>
            </Space>
          </div>
        }
        width={800}
        className="AITab__modal"
      >
        {loadingConversation ? (
          <div style={{ padding: '40px', textAlign: 'center' }}>
            <Spin size="large" tip="Loading conversation..." />
          </div>
        ) : selectedConversation ? (
          <div className="AITab__chatSection">
            <div className="AITab__chatMetadata">
              <BaseText type="caption">
                Started on {new Date(selectedConversation.createdAt).toLocaleString()}
              </BaseText>
            </div>
            <div className="AITab__chatbotBox AITab__chatbotBox--scrollable">
              {selectedConversation.messages?.map((msg, index) => (
                <div 
                  key={msg.id || index} 
                  className={`AITab__chatMessage AITab__chatMessage--${msg.role}`}
                >
                  <div className="AITab__chatMessageHeader">
                    <strong>{msg.role === 'user' ? 'You' : 'jAImee'}</strong>
                    <span className="AITab__chatMessageTime">
                      {msg.createdAt ? new Date(msg.createdAt).toLocaleTimeString() : ''}
                    </span>
                  </div>
                  <div className="AITab__chatMessageContent">
                    <ReactMarkdown 
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code: ({node, inline, ...props}) => (
                          <code className="AITab__chatMessageCode" {...props} />
                        ),
                        pre: ({node, ...props}) => (
                          <pre className="AITab__chatMessagePre" {...props} />
                        ),
                      }}
                    >
                      {msg.content}
                    </ReactMarkdown>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="AITab__loadingState">
            <Spin size="large" />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AITab; 