import jsPDF from 'jspdf';
import dayjs from 'dayjs';

interface DocumentMetadata {
  wordCount?: number;
  estimatedReadTime?: number;
  transcriptIds?: string[];
  transcriptDates?: Record<string, string>;
}

export interface ExportableDocument {
  title: string;
  content: string;
  generatedAt: string | Date;
  metadata: DocumentMetadata;
}

export const exportToPDF = (document: ExportableDocument) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 20;
  let currentY = margin;
  const maxWidth = pageWidth - (margin * 2);

  // Add title and metadata
  doc.setFontSize(16);
  doc.setFont('helvetica', 'bold');
  doc.text(document.title, margin, currentY);
  currentY += 10;

  doc.setFontSize(12);
  doc.setFont('helvetica', 'normal');
  doc.text(`Generated: ${dayjs(document.generatedAt).format('MMMM D, YYYY h:mm A')}`, margin, currentY);
  currentY += 8;
  if (document.metadata.wordCount) {
    doc.text(`Word count: ${document.metadata.wordCount} words`, margin, currentY);
    currentY += 8;
  }
  if (document.metadata.estimatedReadTime) {
    doc.text(`Estimated read time: ${document.metadata.estimatedReadTime} minutes`, margin, currentY);
    currentY += 15;
  }

  // Process the markdown content line by line
  const lines = document.content.split('\n');
  lines.forEach(line => {
    const trimmedLine = line.trim();
    if (!trimmedLine) {
      currentY += 4; // Add some space for empty lines
      return;
    }

    // Process the line based on its type
    let processedText = '';
    let fontSize = 11;
    let indent = 0;
    let font = 'normal';

    if (trimmedLine.startsWith('# ')) {
      processedText = trimmedLine.substring(2);
      fontSize = 16;
      font = 'bold';
      currentY += 5;
    } else if (trimmedLine.startsWith('## ')) {
      processedText = trimmedLine.substring(3);
      fontSize = 14;
      font = 'bold';
      currentY += 4;
    } else if (trimmedLine.startsWith('### ')) {
      processedText = trimmedLine.substring(4);
      fontSize = 12;
      font = 'bold';
      currentY += 3;
    } else if (trimmedLine.startsWith('- ')) {
      processedText = '• ' + trimmedLine.substring(2);
      indent = 10;
    } else if (trimmedLine.match(/^\d+\./)) {
      processedText = trimmedLine;
      indent = 10;
    } else {
      processedText = trimmedLine;
    }

    // Set font properties
    doc.setFontSize(fontSize);
    doc.setFont('helvetica', font);

    // Split text to fit width
    const splitText = doc.splitTextToSize(processedText, maxWidth - indent);
    
    // Check if we need a new page
    if (currentY + (splitText.length * 7) > pageHeight - margin) {
      doc.addPage();
      currentY = margin;
    }

    // Add each line of wrapped text
    splitText.forEach((textLine: string) => {
      doc.text(textLine, margin + indent, currentY);
      currentY += 7;
    });

    // Add some spacing after headers and paragraphs
    if (font === 'bold' || processedText.length > 50) {
      currentY += 4;
    }
  });

  // Generate filename and save
  const filename = `${document.title.toLowerCase().replace(/\s+/g, '_')}_${dayjs(document.generatedAt).format('YYYY-MM-DD')}.pdf`;
  doc.save(filename);
}; 