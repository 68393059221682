import { Checkbox, Radio, Button } from 'antd';
import { useMemo } from 'react';
import Collapse from 'src/components/collapse';
import { BaseText } from 'src/components/typography';
import { TAnswerQuestion, TGetHomeworkResultResponse } from 'src/interfaces/clients-interface';
import { convertHtmlTextToPlain } from 'src/utils/common-utils';
import { EHomeworkResultStatus, EQuestionType, EQuestionViewType } from 'src/variables/enum-variables';
import ActivityCard from './ActivityCard';
import './QuestionResults.scss';
import SeverityTable from './SeverityTable';
import SummaryTable from './SummaryTable';
import { DownloadOutlined } from '@ant-design/icons';

interface IProps {
  homework?: TGetHomeworkResultResponse;
}

interface SeverityTableRow {
  category: string;
  level: string;
  range: string;
}

const QuestionResults = ({ homework }: IProps) => {
  const isSpecialQuestion = homework?.result;

  const questionAndAnswers = useMemo(() => {
    const questions = [...(homework?.homeworkQuestions ?? [])];
    const sortedAnswers: TAnswerQuestion[] = [];
    const answerQuestions = homework?.clientResponse?.answerQuestion ?? [];
    const sortedQuestions = questions.sort((a, b) => a.index - b.index) ?? [];
    const formattedQuestions = isSpecialQuestion
      ? sortedQuestions.filter((question) => question.type !== EQuestionViewType.PHOTO)
      : sortedQuestions.filter((question) => question.type === EQuestionViewType.QUESTION);
    const filteredQuestionsIds = formattedQuestions.map((item) => item.id);
    const questionList = isSpecialQuestion
      ? sortedQuestions.filter((question) => question.type === EQuestionViewType.QUESTION)
      : [];

    filteredQuestionsIds.forEach((id) => {
      const item = answerQuestions.find((answer) => answer.questionId === id);
      item && sortedAnswers.push(item);
    });

    const questionAndAnswerList =
      filteredQuestionsIds?.map((id, index) => {
        const question = formattedQuestions[index];
        const answer = sortedAnswers[index];

        return {
          id,
          question: question.question,
          order: index + questionList?.length - formattedQuestions?.length + 1,
          type: question.questionType,
          viewType: question.type,
          options: question.options,
          answerText: answer?.answerText,
          answerChoices: answer?.answerChoices,
          title: question.title,
          description: question.description,
        };
      }) ?? [];

    return questionAndAnswerList;
  }, [homework]);

  const exportToPDF = () => {
    if (!homework?.result) return;

    // Format severity data more cleanly
    const severityTable = Object.entries(homework.result.severity)
      .map(([category, levels]) => {
        // Skip 'index' category as it's not needed
        if (category === 'index') return null;
        
        return Object.entries(levels)
          .map(([level, range]) => ({
            category: category.charAt(0).toUpperCase() + category.slice(1),
            level,
            range: `${range.from}${range.to ? ' - ' + range.to : '+'}`
          }));
      })
      .flat()
      .filter((item): item is SeverityTableRow => item !== null)
      .sort((a, b) => {
        // Sort order: Normal, Mild, Moderate, Severe, Extremely Severe
        const order = ['Normal', 'Mild', 'Moderate', 'Severe', 'Extremely Severe'];
        return order.indexOf(a.level) - order.indexOf(b.level);
      });

    const content = `
      <html>
        <head>
          <title>Homework Results</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            .header { font-size: 24px; margin-bottom: 20px; }
            .section { margin-bottom: 30px; }
            .section-title { font-size: 18px; margin-bottom: 10px; }
            .table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            .table th, .table td { 
              border: 1px solid #ddd; 
              padding: 8px; 
              text-align: left; 
            }
            .table th { background-color: #f5f5f5; }
            .question { margin-bottom: 15px; }
            .answer { margin-left: 20px; }
            .severity-table { margin-top: 20px; }
          </style>
        </head>
        <body>
          <div class="header">Homework Results</div>
          
          <div class="section">
            <div class="section-title">Summary Scores</div>
            <table class="table">
              <tr>
                <th>Category</th>
                <th>Score</th>
              </tr>
              ${Object.entries(homework.result.summary)
                .filter(([key]) => key !== 'total')
                .map(([_, value]) => {
                  const item = value as { score: number; category: string };
                  return `
                    <tr>
                      <td>${item.category}</td>
                      <td>${item.score}</td>
                    </tr>
                  `;
                }).join('')}
              <tr>
                <td><strong>Total</strong></td>
                <td><strong>${homework.result.summary.total}</strong></td>
              </tr>
            </table>
          </div>

          <div class="section">
            <div class="section-title">Client's Responses</div>
            ${questionAndAnswers.map((item) => {
              if (item.viewType === EQuestionViewType.FORM_HEADER) {
                return `
                  <div class="question form-header">
                    <h3>${item.title || ''}</h3>
                    <div>${item.description || ''}</div>
                  </div>
                `;
              }
              return `
                <div class="question">
                  <strong>${item.order}. ${item.question}</strong>
                  ${item.answerChoices ? `
                    <div class="answer">
                      ${Object.entries(item.options)
                        .map(([key, value]) => `
                          ${item.answerChoices?.includes(key) ? '✓' : '○'} ${value}
                        `).join('<br>')}
                    </div>
                  ` : ''}
                  ${item.answerText ? `
                    <div class="answer">
                      ${item.answerText}
                    </div>
                  ` : ''}
                </div>
              `;
            }).join('')}
          </div>
        </body>
      </html>
    `;

    // Create blob and download
    const blob = new Blob([content], { type: 'text/html' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `homework-results-${homework.id}.html`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="QuestionResults">
      {!isSpecialQuestion && homework?.status !== EHomeworkResultStatus.SKIPPED && (
        <ActivityCard
          isCard={false}
          data={{ title: 'Homework result', id: homework?.id ?? '' }}
          className="QuestionResults__result"
        >
          {questionAndAnswers.length &&
            questionAndAnswers.map((item, index: number) => {
              return (
                <div className="QuestionResults__QA" key={item.id}>
                  <BaseText type="button" className="QuestionResults__QA-question">
                    {index + 1}. {convertHtmlTextToPlain(item.question ?? '')}
                  </BaseText>

                  <div className="QuestionResults__QA-result">
                    {item.answerText && (
                      <BaseText className="QuestionResults__QA-answerText">{item.answerText}</BaseText>
                    )}
                    {item.answerChoices && (
                      <div className="QuestionResults__QA-answerChoices">
                        {Object.keys(item.options)?.map((key) => (
                          <>
                            {item.type === EQuestionType.MULTI_CHOICE && (
                              <Checkbox checked={item.answerChoices.includes(key)} disabled>
                                {item.options[key]}
                              </Checkbox>
                            )}
                            {item.type === EQuestionType.SINGLE_CHOICE && (
                              <Radio checked={item.answerChoices.includes(key)} disabled>
                                {item.options[key]}
                              </Radio>
                            )}
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </ActivityCard>
      )}

      {isSpecialQuestion && (
        <>
          <ActivityCard
            isCard={false}
            data={{ title: 'Homework result', id: homework?.id ?? '' }}
            className="QuestionResults__result"
          >
            <div className="QuestionResults__scores">
              <div className="QuestionResults__scores-table summary">
                <BaseText type="caption">Result</BaseText>
                <SummaryTable data={homework?.result?.summary} />
              </div>
              <div className="QuestionResults__scores-table">
                <BaseText type="caption">Severity</BaseText>
                <SeverityTable data={homework?.result?.severity} />
              </div>
            </div>
          </ActivityCard>

          <Collapse
            defaultActiveKey={['item']}
            className="QuestionResults__collapse"
            items={[
              {
                key: 'item',
                header: (
                  <div className="QuestionResults__collapse-header">
                    <BaseText inline type="title">
                      Client&apos;s Responses
                    </BaseText>
                    <BaseText inline type="caption">
                      Expand/Collapse
                    </BaseText>
                  </div>
                ),
                content:
                  questionAndAnswers.length &&
                  questionAndAnswers.map((item) => {
                    if (item.viewType === EQuestionViewType.FORM_HEADER)
                      return (
                        <div key={item.id} className="QuestionResults__QA-formHeader">
                          <BaseText
                            dangerouslyText={item.title ?? ''}
                            type="body2"
                            className="QuestionResults__QA-formHeader-title"
                          />
                          <div
                            dangerouslySetInnerHTML={{ __html: item.description ?? '' }}
                            className="QuestionResults__QA-formHeader-description"
                          />
                        </div>
                      );

                    return (
                      <div className="QuestionResults__QA-item" key={item.id}>
                        <div className="QuestionResults__QA-item-label">
                          <BaseText inline>{item.order}.&nbsp;</BaseText>
                          <BaseText dangerouslyText={item.question ?? ''} inline />
                        </div>
                        <div className="QuestionResults__QA-item-value">
                          {item.answerChoices &&
                            Object.keys(item.options)?.map((key) => (
                              <div
                                className={`QuestionResults__QA-item-value-option ${
                                  item.answerChoices.includes(key) ? 'checked' : ''
                                }`}
                                key={key}
                              >
                                {item.options[key]}
                              </div>
                            ))}
                        </div>
                      </div>
                    );
                  }),
              },
            ]}
          />
        </>
      )}

      {isSpecialQuestion && (
        <div className="QuestionResults__footer">
          <Button 
            icon={<DownloadOutlined />}
            onClick={exportToPDF}
            className="QuestionResults__export-btn"
          >
            Export PDF
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuestionResults;
