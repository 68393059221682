import './ChartLegend.scss';
import { BaseText } from 'src/components/typography';

interface ChartLegendProps {
  visibleLines: {
    depression: boolean;
    anxiety: boolean;
    stress: boolean;
  };
  onToggle: (key: 'depression' | 'anxiety' | 'stress') => void;
}

const DASS_COLORS = {
  depression: '#2E5BFF',
  anxiety: '#8C54FF',
  stress: '#00C1D4'
};

const ChartLegend = ({ visibleLines, onToggle }: ChartLegendProps) => {
  return (
    <div className="chart-legend">
      {Object.entries(DASS_COLORS).map(([key, color]) => (
        <div 
          key={key}
          className={`legend-item ${!visibleLines[key as keyof typeof visibleLines] ? 'inactive' : ''}`}
          data-type={key}
          onClick={() => onToggle(key as keyof typeof visibleLines)}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onToggle(key as keyof typeof visibleLines);
            }
          }}
        >
          <span 
            className="legend-color" 
            style={{ backgroundColor: color }} 
          />
          <span className="legend-label">
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ChartLegend;
