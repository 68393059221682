import React, { useState } from 'react';
import { 
  Input, 
  Select, 
  Button, 
  Empty, 
  Spin,
  Table,
  Space,
  Tag,
  Modal,
  Card
} from 'antd';
import {
  SearchOutlined,
  DownloadOutlined,
  EditOutlined,
  SaveOutlined,
  CopyOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { GeneratedDocument } from 'src/interfaces/document-interface';
import { BaseDocumentType } from 'src/pages/clients/client-details-page-constants';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import dayjs from 'dayjs';
import './GeneratedDocument.scss';
import { DocumentType } from 'src/stores/clients/clients-actions';
import { BaseText } from 'src/components/typography';
import { exportToPDF } from 'src/utils/document-export';

interface GeneratedDocumentViewProps {
  documents: GeneratedDocument[];
  documentTypes: BaseDocumentType[];
  onGenerateDocument: (type: DocumentType) => Promise<void>;
  isGenerating: boolean;
  transcriptId: string;
  generatingDocumentIds?: string[];
}

interface DocumentTypeCategory {
  name: string;
  types: BaseDocumentType[];
}

const GeneratedDocumentView = ({ 
  documents, 
  documentTypes,
  onGenerateDocument,
  isGenerating,
  transcriptId,
  generatingDocumentIds
}: GeneratedDocumentViewProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState<string>('all');
  const [selectedDocument, setSelectedDocument] = useState<GeneratedDocument | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editableContent, setEditableContent] = useState('');

  const filteredDocuments = documents.filter((doc): doc is GeneratedDocument => {
    if (!doc) return false;
    const matchesSearch = (doc.content?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
                         (doc.documentType?.toLowerCase() || '').includes(searchTerm.toLowerCase());
    const matchesType = selectedType === 'all' || doc.documentType === selectedType;
    return matchesSearch && matchesType;
  });

  const sortedDocuments = [...filteredDocuments].sort((a, b) => 
    new Date(b.generatedAt).getTime() - new Date(a.generatedAt).getTime()
  );

  const handleViewDocument = (document: GeneratedDocument) => {
    setSelectedDocument(document);
    setEditableContent(document.content);
    setModalVisible(true);
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(editableContent);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleExport = () => {
    if (!selectedDocument) return;

    const documentTitle = documentTypes.find(t => t.type === selectedDocument.documentType)?.title || 'Document';
    
    exportToPDF({
      title: documentTitle,
      content: editableContent,
      generatedAt: selectedDocument.generatedAt,
      metadata: selectedDocument.metadata
    });
  };

  const columns = [
    {
      title: 'Generated',
      dataIndex: 'generatedAt',
      key: 'generatedAt',
      sorter: (a: GeneratedDocument, b: GeneratedDocument) => 
        dayjs(a.generatedAt).unix() - dayjs(b.generatedAt).unix(),
      render: (date: string, record: GeneratedDocument) => (
        generatingDocumentIds?.includes(record.id) ? (
          <span>
            <LoadingOutlined style={{ marginRight: 8 }} />
            Generating...
          </span>
        ) : dayjs(date).format('MMMM D, YYYY h:mm A')
      ),
    },
    {
      title: 'Type',
      dataIndex: 'documentType',
      key: 'documentType',
      render: (type: string) => (
        <Tag color="blue">
          {documentTypes.find(t => t.type === type)?.title || type}
        </Tag>
      ),
    },
    {
      title: 'Sessions Analysed',
      key: 'sessions',
      render: (_: unknown, record: GeneratedDocument) => (
        <div className="generated-document-container__summaryConversations">
          {record.metadata.transcriptIds?.map((id) => (
            <Tag key={id} className="generated-document-container__conversationTag">
              {dayjs(record.metadata.transcriptDates?.[id]).format('MMMM D, YYYY')}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: 'Details',
      key: 'details',
      render: (_: unknown, record: GeneratedDocument) => (
        <Space>
          <Tag color="default">{`${record.metadata.wordCount} words`}</Tag>
          <Tag color="default">{`${record.metadata.estimatedReadTime} min read`}</Tag>
        </Space>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: GeneratedDocument) => (
        <Space>
          <Button
            type="link"
            className="generated-document-container__viewButton"
            onClick={() => handleViewDocument(record)}
            disabled={generatingDocumentIds?.includes(record.id)}
          >
            View Document
          </Button>
          <Button 
            type="text" 
            icon={<DownloadOutlined />}
            onClick={() => handleExport()}
            disabled={generatingDocumentIds?.includes(record.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="generated-document-container">
      <div style={{ marginBottom: 16, display: 'flex', gap: '16px' }}>
        <Input
          placeholder="Search documents..."
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ maxWidth: '300px' }}
        />
        <Select
          value={selectedType}
          onChange={setSelectedType}
          style={{ width: '200px' }}
        >
          <Select.Option key="all" value="all">All Types</Select.Option>
          {documentTypes.map(type => (
            <Select.Option key={type.type} value={type.type}>
              {type.title}
            </Select.Option>
          ))}
        </Select>
      </div>

      <Table
        columns={columns}
        dataSource={sortedDocuments}
        rowKey="id"
        loading={false}
        pagination={{
          pageSize: 10,
          showTotal: (total) => `Total ${total} documents`,
        }}
      />

      <Modal
        title={selectedDocument ? documentTypes.find(t => t.type === selectedDocument.documentType)?.title : 'Document'}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setSelectedDocument(null);
          setIsEditing(false);
        }}
        width={800}
        footer={
          <div className="generated-document-container__modalFooter">
            <Space>
              <Button
                icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? 'Preview' : 'Edit'}
              </Button>
              <Button
                icon={<CopyOutlined />}
                onClick={handleCopyToClipboard}
              >
                Copy
              </Button>
              <Button
                icon={<FilePdfOutlined />}
                onClick={handleExport}
                type="primary"
              >
                Export as PDF
              </Button>
              <Button onClick={() => setModalVisible(false)}>
                Close
              </Button>
            </Space>
          </div>
        }
      >
        {selectedDocument && (
          <div className="generated-document-container__documentView">
            <div className="generated-document-container__documentMetadata">
              <div>
                <BaseText type="caption">Generated on {dayjs(selectedDocument.generatedAt).format('MMMM D, YYYY h:mm A')}</BaseText>
              </div>
              <div className="generated-document-container__metadata">
                <Space>
                  <Tag color="blue">{documentTypes.find(t => t.type === selectedDocument.documentType)?.title}</Tag>
                  <Tag color="default">{`${selectedDocument.metadata.wordCount} words`}</Tag>
                  <Tag color="default">{`${selectedDocument.metadata.estimatedReadTime} min read`}</Tag>
                </Space>
              </div>
            </div>
            <div className="generated-document-container__documentContent">
              {isEditing ? (
                <Input.TextArea
                  value={editableContent}
                  onChange={(e) => setEditableContent(e.target.value)}
                  autoSize={{ minRows: 10 }}
                  style={{ fontFamily: 'monospace' }}
                />
              ) : (
                <ReactMarkdown 
                  remarkPlugins={[remarkGfm]}
                  components={{
                    h1: ({node, ...props}) => <h1 className="generated-document-container__h1" {...props} />,
                    h2: ({node, ...props}) => <h2 className="generated-document-container__h2" {...props} />,
                    h3: ({node, ...props}) => <h3 className="generated-document-container__h3" {...props} />,
                    ul: ({node, ...props}) => <ul className="generated-document-container__list" {...props} />,
                    ol: ({node, ...props}) => <ol className="generated-document-container__list" {...props} />,
                    li: ({node, ...props}) => <li className="generated-document-container__listItem" {...props} />,
                    p: ({node, ...props}) => <p className="generated-document-container__paragraph" {...props} />,
                  }}
                >
                  {editableContent}
                </ReactMarkdown>
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default GeneratedDocumentView;