import { Socket, io } from 'socket.io-client';
import { TRoomMessage } from 'src/interfaces/chat-interface';

import { EAuthToken, EUserProfile } from 'src/variables/storage';

const url = process.env.REACT_APP_API_URL ?? '';

class SocketConfig {
  private static socket: Socket;

  static getSocket() {
    if (SocketConfig.socket) {
      return SocketConfig.socket;
    }

    const accessToken = localStorage.getItem(EAuthToken.ACCESS_TOKEN);
    SocketConfig.socket = io(url, { query: { accessToken } });
    return SocketConfig.socket;
  }
}

export const getSocket = () => {
  const currentProfileId = localStorage.getItem(EUserProfile.PROFILE_ID);

  const leaveRoomChat = async (roomId: string) => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.emit('leaveRoom', `ROOM-${roomId}`);
      socket.off('messageOnRoom');
    }
  };

  const joinRoomChat = (roomId: string, cb: (message: TRoomMessage) => void) => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.emit('joinRoom', `ROOM-${roomId}`);
      socket.on('messageOnRoom', (message: TRoomMessage) => {
        cb(message);
      });
    }
  };

  const leaveRoomNotification = () => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.emit('leaveRoom', `PROFILE-${currentProfileId}`);
      socket.off('notification');
    }
  };

  const joinRoomNotification = (handleReceiveNotification?: (message: TRoomMessage) => void) => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.emit('joinRoom', `PROFILE-${currentProfileId}`);
      socket.on('notification', (message) => {
        handleReceiveNotification?.(message);
      });
    }
  };

  const listenMessageOnProfile = (cb: () => void) => {
    const socket = SocketConfig.getSocket();
    socket.off('messageOnProfile');
    socket.on('messageOnProfile', () => {
      cb();
    });
  };

  const offMessageOnProfile = () => {
    const socket = SocketConfig.getSocket();
    socket.off('messageOnProfile');
  };

  const listenCreateRoom = (cb: () => void) => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.off('room');
      socket.on('room', () => {
        cb();
      });
    }
  };

  const offCreateRoom = () => {
    const socket = SocketConfig.getSocket();
    socket.off('room');
  };

  const listenGptResChunk = (cb: (message: { content: string }) => void) => {
    const socket = SocketConfig.getSocket();
    socket.off('gptResChunk'); // Remove any previous listeners to avoid duplicates
    socket.on('gptResChunk', (message: { content: string }) => {
      cb(message);
    });
  };

  const offGptResChunk = () => {
    const socket = SocketConfig.getSocket();
    socket.off('gptResChunk');
  };

  const connect = () => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.emit('joinRoom', `PROFILE-${currentProfileId}`);
    }
  };

  const connectGPT = (profileId: string) => {
    const socket = SocketConfig.getSocket();
    socket.emit('joinRoom', `PROFILE-${profileId}`);
  };

  interface StreamingStartResponse {
    data: {
      sessionId: string;
    };
  }

  interface TranscriptionResult {
    sessionId: string;
    transcript: string;
    isPartial: boolean;
    speakers: Array<{
      speaker: string;
      text: string;
      confidence: number;
      startTime: number;
      endTime: number;
    }>;
  }

  interface TranscriptionError {
    message: string;
    code?: string;
    details?: string;
  }

  // Live transcription methods
  const startTranscription = async (): Promise<string> => {
    const socket = SocketConfig.getSocket();
    return new Promise((resolve, reject) => {
      socket.emit('start_streaming', null, (response: { event: string; data: { sessionId: string } }) => {
        if (response?.data?.sessionId) {
          resolve(response.data.sessionId);
        } else {
          reject(new Error('Failed to start transcription'));
        }
      });

      // Add a timeout in case the server doesn't respond
      setTimeout(() => {
        reject(new Error('Transcription start timeout'));
      }, 5000);

      // Also listen for the streaming_started event as a fallback
      socket.once('streaming_started', (response: { sessionId: string }) => {
        if (response?.sessionId) {
          resolve(response.sessionId);
        }
      });
    });
  };

  const stopTranscription = (sessionId: string): void => {
    const socket = SocketConfig.getSocket();
    socket.emit('stop_streaming', { sessionId });
  };

  const streamAudio = (sessionId: string, audioData: Float32Array | Int16Array | Uint8Array): void => {
    const socket = SocketConfig.getSocket();
    socket.emit('stream_audio', {
      sessionId,
      audioData: Array.from(audioData),
      timestamp: Date.now()
    });
  };

  const listenTranscriptionResults = (callback: (result: TranscriptionResult) => void): void => {
    const socket = SocketConfig.getSocket();
    socket.on('transcription_result', callback);
  };

  const listenTranscriptionErrors = (callback: (error: TranscriptionError) => void): void => {
    const socket = SocketConfig.getSocket();
    socket.on('transcription_error', callback);
  };

  const cleanupTranscriptionListeners = (): void => {
    const socket = SocketConfig.getSocket();
    socket.off('transcription_result');
    socket.off('transcription_error');
    socket.off('stream_error');
    socket.off('streaming_stopped');
  };

  return {
    connect,
    connectGPT,
    leaveRoomChat,
    joinRoomChat,
    listenMessageOnProfile,
    offMessageOnProfile,
    listenCreateRoom,
    offCreateRoom,
    leaveRoomNotification,
    joinRoomNotification,
    listenGptResChunk,
    offGptResChunk,
    // Add new transcription methods
    startTranscription,
    stopTranscription,
    streamAudio,
    listenTranscriptionResults,
    listenTranscriptionErrors,
    cleanupTranscriptionListeners,
  };
};
