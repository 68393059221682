import React, { useState, useEffect } from 'react';
import { Modal, Timeline, Space, Typography, Tabs, Button, Spin, Card, Tag, Alert } from 'antd';
import {
  ClockCircleOutlined, UserOutlined, AudioOutlined,
  FileTextOutlined, FormOutlined, MessageOutlined,
  PieChartOutlined, DownloadOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { BaseText } from 'src/components/typography';
import dayjs from 'dayjs';
import './TranscriptModal.scss';
import { TTranscript } from 'src/interfaces/transcript-interface';
import { useAppDispatch } from 'src/stores';
import { analyseTranscriptAction, generateDocumentAction, DocumentType } from 'src/stores/clients/clients-actions';
import TranscriptAnalysisView from './components/TranscriptAnalysisView';
import GeneratedDocumentView from './components/GeneratedDocument';
import EditableTranscript from './components/EditableTranscript';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

interface TranscriptModalProps {
  transcript: TTranscript | null;
  visible: boolean;
  onClose: () => void;
}

interface AnalysisSummary {
  keyPoints: string[];
  topics: { name: string; confidence: number }[];
  sentiment: { positive: number; neutral: number; negative: number };
  suggestedFollowUp: string[];
}

interface GeneratedDocumentType {
  id: string;
  content: string;
  documentType: DocumentType;
  generatedAt: string;
  metadata: {
    estimatedReadTime: number;
    wordCount: number;
  };
}

/*const DOCUMENT_TYPES: Array<{
  title: string;
  type: string;
  description: string;
}> = [
  {
    title: "Quick Summary",
    type: "summary",
    description: "Generate a concise summary of the session, including key points and outcomes."
  },
  {
    title: "Doctor's Letter",
    type: "letter",
    description: "Create a professional letter for referring doctors or healthcare providers."
  },
  {
    title: "Session Notes",
    type: "notes",
    description: "Generate detailed clinical notes from the session transcript."
  }
];*/

const TranscriptModal: React.FC<TranscriptModalProps> = ({ transcript, visible, onClose }) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState('transcript');
  const [analysisLoading, setAnalysisLoading] = useState(false);
  const [analysis, setAnalysis] = useState<AnalysisSummary | null>(null);
  const [analysisError, setAnalysisError] = useState<string | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  useEffect(() => {
    if (!visible) {
      setActiveTab('transcript');
      setAnalysisLoading(false);
      setAnalysis(null);
      setAnalysisError(null);
      setIsAnalyzing(false);
    }
  }, [visible]);

  if (!transcript) return null;

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleAnalyseTranscript = async () => {
    setIsAnalyzing(true);
    try {
      setAnalysisLoading(true);
      setAnalysisError(null);
      const result = await dispatch(analyseTranscriptAction({
        transcriptId: transcript.id,
        clientId: transcript.clientId || ''
      })).unwrap();
      setAnalysis(result);
    } catch (error) {
      console.error('Analysis error:', error);
      setAnalysisError('Failed to analyze transcript');
    } finally {
      setAnalysisLoading(false);
      setIsAnalyzing(false);
    }
  };

  return (
    <Modal
      title={
        <Space direction="vertical" size={4} className="transcript-modal-header">
          <Title level={4}>Session Analysis & Documentation</Title>
          <BaseText type="body2" className="transcript-date">
            {dayjs(transcript.recordingDate).format('MMMM D, YYYY')}
          </BaseText>
        </Space>
      }
      open={visible}
      onCancel={onClose}
      width={1000}
      footer={null}
      className="transcript-modal"
    >
      {transcript.isLoading ? (
        <div style={{ padding: '40px', textAlign: 'center' }}>
          <Spin size="large" tip="Loading transcript..." />
        </div>
      ) : (
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane
            tab={<><FileTextOutlined /> Transcript</>}
            key="transcript"
          >
            <EditableTranscript
              segments={transcript.segments}
              recordingDate={transcript.recordingDate}
              onSegmentsChange={(updatedSegments) => {
                // Handle the updated segments here
                // You might want to dispatch an action to update the transcript
              }}
            />
          </TabPane>

          <TabPane
            tab={<><PieChartOutlined /> Analysis</>}
            key="analysis"
          >
            {!analysis ? (
              <div className="analysis-placeholder">
                <Button
                  type="primary"
                  onClick={handleAnalyseTranscript}
                  loading={analysisLoading}
                >
                  Analyse Transcript
                </Button>
              </div>
            ) : (
              <TranscriptAnalysisView
                analysis={analysis}
                isLoading={isAnalyzing}
                onRegenerate={() => handleAnalyseTranscript()}
                clientId={transcript.clientId}
              />
            )}
          </TabPane>
        </Tabs>
      )}
    </Modal>
  );
};

export default TranscriptModal;