import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Space, Typography, Modal, Tooltip, Spin, Tag, Drawer, Select, message, Card, Switch, Progress, List, AutoComplete, Form, Radio, Alert } from 'antd';
import { ArrowLeftOutlined, UserOutlined, AudioOutlined, AudioMutedOutlined, DeleteOutlined, InfoCircleOutlined, CheckCircleOutlined, FlagOutlined, EditOutlined, SaveOutlined, SettingOutlined, PieChartOutlined, ReloadOutlined, SmileOutlined, MehOutlined, FrownOutlined, ArrowRightOutlined, LoadingOutlined, FileTextOutlined, WarningOutlined, PlusOutlined, GlobalOutlined, TeamOutlined, LockOutlined, CloseOutlined, DownOutlined, UpOutlined, FilePdfOutlined, UploadOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHatWizard, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { RoutePaths } from 'src/routes/routes-constants';
import LiveTranscribeTab from '../clients/client-details-components/sessions/LiveTranscribeTab';
import useWindowSize from 'src/hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { TRootState, useAppDispatch } from 'src/stores';
import { analyseTranscriptAction, getTemplatesAction, createTemplateAction, updateTemplateAction, deleteTemplateAction, generateDocumentFromTemplateAction, uploadTranscriptToClientAction } from 'src/stores/clients/clients-actions';
import { TClient } from 'src/interfaces/clients-interface';
import { TCommonGetListParams } from 'src/interfaces/common-interface';
import { getClients } from 'src/stores/clients';
import debounce from 'lodash/debounce';
import { EUserProfile } from 'src/variables/storage';
import { EUserType } from 'src/variables/enum-variables';
import { unwrapResult } from '@reduxjs/toolkit';
import './LiveTranscribePage.scss';  // Add this import
import { exportToPDF } from 'src/utils/document-export';

// Add at the top of the file, before the component
interface APITranscriptSegment {
  speaker: string;
  text: string;
  startTime: number;
}

interface GeneratedDoc {
  id: string;
  content: string;
  name: string;
  isGenerated?: boolean;
}

interface TranscriptionSegment {
  speaker: string;
  text: string;
  startTime: number;
  endTime: number;
  confidence: number;
  sentiment?: {
    score: number;
    label: 'positive' | 'negative' | 'neutral';
  };
}

interface ClientOption {
  value: string;
  label: string;
  id: string;
}

interface SessionSummary {
  keyPoints: string[];
  sentiment: {
    positive: number;
    neutral: number;
    negative: number;
  };
  suggestedFollowUp: string[];
}

// Define interfaces for quick notes
interface QuickNoteTemplate {
  id: string;
  content: string;
  category: 'important' | 'followup' | 'observation';
}

// Define a local interface that extends the API Template
interface UITemplate {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  color: string;
  tags: string[];
  content: string;
  createdBy?: string;
  isPrivate?: boolean;
  clinicId?: string;
  usage_count: number;
}

// Add interface for active template
interface ActiveTemplate {
  id: string;
  name: string;
  content: string;
  variables: TemplateVariable[];
  usage_count: number;
}

// Add new interface for template form
interface TemplateFormValues {
  name: string;
  description: string;
  content: string;
  isPrivate: 'public' | 'clinic' | 'private';
  icon?: string;
  color?: string;
  tags: string[];
}

// Add interface for API template response
interface APITemplate {
  id: string;
  name: string;
  description: string;
  content: string;
  icon?: string;
  color?: string;
  tags?: string[];
  isPrivate: boolean;
  clinicId?: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  usage_count: number;
}

// Update API response interface
interface APIResponse<T> {
  data: T[];
  // Add other response fields if needed
}

// Base template interface for API responses
interface BaseTemplate {
  id: string;
  name: string;
  description: string;
  content: string;
  icon?: string;
  color?: string;
  tags?: string[];
  isPrivate?: boolean;
  clinicId?: string;
  createdBy: string;
  usage_count: number;
}

// Add new interfaces for variable mapping
interface TemplateVariable {
  name: string;
  value: string;
  type: 'system' | 'custom';
  description: string;
}

interface SystemVariable {
  key: string;
  description: string;
  source: 'client' | 'profile' | 'clinic' | 'practitioner';
  path: string;
}

// Add system variables based on entities
const SYSTEM_VARIABLES: SystemVariable[] = [
  // Client variables
  { key: 'clientName', description: 'Full name of the client', source: 'client', path: 'firstName + " " + lastName' },
  { key: 'clientFirstName', description: 'First name of the client', source: 'client', path: 'firstName' },
  { key: 'clientLastName', description: 'Last name of the client', source: 'client', path: 'lastName' },
  { key: 'clientTitle', description: 'Title of the client', source: 'client', path: 'title' },
  { key: 'clientGender', description: 'Gender of the client', source: 'client', path: 'gender' },
  { key: 'clientDob', description: 'Date of birth of the client', source: 'client', path: 'dob' },
  { key: 'clientPhone', description: 'Phone number of the client', source: 'client', path: 'phone' },
  { key: 'clientAddress', description: 'Address of the client', source: 'client', path: 'address' },
  { key: 'clientOccupation', description: 'Occupation of the client', source: 'client', path: 'occupation' },
  
  // Practitioner variables
  { key: 'practitionerName', description: 'Full name of the practitioner', source: 'profile', path: 'firstName + " " + lastName' },
  { key: 'practitionerTitle', description: 'Title of the practitioner', source: 'profile', path: 'title' },
  { key: 'practitionerType', description: 'Type of practitioner', source: 'profile', path: 'practitionerType.name' },
  { key: 'practitionerPhone', description: 'Phone number of the practitioner', source: 'profile', path: 'phone' },
  { key: 'practitionerProvideNumber', description: 'Provider number of the practitioner', source: 'profile', path: 'provideNumber' },
  
  // Clinic variables
  { key: 'clinicName', description: 'Name of the clinic', source: 'clinic', path: 'name' },
  { key: 'clinicPhone', description: 'Phone number of the clinic', source: 'clinic', path: 'phone' },
  { key: 'clinicEmail', description: 'Email of the clinic', source: 'clinic', path: 'email' },
  { key: 'clinicAddress', description: 'Address of the clinic', source: 'clinic', path: 'address' },
  { key: 'clinicAbn', description: 'ABN of the clinic', source: 'clinic', path: 'abn' },
  
  // Practice variables
  { key: 'practiceName', description: 'Name of the practice', source: 'practitioner', path: 'practiceName' },
  { key: 'medication', description: 'Client medication', source: 'practitioner', path: 'medication' },
  { key: 'diagnosis', description: 'Client diagnosis', source: 'practitioner', path: 'diagnosis' },
  { key: 'history', description: 'Client history', source: 'practitioner', path: 'history' },
  { key: 'emergencyContactName', description: 'Emergency contact name', source: 'practitioner', path: 'emergencyContactName' },
  { key: 'emergencyContactRelationship', description: 'Emergency contact relationship', source: 'practitioner', path: 'emergencyContactRelationship' },
  { key: 'emergencyContactPhone', description: 'Emergency contact phone', source: 'practitioner', path: 'emergencyContactPhone' },
  { key: 'drName', description: 'Doctor name', source: 'practitioner', path: 'drName' },
  { key: 'drProvideNumber', description: 'Doctor provider number', source: 'practitioner', path: 'drProvideNumber' },
  { key: 'drAddress', description: 'Doctor address', source: 'practitioner', path: 'drAddress' }
];

const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;

// Keyboard shortcuts
const SHORTCUTS = {
  TOGGLE_RECORDING: 'r',
  CLEAR_TRANSCRIPT: 'c',
};

// Template notes
const DEFAULT_TEMPLATES = [
  { id: 'mood', text: 'Client mood appears ', category: 'observation' },
  { id: 'anxiety', text: 'Anxiety levels: ', category: 'observation' },
  { id: 'progress', text: 'Progress noted in ', category: 'important' },
  { id: 'followup', text: 'Schedule follow-up for ', category: 'followup' },
];

const MOBILE_BREAKPOINT = 768;
const SMALL_SCREEN_BREAKPOINT = 480;

interface LiveTranscribeTabProps {
  clientId: string;
  isRecording: boolean;
  onRecordingStateChange: (recording: boolean, initializing: boolean) => void;
  onTranscriptStateChange: (hasTranscript: boolean) => void;
  onClearCallback: (callback: () => void) => void;
  onTranscriptUpdate: (segments: APITranscriptSegmentWithSentiment[]) => void;
  isGeneratingDocument?: boolean;
  generatedDocument?: string;
  generatedDocs: Array<{id: string; content: string; name: string}>;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  onCloseDocument: (docId: string) => void;
  customVariables?: TemplateVariable[];
  isTemplateVariablesCollapsed?: boolean;
}

// Add new interface for API transcript segments with sentiment
interface APITranscriptSegmentWithSentiment {
  speaker: string;
  text: string;
  startTime: number;
  sentiment?: {
    score: number;
    label: 'positive' | 'negative' | 'neutral';
  };
}

const LiveTranscribePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useSelector((state: TRootState) => state.user.profile);
  const [clientName, setClientName] = useState('');
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [hasExistingTranscript, setHasExistingTranscript] = useState(false);
  const [clearTranscript, setClearTranscript] = useState<(() => void) | null>(null);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [isGeneratingDocument, setIsGeneratingDocument] = useState(false);
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const { width } = useWindowSize();
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [sessionSummary, setSessionSummary] = useState<SessionSummary | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const sessionIdRef = useRef<string | null>(null);
  const [clientSearchParams, setClientSearchParams] = useState<TCommonGetListParams>({
    page: 1,
    size: 10,
    keyword: '',
    status: 'ACTIVE'
  });
  const [clientOptions, setClientOptions] = useState<ClientOption[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [hasMoreClients, setHasMoreClients] = useState(true);
  const clientSearchRef = useRef<HTMLDivElement>(null);
  const [isClientLinked, setIsClientLinked] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [templateSearchQuery, setTemplateSearchQuery] = useState('');
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const [form] = Form.useForm<TemplateFormValues>();
  const [templateTypeFilter, setTemplateTypeFilter] = useState<'all' | 'private' | 'clinic' | 'public'>('all');
  const userRole = profile?.role || '';
  const [templateModalView, setTemplateModalView] = useState<'list' | 'create' | 'edit'>('list');
  const [activeTemplate, setActiveTemplate] = useState<ActiveTemplate | null>(null);
  const [detectedVariables, setDetectedVariables] = useState<TemplateVariable[]>([]);
  const [customVariables, setCustomVariables] = useState<TemplateVariable[]>([]);
  const [isAddingVariable, setIsAddingVariable] = useState(false);
  const [newVariableName, setNewVariableName] = useState('');
  const [newVariableValue, setNewVariableValue] = useState('');
  const [clients, setClients] = useState<TClient[]>([]);
  const [isTemplateVariablesCollapsed, setIsTemplateVariablesCollapsed] = useState(false);
  const [isEditingTemplate, setIsEditingTemplate] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [transcriptSegments, setTranscriptSegments] = useState<TranscriptionSegment[]>([]);
  const [apiTranscriptSegments, setApiTranscriptSegments] = useState<APITranscriptSegmentWithSentiment[]>([]);
  const [generatedDocument, setGeneratedDocument] = useState<string>('');
  const [generatedDocs, setGeneratedDocs] = useState<GeneratedDoc[]>([]);
  const [activeTab, setActiveTab] = useState<string>('transcript');
  const [templateContent, setTemplateContent] = useState('');
  const [templates, setTemplates] = useState<UITemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<UITemplate | null>(null);
  const [error, setError] = useState<string | null>(null);
  // Add new state variable near the other state declarations
  const [hasUploaded, setHasUploaded] = useState(false);

  // Memoize the width-dependent values
  const layoutConfig = useMemo(() => ({
    isMobile: width <= MOBILE_BREAKPOINT,
    isVerySmallScreen: width <= SMALL_SCREEN_BREAKPOINT,
    inputWidth: width <= 960 ? '100%' : '240px',
    inputFlex: width <= 960 ? '1 1 auto' : '0 0 auto'
  }), [width]);

  const { isMobile, isVerySmallScreen, inputWidth, inputFlex } = layoutConfig;

  // Timer effect
  useEffect(() => {
    if (isRecording) {
      timerIntervalRef.current = setInterval(() => {
        setTotalSeconds(prev => prev + 1);
      }, 1000);
    } else if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
    }

    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, [isRecording]);

  const formatTime = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleBack = () => {
    navigate(RoutePaths.HOME);
  };

  const handleClearTranscript = () => {
    confirm({
      title: 'Clear Transcript',
      icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
      content: 'Are you sure you want to clear the entire transcript? This action cannot be undone.',
      okText: 'Clear',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        clearTranscript?.();
        setTotalSeconds(0);
      },
    });
  };

  // Add this before the renderRecordButton function
  const handleUploadTranscript = async () => {
    if (!selectedClientId || !hasExistingTranscript || !apiTranscriptSegments.length || hasUploaded) {
      return;
    }

    try {
      // Filter out system messages, clean sentiment text, and normalize speaker labels
      const cleanedSegments = apiTranscriptSegments
        .filter(segment => segment.speaker !== '#SYSTEM')
        .map(segment => ({
          ...segment,
          speaker: segment.speaker === 'spk_0' ? '1' : segment.speaker,
          text: segment.text.replace(/\[Sentiment: .*?\]/g, '').trim()
        }));

      const result = await dispatch(uploadTranscriptToClientAction({
        clientId: selectedClientId,
        transcript: {
          segments: cleanedSegments,
          rawText: cleanedSegments.map(s => s.text).join(' ')
        },
        metadata: {
          recordingDate: new Date().toISOString(),
          totalSegments: cleanedSegments.length,
          averageConfidence: 1 // Default for uploaded transcripts
        }
      })).unwrap();

      if (result.status === 'success') {
        message.success('Transcript uploaded successfully');
        setHasUploaded(true); // Set upload flag after successful upload
      } else {
        message.error('Failed to upload transcript');
      }
    } catch (error) {
      console.error('Error uploading transcript:', error);
      message.error('Failed to upload transcript');
    }
  };

  const renderRecordButton = () => (
    clientName && (
      <>
        <Button
          type={isRecording ? 'default' : 'primary'}
          icon={isRecording ? <AudioMutedOutlined /> : <AudioOutlined />}
          loading={isInitializing}
          danger={isRecording}
          style={{ 
            height: '36px',
            borderRadius: '6px',
            padding: isVerySmallScreen ? '8px' : '0 16px',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            minWidth: isVerySmallScreen ? '36px' : 'auto',
            justifyContent: 'center',
            ...(hasExistingTranscript && !isRecording ? {
              backgroundColor: '#fa8c16',
              borderColor: '#fa8c16',
              color: '#fff',
            } : {})
          }}
          onClick={() => setIsRecording(!isRecording)}
        >
          {!isVerySmallScreen && (
            isRecording ? 'Stop Recording' : (hasExistingTranscript ? 'Append' : 'Start Recording')
          )}
        </Button>
        {isClientLinked && hasExistingTranscript && !isRecording && (
          <Tooltip title={hasUploaded ? 'Transcript has already been uploaded' : undefined}>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              style={{ 
                height: '36px',
                borderRadius: '6px',
                padding: isVerySmallScreen ? '8px' : '0 16px',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                minWidth: isVerySmallScreen ? '36px' : 'auto',
                justifyContent: 'center',
                backgroundColor: hasUploaded ? '#d9d9d9' : '#52c41a',
                borderColor: hasUploaded ? '#d9d9d9' : '#52c41a'
              }}
              onClick={handleUploadTranscript}
              disabled={hasUploaded}
            >
              {!isVerySmallScreen && (hasUploaded ? 'Uploaded' : 'Upload')}
            </Button>
          </Tooltip>
        )}
      </>
    )
  );

  const renderClearButton = () => (
    hasExistingTranscript && (
      <Button
        type="default"
        danger
        icon={<DeleteOutlined />}
        onClick={handleClearTranscript}
        style={{ 
          height: '36px',
          borderRadius: '6px',
          padding: isVerySmallScreen ? '8px' : '0 16px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          minWidth: isVerySmallScreen ? '36px' : 'auto',
          justifyContent: 'center'
        }}
      >
        {!isVerySmallScreen && 'Clear'}
      </Button>
    )
  );

  // Handle keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      // Only handle shortcuts if not in an input field and client name exists
      if (e.target instanceof HTMLInputElement || 
          e.target instanceof HTMLTextAreaElement || 
          !clientName) {
        return;
      }

      switch (e.key.toLowerCase()) {
        case SHORTCUTS.TOGGLE_RECORDING:
          setIsRecording(prev => !prev);
          break;
        case SHORTCUTS.CLEAR_TRANSCRIPT:
          if (hasExistingTranscript && !isRecording) {
            handleClearTranscript();
          }
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isRecording, hasExistingTranscript, handleClearTranscript, clientName]);

  // Add function to detect variables in template content
  const detectTemplateVariables = useCallback((content: string) => {
    const variableRegex = /\{\{([^}]+)\}\}/g;
    const matches = Array.from(content.matchAll(variableRegex));
    const variables: TemplateVariable[] = [];

    matches.forEach(match => {
      const varName = match[1].trim();
      const systemVar = SYSTEM_VARIABLES.find(v => v.key === varName);
      
      if (systemVar) {
        variables.push({
          name: varName,
          value: '', // Will be populated from system data
          type: 'system',
          description: systemVar.description
        });
      } else {
        // Check if we already have this custom variable
        const existingCustomVar = customVariables.find(v => v.name === varName);
        if (existingCustomVar) {
          variables.push(existingCustomVar);
        } else {
          variables.push({
            name: varName,
            value: '',
            type: 'custom',
            description: 'Custom variable'
          });
        }
      }
    });

    setDetectedVariables(variables);
    return variables; // Return the variables array
  }, [customVariables]);

  // Handle document template selection
  const handleDocumentTemplateSelect = useCallback((template: UITemplate) => {
    setSelectedTemplate(template);
    setTemplateModalView('edit');
    form.setFieldsValue({
      name: template.name,
      description: template.description,
      content: template.content,
      isPrivate: template.clinicId ? 'clinic' : (template.isPrivate ? 'private' : 'public'),
      tags: template.tags || []
    });
  }, [form]);

  // Add template close handler
  const handleTemplateClose = useCallback(() => {
    setActiveTemplate(null);
  }, []);

  // Settings drawer content
  const renderSettingsContent = () => (
    <Space direction="vertical" style={{ width: '100%' }} size={16}>
      <Card title="Keyboard Shortcuts" size="small">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Text>Press &lsquo;R&rsquo; - Toggle recording</Text>
          <Text>Press &lsquo;C&rsquo; - Clear transcript</Text>
        </Space>
      </Card>
    </Space>
  );

  // Render settings button
  const renderSettingsButton = () => (
    <Tooltip title="Settings">
      <Button
        type={isSettingsVisible ? 'primary' : 'default'}
        icon={<SettingOutlined />}
        onClick={() => setIsSettingsVisible(true)}
        style={{ 
          height: '36px',
          borderRadius: '6px',
          padding: isVerySmallScreen ? '8px' : '0 16px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          minWidth: isVerySmallScreen ? '36px' : 'auto',
          justifyContent: 'center'
        }}
      >
        {!isVerySmallScreen && 'Settings'}
      </Button>
    </Tooltip>
  );

  // Memoize the analyze session callback
  const analyzeSession = useCallback(async () => {
    if (!hasExistingTranscript || !selectedClientId) return;

    try {
      setIsAnalyzing(true);
      const result = await dispatch(analyseTranscriptAction({
        transcriptId: sessionIdRef.current || '',
        clientId: selectedClientId
      })).unwrap();

      setSessionSummary(result);
    } catch (error) {
      message.error('Failed to analyze session');
    } finally {
      setIsAnalyzing(false);
    }
  }, [dispatch, hasExistingTranscript, selectedClientId]);

  // Render session summary
  const renderSessionSummary = () => {
    if (!sessionSummary) return null;

    return (
      <Card
        title={
          <Space>
            <PieChartOutlined />
            <span>Session Analysis</span>
          </Space>
        }
        extra={
          <Button
            icon={<ReloadOutlined />}
            onClick={analyzeSession}
            loading={isAnalyzing}
          >
            Refresh
          </Button>
        }
        style={{ marginTop: 16 }}
      >
        <Space direction="vertical" style={{ width: '100%' }} size={16}>
          {/* Sentiment Analysis */}
          <Card size="small" title="Overall Sentiment">
            <Space direction="vertical" style={{ width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <SmileOutlined style={{ color: '#52c41a' }} />
                <Progress
                  percent={Math.round(sessionSummary.sentiment.positive * 100)}
                  status="success"
                  size="small"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <MehOutlined style={{ color: '#1890ff' }} />
                <Progress
                  percent={Math.round(sessionSummary.sentiment.neutral * 100)}
                  status="normal"
                  size="small"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <FrownOutlined style={{ color: '#ff4d4f' }} />
                <Progress
                  percent={Math.round(sessionSummary.sentiment.negative * 100)}
                  status="exception"
                  size="small"
                />
              </div>
            </Space>
          </Card>

          {/* Key Points */}
          <Card size="small" title="Key Discussion Points">
            <List
              size="small"
              dataSource={sessionSummary.keyPoints}
              renderItem={(point: string) => (
                <List.Item>
                  <Space>
                    <CheckCircleOutlined style={{ color: '#52c41a' }} />
                    <Text>{point}</Text>
                  </Space>
                </List.Item>
              )}
            />
          </Card>

          {/* Follow-up Suggestions */}
          <Card size="small" title="Suggested Follow-up">
            <List
              size="small"
              dataSource={sessionSummary.suggestedFollowUp}
              renderItem={(suggestion: string) => (
                <List.Item>
                  <Space>
                    <ArrowRightOutlined style={{ color: '#1890ff' }} />
                    <Text>{suggestion}</Text>
                  </Space>
                </List.Item>
              )}
            />
          </Card>
        </Space>
      </Card>
    );
  };

  // Add this effect to handle infinite scroll
  useEffect(() => {
    const handleScroll = (e: Event) => {
      const element = e.target as HTMLDivElement;
      if (
        element.scrollHeight - element.scrollTop === element.clientHeight &&
        !isLoadingClients &&
        hasMoreClients
      ) {
        setClientSearchParams(prev => ({
          ...prev,
          page: prev.page + 1
        }));
      }
    };

    const currentRef = clientSearchRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoadingClients, hasMoreClients]);

  // Add client search functionality
  const searchClients = useCallback(
    debounce(async (searchText: string) => {
      setIsLoadingClients(true);
      try {
        const params: TCommonGetListParams = {
          ...clientSearchParams,
          page: 1,
          keyword: searchText,
          status: 'ACTIVE'
        };
        
        const result = await dispatch(getClients(params)).unwrap();
        
        const options = result.data.map((client: TClient) => ({
          value: `${client.firstName} ${client.lastName}`.trim(),
          label: `${client.firstName} ${client.lastName}${client.email ? ` (${client.email})` : ''}`.trim(),
          id: client.clientId
        }));

        setClientOptions(options);
        setHasMoreClients(result.data.length === params.size);
        setClientSearchParams(params);
      } catch (error) {
        console.error('Error searching clients:', error);
      } finally {
        setIsLoadingClients(false);
      }
    }, 300),
    [dispatch, clientSearchParams]
  );

  // Add load more clients functionality
  const loadMoreClients = useCallback(async () => {
    if (isLoadingClients || !hasMoreClients) return;
    
    setIsLoadingClients(true);
    try {
      const result = await dispatch(getClients(clientSearchParams)).unwrap();
      
      const newOptions = result.data.map((client: TClient) => ({
        value: `${client.firstName} ${client.lastName}`.trim(),
        label: `${client.firstName} ${client.lastName}${client.email ? ` (${client.email})` : ''}`.trim(),
        id: client.clientId
      }));

      setClientOptions(prev => [...prev, ...newOptions]);
      setHasMoreClients(result.data.length === clientSearchParams.size);
    } catch (error) {
      console.error('Error loading more clients:', error);
    } finally {
      setIsLoadingClients(false);
    }
  }, [dispatch, clientSearchParams, isLoadingClients, hasMoreClients]);

  // Update the client name change handler
  const handleClientNameChange = (value: string) => {
    setClientName(value);
    setIsClientLinked(false);
    searchClients(value);
    
    // Update custom variables if clientName exists
    const clientNameVar = {
      name: 'clientName',
      value: value || '(No client selected)',
      type: 'system' as const,
      description: 'Full name of the client'
    };

    setCustomVariables(prev => {
      const updated = prev.filter(v => v.name !== 'clientName');
      return [...updated, clientNameVar];
    });
  };

  // Update the client selection handler
  const handleClientSelect = (value: string, option: ClientOption) => {
    setClientName(value);
    setSelectedClientId(option.id);
    setIsClientLinked(true);
    
    // Update custom variables if clientName exists
    const clientNameVar = {
      name: 'clientName',
      value: value,
      type: 'system' as const,
      description: 'Full name of the client'
    };

    setCustomVariables(prev => {
      const updated = prev.filter(v => v.name !== 'clientName');
      return [...updated, clientNameVar];
    });
  };

  // Replace the existing AutoComplete component with updated handlers
  const renderClientInput = useCallback(() => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <AutoComplete<string, ClientOption>
        value={clientName}
        onChange={handleClientNameChange}
        onSelect={handleClientSelect}
        options={clientOptions}
        style={{
          width: inputWidth,
          flex: inputFlex
        }}
        placeholder="Search or enter client name..."
        allowClear
        showSearch
        notFoundContent={
          isLoadingClients ? (
            <div style={{ padding: '8px', textAlign: 'center' }}>
              <Spin size="small" />
            </div>
          ) : clientOptions.length === 0 ? (
            <div style={{ padding: '8px', textAlign: 'center', color: '#999' }}>
              No existing clients found
            </div>
          ) : null
        }
        dropdownRender={(menu) => (
          <div ref={clientSearchRef} style={{ maxHeight: '300px', overflow: 'auto' }}>
            {menu}
            {hasMoreClients && (
              <div style={{ textAlign: 'center', padding: '8px' }}>
                <Spin spinning={isLoadingClients} size="small" />
              </div>
            )}
          </div>
        )}
      />
      {clientName && (
        <Tag 
          color={isClientLinked ? '#52c41a' : '#8c8c8c'} 
          icon={isClientLinked ? <CheckCircleOutlined /> : <InfoCircleOutlined />}
          style={{ marginLeft: 0 }}
        >
          {isClientLinked ? 'Client Linked' : 'Manual Entry'}
        </Tag>
      )}
    </div>
  ), [clientName, clientOptions, inputWidth, inputFlex, isLoadingClients, hasMoreClients, isClientLinked]);

  // Add this effect to perform initial client search
  useEffect(() => {
    const loadInitialClients = async () => {
      setIsLoadingClients(true);
      try {
        const initialParams: TCommonGetListParams = {
          page: 1,
          size: 10,
          keyword: '',
          status: 'ACTIVE'
        };
        
        const result = await dispatch(getClients(initialParams)).unwrap();
        
        const options = result.data.map((client: TClient) => ({
          value: `${client.firstName} ${client.lastName}`.trim(),
          label: `${client.firstName} ${client.lastName}${client.email ? ` (${client.email})` : ''}`.trim(),
          id: client.clientId
        }));

        setClientOptions(options);
        setHasMoreClients(result.data.length === initialParams.size);
        setClientSearchParams(initialParams);
      } catch (error) {
        console.error('Error loading initial clients:', error);
      } finally {
        setIsLoadingClients(false);
      }
    };

    loadInitialClients();
  }, []); // Run once when component mounts

  // Update template content when selected template changes
  useEffect(() => {
    if (selectedTemplate) {
      setTemplateContent(selectedTemplate.content);
      setActiveTab('template');
    } else {
      setTemplateContent('');
      // Only change tab if we're currently on template tab
      if (activeTab === 'template') {
        setActiveTab('transcript');
      }
    }
  }, [selectedTemplate]); // Remove activeTab from dependencies to break circular dependency

  // Add this at the top level of the component
  const setActiveTabCallback = useCallback((tab: string) => {
    if (tab !== activeTab) {  // Only update if the tab is actually changing
      setActiveTab(tab);
    }
  }, [activeTab]); // Include activeTab in dependencies since we're using it

  // Update the templates effect to avoid unnecessary updates
  useEffect(() => {
    const fetchTemplates = async () => {
      if (!showTemplateModal) return;
      
      setIsLoadingTemplates(true);
      try {
        const templates = await dispatch(getTemplatesAction({})).unwrap();
        const apiTemplates: UITemplate[] = templates
          .filter((template: BaseTemplate) => template.content)
          .map((template: BaseTemplate) => ({
            id: template.id,
            name: template.name,
            description: template.description,
            content: template.content!,
            icon: <FileTextOutlined />,
            color: template.color || '#1890ff',
            tags: template.tags || [],
            isPrivate: template.isPrivate,
            clinicId: template.clinicId,
            createdBy: template.createdBy,
            usage_count: template.usage_count
          }));

        setTemplates(apiTemplates);
      } catch (error) {
        message.error('Failed to load templates');
      } finally {
        setIsLoadingTemplates(false);
      }
    };

    fetchTemplates();
  }, [showTemplateModal, dispatch]); // Keep minimal dependencies

  // Add template creation handler
  const handleCreateTemplate = async (values: TemplateFormValues) => {
    try {
      setIsCreatingTemplate(true);
      
      const profileId = localStorage.getItem(EUserProfile.PROFILE_ID);
      const templateData = {
        ...values,
        isPrivate: values.isPrivate !== 'public',
        clinicId: values.isPrivate === 'clinic' ? profileId || undefined : undefined,
        createdBy: profileId || '',
        icon: 'FileTextOutlined', // Default icon
        color: '#1890ff', // Default color
      };

      await dispatch(createTemplateAction(templateData)).unwrap();
      message.success('Template created successfully');

      // Reset form and view state
      setTemplateModalView('list');
      setShowCreateTemplate(false);
      form.resetFields();
      
      // Refresh templates list
      setIsLoadingTemplates(true);
      try {
        const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
        
        // Convert API templates to UI templates
        const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
          id: template.id,
          name: template.name,
          description: template.description,
          content: template.content,
          icon: <FileTextOutlined />,
          color: template.color || '#1890ff',
          tags: template.tags || [],
          isPrivate: template.isPrivate,
          clinicId: template.clinicId,
          createdBy: template.createdBy,
          usage_count: template.usage_count
        }));
        
        // Update both templates and filtered templates
        setTemplates(uiTemplates);
      } catch (error) {
        message.error('Failed to refresh templates list');
        console.error('Error refreshing templates:', error);
      } finally {
        setIsLoadingTemplates(false);
      }
    } catch (error) {
      message.error('Failed to create template');
      console.error('Error creating template:', error);
    } finally {
      setIsCreatingTemplate(false);
    }
  };

  // Add function to handle variable value changes
  const handleVariableValueChange = (varName: string, value: string) => {
    if (detectedVariables.find(v => v.name === varName)?.type === 'system') {
      // System variables are read-only
      return;
    }

    setCustomVariables(prev => {
      const updated = [...prev];
      const index = updated.findIndex(v => v.name === varName);
      if (index >= 0) {
        updated[index] = { ...updated[index], value };
      } else {
        updated.push({
          name: varName,
          value,
          type: 'custom',
          description: 'Custom variable'
        });
      }
      return updated;
    });
  };

  // Add function to add new custom variable
  const handleAddCustomVariable = () => {
    if (!newVariableName || !newVariableValue) return;

    const formattedName = newVariableName.replace(/\s+/g, '');
    
    setCustomVariables(prev => [
      ...prev,
      {
        name: formattedName,
        value: newVariableValue,
        type: 'custom',
        description: 'Custom variable'
      }
    ]);

    setNewVariableName('');
    setNewVariableValue('');
    setIsAddingVariable(false);
  };

  // Add function to get variable preview value
  const getVariablePreviewValue = (variable: TemplateVariable) => {
    if (variable.type === 'custom') {
      return customVariables.find(v => v.name === variable.name)?.value || '';
    }

    const systemVar = SYSTEM_VARIABLES.find(v => v.key === variable.name);
    if (!systemVar) return '';

    // Get values from the actual data sources
    switch (systemVar.source) {
      case 'client': {
        // Use the selected client data if available
        const selectedClient = clientOptions.find(c => c.id === selectedClientId);
        if (!selectedClient) return '(No client selected)';

        // Get the full client data from the API response
        const clientData = clients.find(c => c.id === selectedClientId);
        if (!clientData) return '(Client data not found)';

        switch (systemVar.key) {
          case 'clientName':
            return `${clientData.firstName} ${clientData.lastName}`.trim();
          case 'clientFirstName':
            return clientData.firstName || '';
          case 'clientLastName':
            return clientData.lastName || '';
          case 'clientEmail':
            return clientData.email || '';
          default:
            return '(Client data)';
        }
      }
      case 'profile': {
        // Use the logged-in practitioner's profile
        if (!profile) return '(No practitioner data)';

        switch (systemVar.key) {
          case 'practitionerName':
            return `${profile.firstName} ${profile.lastName}`.trim();
          case 'practitionerTitle':
            return profile.title || '';
          case 'practitionerPhone':
            return profile.phone || '';
          case 'practitionerProvideNumber':
            return profile.provideNumber || '';
          default:
            return '(Practitioner data)';
        }
      }
      case 'clinic': {
        // Use the clinic data from the profile
        if (!profile?.clinic) return '(No clinic data)';

        switch (systemVar.key) {
          case 'clinicName':
            return profile.clinic.name || '';
          default:
            return '(Clinic data)';
        }
      }
      case 'practitioner':
        // These are custom fields that would need to be populated from your practice management system
        return `(${systemVar.description})`;
      default:
        return '';
    }
  };

  // Add function to get preview content with variables replaced
  const getPreviewContent = (content: string) => {
    return content.replace(/\{\{([^}]+)\}\}/g, (match, varName) => {
      const variable = detectedVariables.find(v => v.name === varName.trim());
      return variable ? getVariablePreviewValue(variable) : match;
    });
  };

  // Update the template preview section in renderTemplateModal
  const renderTemplatePreview = () => {
    if (!selectedTemplate) return null;

    const isOwnTemplate = selectedTemplate.createdBy === localStorage.getItem(EUserProfile.PROFILE_ID);

    const handleEditClick = () => {
      setIsEditingTemplate(true);
      form.setFieldsValue({
        name: selectedTemplate.name,
        description: selectedTemplate.description,
        content: selectedTemplate.content,
        isPrivate: selectedTemplate.clinicId ? 'clinic' : (selectedTemplate.isPrivate ? 'private' : 'public'),
        tags: selectedTemplate.tags || []
      });
    };

    const handleEditSubmit = async (values: TemplateFormValues) => {
      try {
        setIsCreatingTemplate(true);
        await dispatch(updateTemplateAction({
          id: selectedTemplate.id,
          template: {
            ...values,
            isPrivate: values.isPrivate !== 'public',
            clinicId: values.isPrivate === 'clinic' ? profile?.id || undefined : undefined,
          }
        })).unwrap();
        
        message.success('Template updated successfully');
        setIsEditingTemplate(false);
        
        // Refresh templates list
        setIsLoadingTemplates(true);
        try {
          const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
          const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
            id: template.id,
            name: template.name,
            description: template.description,
            content: template.content,
            icon: <FileTextOutlined />,
            color: template.color || '#1890ff',
            tags: template.tags || [],
            isPrivate: template.isPrivate,
            clinicId: template.clinicId,
            createdBy: template.createdBy,
            usage_count: template.usage_count
          }));
          setTemplates(uiTemplates);
          
          // Update selected template
          const updatedTemplate = uiTemplates.find(t => t.id === selectedTemplate.id);
          if (updatedTemplate) {
            setSelectedTemplate(updatedTemplate);
          }
        } catch (error) {
          message.error('Failed to refresh templates list');
        } finally {
          setIsLoadingTemplates(false);
        }
      } catch (error) {
        message.error('Failed to update template');
      } finally {
        setIsCreatingTemplate(false);
      }
    };

    const handleDeleteClick = () => {
      setShowDeleteConfirm(true);
    };

    const handleDeleteConfirm = async () => {
      try {
        setIsDeleting(true);
        await dispatch(deleteTemplateAction(selectedTemplate.id)).unwrap();
        message.success('Template deleted successfully');
        setShowDeleteConfirm(false);
        setTemplateModalView('list');
        setSelectedTemplate(null);
        
        // Refresh templates list
        setIsLoadingTemplates(true);
        try {
          const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
          const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
            id: template.id,
            name: template.name,
            description: template.description,
            content: template.content,
            icon: <FileTextOutlined />,
            color: template.color || '#1890ff',
            tags: template.tags || [],
            isPrivate: template.isPrivate,
            clinicId: template.clinicId,
            createdBy: template.createdBy,
            usage_count: template.usage_count
          }));
          setTemplates(uiTemplates);
        } catch (error) {
          message.error('Failed to refresh templates list');
        } finally {
          setIsLoadingTemplates(false);
        }
      } catch (error) {
        message.error('Failed to delete template');
      } finally {
        setIsDeleting(false);
      }
    };

    return (
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        {/* Template Header */}
        <div style={{ 
          display: 'flex', 
          alignItems: 'flex-start',
          gap: 16 
        }}>
          <div style={{
            width: 48,
            height: 48,
            borderRadius: '8px',
            background: selectedTemplate.color || '#1890ff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '24px'
          }}>
            {selectedTemplate.icon}
          </div>
          <div style={{ flex: 1 }}>
            <Title level={4} style={{ margin: 0 }}>{selectedTemplate.name}</Title>
            <Text type="secondary">{selectedTemplate.description}</Text>
            <div style={{ marginTop: 8 }}>
              <Space wrap size={[0, 8]}>
                {selectedTemplate.tags?.map((tag, index) => (
                  <Tag key={index} color="blue">{tag}</Tag>
                ))}
                {selectedTemplate.clinicId ? (
                  <Tag icon={<TeamOutlined />} color="cyan">Clinic</Tag>
                ) : selectedTemplate.isPrivate ? (
                  <Tag icon={<LockOutlined />} color="red">Private</Tag>
                ) : (
                  <Tag icon={<GlobalOutlined />} color="green">Public</Tag>
                )}
                {isOwnTemplate && (
                  <Tag icon={<UserOutlined />} color="purple">Created by me</Tag>
                )}
              </Space>
            </div>
          </div>
        </div>

        {/* Template Content Preview */}
        <Card
          title={
            <Space>
              <FileTextOutlined />
              <span>Template Preview</span>
            </Space>
          }
          size="small"
          bodyStyle={{ 
            maxHeight: '300px',
            overflowY: 'auto'
          }}
        >
          <pre style={{ 
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            margin: 0,
            fontFamily: 'inherit'
          }}>
            {getPreviewContent(selectedTemplate.content)}
          </pre>
        </Card>

        {/* Action Buttons */}
        <div style={{ 
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 8,
          marginTop: 16 
        }}>
          <Button onClick={() => {
            setTemplateModalView('list');
            setSelectedTemplate(null);
            setDetectedVariables([]);
          }}>
            Back to List
          </Button>
          {isOwnTemplate && (
            <>
              <Button 
                icon={<EditOutlined />}
                onClick={handleEditClick}
              >
                Edit
              </Button>
              <Button 
                danger
                icon={<DeleteOutlined />}
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            </>
          )}
          <Button 
            type="primary"
            onClick={() => {
              setActiveTemplate({
                id: selectedTemplate.id,
                name: selectedTemplate.name,
                content: selectedTemplate.content,
                variables: detectedVariables || [], // Ensure variables is at least an empty array
                usage_count: selectedTemplate.usage_count
              });
              setShowTemplateModal(false);
              setTemplateModalView('list');
              setSelectedTemplate(null);
            }}
            disabled={!hasExistingTranscript}
          >
            Use Template
          </Button>
        </div>
        
        {!hasExistingTranscript && (
          <Alert
            type="warning"
            message="Please record a session before using a template"
            showIcon
          />
        )}

        {/* Delete Confirmation Modal */}
        <Modal
          title="Delete Template"
          open={showDeleteConfirm}
          onCancel={() => setShowDeleteConfirm(false)}
          footer={[
            <Button key="cancel" onClick={() => setShowDeleteConfirm(false)}>
              Cancel
            </Button>,
            <Button
              key="delete"
              type="primary"
              danger
              loading={isDeleting}
              onClick={handleDeleteConfirm}
            >
              Delete
            </Button>
          ]}
        >
          <Space direction="vertical" size={16}>
            <Text>Are you sure you want to delete this template?</Text>
            <Text type="secondary">This action cannot be undone.</Text>
          </Space>
        </Modal>

        {/* Edit Template Modal */}
        <Modal
          title="Edit Template"
          open={isEditingTemplate}
          onCancel={() => {
            setIsEditingTemplate(false);
            form.resetFields();
          }}
          footer={null}
          width={800}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleEditSubmit}
            initialValues={{
              isPrivate: userRole === EUserType.SOLO_PRACTITIONER ? 'private' : 'clinic',
              tags: ['progress note'],
            }}
          >
            <Form.Item
              name="name"
              label="Template Name"
              rules={[{ required: true, message: 'Please enter template name' }]}
            >
              <Input placeholder="Enter template name" />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please enter template description' }]}
            >
              <Input.TextArea 
                placeholder="Enter template description"
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>

            <Form.Item
              name="content"
              label="Template Content"
              rules={[{ required: true, message: 'Please enter template content' }]}
            >
              <Input.TextArea 
                placeholder="Enter template content with placeholders like {{clientName}}"
                autoSize={{ minRows: 6, maxRows: 12 }}
              />
            </Form.Item>

            <Form.Item
              name="isPrivate"
              label="Template Type"
              rules={[{ required: true, message: 'Please select template type' }]}
            >
              <Radio.Group>
                {userRole === EUserType.SOLO_PRACTITIONER ? (
                  <>
                    <Radio.Button value="private">Private</Radio.Button>
                    <Radio.Button value="public">Public</Radio.Button>
                  </>
                ) : (
                  <>
                    <Radio.Button value="clinic">Clinic</Radio.Button>
                    <Radio.Button value="private">Private</Radio.Button>
                    <Radio.Button value="public">Public</Radio.Button>
                  </>
                )}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="tags"
              label="Tags"
              rules={[{ required: true, message: 'Please add at least one tag' }]}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Add tags"
                tokenSeparators={[',']}
              />
            </Form.Item>

            <Form.Item>
              <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                <Button onClick={() => {
                  setIsEditingTemplate(false);
                  form.resetFields();
                }}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isCreatingTemplate}>
                  Update Template
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </Space>
    );
  };

  // Add function to fetch client data
  const fetchClientData = useCallback(async () => {
    if (selectedClientId) {
      try {
        const response = await dispatch(getClients({ 
          page: 1,
          size: 10,
          status: undefined,
          keyword: selectedClientId 
        }));
        const clientData = unwrapResult(response);
        setClients(clientData.data);
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    }
  }, [selectedClientId, dispatch]);

  // Add effect to fetch client data when selectedClientId changes
  useEffect(() => {
    fetchClientData();
  }, [selectedClientId, fetchClientData]);

  // Add template edit handler
  const handleEditTemplate = async (values: TemplateFormValues) => {
    if (!selectedTemplate) return;
    
    try {
      setIsCreatingTemplate(true);
      await dispatch(updateTemplateAction({
        id: selectedTemplate.id,
        template: {
          ...values,
          isPrivate: values.isPrivate !== 'public',
          clinicId: values.isPrivate === 'clinic' ? profile?.id || undefined : undefined,
        }
      })).unwrap();
      
      message.success('Template updated successfully');
      setIsEditingTemplate(false);
      
      // Refresh templates list
      setIsLoadingTemplates(true);
      try {
        const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
        const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
          id: template.id,
          name: template.name,
          description: template.description,
          content: template.content,
          icon: <FileTextOutlined />,
          color: template.color || '#1890ff',
          tags: template.tags || [],
          isPrivate: template.isPrivate,
          clinicId: template.clinicId,
          createdBy: template.createdBy,
          usage_count: template.usage_count
        }));
        setTemplates(uiTemplates);
        
        // Update selected template
        const updatedTemplate = uiTemplates.find(t => t.id === selectedTemplate.id);
        if (updatedTemplate) {
          setSelectedTemplate(updatedTemplate);
        }
      } catch (error) {
        message.error('Failed to refresh templates list');
      } finally {
        setIsLoadingTemplates(false);
      }
    } catch (error) {
      message.error('Failed to update template');
    } finally {
      setIsCreatingTemplate(false);
    }
  };

  // Add template delete handler
  const handleDeleteTemplate = async (templateId?: string) => {
    if (!selectedTemplate && !templateId) return;
    
    try {
      setIsDeleting(true);
      await dispatch(deleteTemplateAction(templateId || selectedTemplate!.id)).unwrap();
      message.success('Template deleted successfully');
      setShowDeleteConfirm(false);
      setTemplateModalView('list');
      setSelectedTemplate(null);
      
      // Refresh templates list
      setIsLoadingTemplates(true);
      try {
        const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
        const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
          id: template.id,
          name: template.name,
          description: template.description,
          content: template.content,
          icon: <FileTextOutlined />,
          color: template.color || '#1890ff',
          tags: template.tags || [],
          isPrivate: template.isPrivate,
          clinicId: template.clinicId,
          createdBy: template.createdBy,
          usage_count: template.usage_count
        }));
        setTemplates(uiTemplates);
      } catch (error) {
        message.error('Failed to refresh templates list');
      } finally {
        setIsLoadingTemplates(false);
      }
    } catch (error) {
      message.error('Failed to delete template');
    } finally {
      setIsDeleting(false);
    }
  };

  // Add back the renderActiveTemplate function
  const renderActiveTemplate = () => {
    if (!activeTemplate) return null;

    return (
      <div style={{ width: '100%' }}>
        <Title level={4} style={{ margin: 0 }}>{activeTemplate.name}</Title>
        <Text type="secondary" style={{ display: 'block', marginBottom: '16px' }}>
          Used {activeTemplate.usage_count} times
        </Text>
        <div style={{ 
          background: '#fff',
          padding: '16px',
          borderRadius: '8px',
          border: '1px solid #f0f0f0'
        }}>
          <pre style={{ 
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            margin: 0,
            fontFamily: 'inherit'
          }}>
            {activeTemplate.content}
          </pre>
        </div>
      </div>
    );
  };

  // Add back the renderTemplateModal function
  const renderTemplateModal = () => (
    <Modal
      title={
        templateModalView === 'list' ? "Create Document" : 
        templateModalView === 'create' ? "Create New Template" :
        "Edit Template"
      }
      open={showTemplateModal || showCreateTemplate}
      onCancel={() => {
        if (templateModalView === 'create') {
          setTemplateModalView('list');
          setShowCreateTemplate(false);
          form.resetFields();
        } else if (templateModalView === 'edit') {
          setTemplateModalView('list');
          setSelectedTemplate(null);
          form.resetFields();
        } else {
          setShowTemplateModal(false);
        }
      }}
      footer={null}
      width={800}
      style={{ top: 20 }}
      bodyStyle={{ 
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        padding: '24px'
      }}
    >
      {/* Template Selection */}
      {templateModalView === 'list' ? (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 16,
            flexWrap: 'wrap'
          }}>
            <Space.Compact style={{ flex: 1, minWidth: 200 }}>
              <Input.Search
                placeholder="Search templates..."
                value={templateSearchQuery}
                onChange={(e) => setTemplateSearchQuery(e.target.value)}
                style={{ flex: 1 }}
              />
              <Select
                value={templateTypeFilter}
                onChange={setTemplateTypeFilter}
                style={{ width: 120 }}
              >
                <Option value="all">All</Option>
                <Option value="private">Private</Option>
                {userRole !== EUserType.SOLO_PRACTITIONER && <Option value="clinic">Clinic</Option>}
                <Option value="public">Public</Option>
              </Select>
            </Space.Compact>
            <Tooltip title="Template creation is temporarily disabled">
              <Button 
                type="primary"
                icon={<PlusOutlined />}
                disabled={true}
                onClick={() => {
                  setTemplateModalView('create');
                  setShowCreateTemplate(true);
                }}
              >
                Create Template
              </Button>
            </Tooltip>
          </div>

          {isLoadingTemplates ? (
            <div style={{ textAlign: 'center', padding: '40px' }}>
              <Spin size="large" />
            </div>
          ) : filteredTemplatesList.length === 0 ? (
            <div style={{ 
              textAlign: 'center', 
              padding: '32px', 
              background: '#f5f5f5',
              borderRadius: '8px'
            }}>
              <FileTextOutlined style={{ fontSize: '24px', color: '#bfbfbf', marginBottom: '8px' }} />
              <Text type="secondary" style={{ display: 'block' }}>No templates found</Text>
              <Button 
                type="link" 
                onClick={() => {
                  setTemplateModalView('create');
                  setShowCreateTemplate(true);
                }}
              >
                Create your first template
              </Button>
            </div>
          ) : (
            <div style={{ 
              display: 'grid',
              gridTemplateColumns: `repeat(auto-fill, minmax(${width <= 640 ? '100%' : '300px'}, 1fr))`,
              gap: '16px',
              marginTop: '16px'
            }}>
              {filteredTemplatesList
                .filter((template: UITemplate) => {
                  // For solo practitioners, only show their private templates and public templates
                  if (userRole === EUserType.SOLO_PRACTITIONER) {
                    if (templateTypeFilter === 'all') {
                      return !template.clinicId; // Show private and public templates
                    }
                    if (templateTypeFilter === 'private') {
                      return template.isPrivate && !template.clinicId;
                    }
                    if (templateTypeFilter === 'public') {
                      return !template.isPrivate && !template.clinicId;
                    }
                    return true;
                  }

                  // For non-solo practitioners, show all template types
                  if (templateTypeFilter === 'all') return true;
                  if (templateTypeFilter === 'private') return template.isPrivate && !template.clinicId;
                  if (templateTypeFilter === 'clinic') return !!template.clinicId;
                  if (templateTypeFilter === 'public') return !template.isPrivate && !template.clinicId;
                  return true;
                })
                .map((template: UITemplate) => (
                  <Card
                    key={template.id}
                    hoverable
                    style={{ height: '100%' }}
                    onClick={() => {
                      // Replace existing template with new one
                      setGeneratedDocs([{
                        id: template.id,
                        content: template.content,
                        name: template.name,
                        isGenerated: false
                      }]);
                      setActiveTemplate({
                        id: template.id,
                        name: template.name,
                        content: template.content,
                        variables: [],
                        usage_count: template.usage_count
                      });
                      setActiveTab(`doc-${template.id}`);
                      setShowTemplateModal(false);
                    }}
                    actions={[
                      ...(template.createdBy === localStorage.getItem(EUserProfile.PROFILE_ID) ? [
                        <Button 
                          key="edit"
                          type="text" 
                          icon={<EditOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDocumentTemplateSelect(template);
                          }}
                        >
                          Edit
                        </Button>,
                        <Button
                          key="delete"
                          type="text"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            Modal.confirm({
                              title: 'Delete Template',
                              content: 'Are you sure you want to delete this template? This action cannot be undone.',
                              okText: 'Delete',
                              okType: 'danger',
                              cancelText: 'Cancel',
                              onOk: () => handleDeleteTemplate(template.id)
                            });
                          }}
                        >
                          Delete
                        </Button>
                      ] : [])
                    ]}
                  >
                    <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
                      <Space align="start">
                        <div style={{
                          width: 40,
                          height: 40,
                          borderRadius: '8px',
                          background: template.color || '#1890ff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#fff'
                        }}>
                          {template.icon}
                        </div>
                        <div style={{ flex: 1 }}>
                          <Text strong style={{ fontSize: '16px', display: 'block' }}>
                            {template.name}
                          </Text>
                          <Text type="secondary" style={{ fontSize: '14px' }}>
                            {template.description}
                          </Text>
                          <Text type="secondary" style={{ fontSize: '12px' }}>
                            Used {template.usage_count} times
                          </Text>
                        </div>
                      </Space>
                      <Space wrap size={[0, 8]} style={{ marginTop: 'auto' }}>
                        {template.tags && template.tags.length > 0 && template.tags.map((tag: string, index: number) => (
                          <Tag key={index} color="blue">{tag}</Tag>
                        ))}
                        {template.clinicId ? (
                          <Tag icon={<TeamOutlined />} color="cyan">Clinic</Tag>
                        ) : template.isPrivate ? (
                          <Tag icon={<LockOutlined />} color="red">Private</Tag>
                        ) : (
                          <Tag icon={<GlobalOutlined />} color="green">Public</Tag>
                        )}
                        {template.createdBy === localStorage.getItem(EUserProfile.PROFILE_ID) && (
                          <Tag icon={<UserOutlined />} color="purple">Created by me</Tag>
                        )}
                      </Space>
                    </Space>
                  </Card>
                ))}
            </div>
          )}
        </Space>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={templateModalView === 'edit' ? handleEditTemplate : handleCreateTemplate}
          initialValues={{
            isPrivate: userRole === EUserType.SOLO_PRACTITIONER ? 'private' : 'clinic',
            tags: ['progress note'],
          }}
        >
          <Form.Item
            name="name"
            label="Template Name"
            rules={[{ required: true, message: 'Please enter template name' }]}
          >
            <Input placeholder="Enter template name" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter template description' }]}
          >
            <Input.TextArea 
              placeholder="Enter template description"
              autoSize={{ minRows: 2, maxRows: 4 }}
            />
          </Form.Item>

          <Form.Item
            name="content"
            label="Template Content"
            rules={[{ required: true, message: 'Please enter template content' }]}
          >
            <Input.TextArea 
              placeholder="Enter template content with placeholders like {{clientName}}"
              autoSize={{ minRows: 6, maxRows: 12 }}
            />
          </Form.Item>

          <Form.Item
            name="isPrivate"
            label="Template Type"
            rules={[{ required: true, message: 'Please select template type' }]}
          >
            <Radio.Group>
              {userRole === EUserType.SOLO_PRACTITIONER ? (
                <>
                  <Radio.Button value="private">Private</Radio.Button>
                  <Radio.Button value="public">Public</Radio.Button>
                </>
              ) : (
                <>
                  <Radio.Button value="clinic">Clinic</Radio.Button>
                  <Radio.Button value="private">Private</Radio.Button>
                  <Radio.Button value="public">Public</Radio.Button>
                </>
              )}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="tags"
            label="Tags"
            rules={[{ required: true, message: 'Please add at least one tag' }]}
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Add tags"
              tokenSeparators={[',']}
            />
          </Form.Item>

          <Form.Item>
            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
              <Button onClick={() => {
                setTemplateModalView('list');
                if (templateModalView === 'create') {
                  setShowCreateTemplate(false);
                } else {
                  setSelectedTemplate(null);
                }
                form.resetFields();
              }}>
                Cancel
              </Button>
              {templateModalView === 'edit' && (
                <Button
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: 'Delete Template',
                      content: 'Are you sure you want to delete this template? This action cannot be undone.',
                      okText: 'Delete',
                      okType: 'danger',
                      cancelText: 'Cancel',
                      onOk: handleDeleteTemplate
                    });
                  }}
                >
                  Delete
                </Button>
              )}
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={isCreatingTemplate}
              >
                {templateModalView === 'edit' ? 'Update' : 'Create'} Template
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );

  const handleCloseGeneratedDoc = (docId: string) => {
    setGeneratedDocs(prev => prev.filter(doc => doc.id !== docId));
    // Clear the active template if we're closing its document
    if (activeTemplate && activeTemplate.id === docId) {
      setActiveTemplate(null);
    }
    // Only change to transcript tab if we're closing the currently active doc
    if (activeTab === `doc-${docId}`) {
      setActiveTab('transcript');
    }
  };

  // Replace with a single memoized filtered templates value:
  const filteredTemplatesList = useMemo(() => {
    if (!templates.length) return [];
    if (!templateSearchQuery) return templates;
    
    return templates.filter(template => 
      template.name.toLowerCase().includes(templateSearchQuery.toLowerCase()) ||
      template.description.toLowerCase().includes(templateSearchQuery.toLowerCase()) ||
      template.tags?.some(tag => tag.toLowerCase().includes(templateSearchQuery.toLowerCase()))
    );
  }, [templates, templateSearchQuery]);

  const handleExportDocument = (doc: GeneratedDoc) => {
    exportToPDF({
      title: doc.name,
      content: doc.content,
      generatedAt: new Date(),
      metadata: {
        wordCount: doc.content.split(/\s+/).length,
        estimatedReadTime: Math.ceil(doc.content.split(/\s+/).length / 200) // Assuming 200 words per minute reading speed
      }
    });
  };

  // Add this effect to reset hasUploaded when transcript is cleared
  useEffect(() => {
    if (!hasExistingTranscript) {
      setHasUploaded(false);
    }
  }, [hasExistingTranscript]);

  return (
    <div 
      className="live-transcribe-page"
      style={{
        width: isMobile ? '100%' : 'calc(100% - 96px)',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
        background: '#ffffff',
        position: 'absolute',
        top: '64px',
        left: isMobile ? '0' : '96px',
        right: 0,
        bottom: 0,
        overflow: 'auto'
      }}>
      {/* Header */}
      <div style={{
        padding: isMobile ? '8px 12px' : '12px 16px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
        background: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        zIndex: 100,
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        flexShrink: 0,
        position: 'sticky',
        top: 0
      }}>
        {/* Main header row */}
        <div style={{ 
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          width: '100%',
          flexWrap: 'wrap',
          flexDirection: width <= 960 ? 'column' : 'row'
        }}>
          {/* Left section - Back button, Session title, and search */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            width: width <= 960 ? '100%' : 'auto',
            flexWrap: width <= 640 ? 'wrap' : 'nowrap'
          }}>
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={handleBack}
              type="text"
              style={{
                fontSize: '16px',
                height: '40px',
                width: '40px',
                borderRadius: '20px'
              }}
            />

            <Title level={4} style={{ 
              margin: 0, 
              fontSize: isMobile ? '16px' : '18px', 
              fontWeight: 500,
              whiteSpace: 'nowrap'
            }}>Session</Title>

            <div style={{
              flex: width <= 640 ? '1 1 100%' : '0 1 auto',
              minWidth: width <= 640 ? '100%' : 'auto'
            }}>
              {renderClientInput()}
            </div>
          </div>
          
          {/* Right section - Timer and action buttons */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            flexWrap: 'wrap',
            marginLeft: width <= 960 ? 0 : 'auto',
            width: width <= 960 ? '100%' : 'auto',
            justifyContent: width <= 960 ? 'flex-end' : 'flex-start',
            marginTop: width <= 960 ? '8px' : 0
          }}>
            {(isRecording || hasExistingTranscript) && (
              <Tag 
                icon={<AudioOutlined style={{ fontSize: '14px' }} />}
                color={isRecording ? 'processing' : 'default'}
                style={{ 
                  height: '28px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  padding: '0 8px',
                  fontSize: '14px',
                  marginRight: width <= 960 ? 'auto' : 0
                }}
              >
                {formatTime(totalSeconds)}
              </Tag>
            )}
            
            {renderRecordButton()}
            {hasExistingTranscript && activeTemplate && clientName && (
              <Button
                type="primary"
                icon={<FontAwesomeIcon icon={faWandSparkles} />}
                loading={isGeneratingDocument}
                disabled={!clientName || !hasExistingTranscript || !activeTemplate || apiTranscriptSegments.length === 0}
                onClick={async () => {
                  const tempDocId = Date.now().toString();
                  try {
                    setIsGeneratingDocument(true);
                    setGeneratedDocument('');
                    // Create a temporary document immediately
                    setGeneratedDocs(prev => [...prev, {
                      id: tempDocId,
                      content: '',
                      name: `Generated Document ${prev.length + 1}`,
                      isGenerated: true
                    }]);
                    // Switch to the new document tab
                    setActiveTab(`doc-${tempDocId}`);

                    const result = await dispatch(generateDocumentFromTemplateAction({
                      transcript: {
                        segments: apiTranscriptSegments
                      },
                      template: {
                        id: activeTemplate.id,
                        name: activeTemplate.name,
                        content: activeTemplate.content
                      },
                      ...(isClientLinked && selectedClientId ? { clientId: selectedClientId } : { clientName: clientName })
                    })).unwrap();
                    
                    // Update the document with the actual content
                    setGeneratedDocument(result.content);
                    setGeneratedDocs(prev => prev.map(doc => 
                      doc.id === tempDocId 
                        ? { ...doc, content: result.content }
                        : doc
                    ));
                    message.success('Document generated successfully');
                  } catch (error) {
                    message.error('Failed to generate document');
                    // Remove the temporary document on error
                    setGeneratedDocs(prev => prev.filter(doc => doc.id !== tempDocId));
                  } finally {
                    setIsGeneratingDocument(false);
                  }
                }}
                className="magical-generate-btn"
                style={{ 
                  height: '36px',
                  borderRadius: '6px',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  minWidth: isVerySmallScreen ? '36px' : 'auto',
                  justifyContent: 'center',
                  border: 'none'
                }}
              >
                {!isVerySmallScreen && (
                  <span className="magical-text">
                    Generate
                  </span>
                )}
              </Button>
            )}
            {clientName && (
              <Tooltip title={
                !hasExistingTranscript ? 'Please record a transcript first' :
                !activeTemplate ? 'Please select a template first' :
                'Create Document'
              }>
                <Button
                  type="primary"
                  icon={<FontAwesomeIcon icon={faHatWizard} />}
                  onClick={() => setShowTemplateModal(true)}
                  style={{ 
                    height: '36px',
                    borderRadius: '6px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    minWidth: isVerySmallScreen ? '36px' : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  {!isVerySmallScreen && 'Create'}
                </Button>
              </Tooltip>
            )}
            {renderClearButton()}
            <Tooltip title="Settings">
              <Button
                type={isSettingsVisible ? 'primary' : 'default'}
                icon={<SettingOutlined />}
                onClick={() => setIsSettingsVisible(true)}
                style={{ 
                  height: '36px',
                  width: '36px',
                  borderRadius: '6px',
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div style={{
        flex: 1,
        background: '#ffffff',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        gap: '16px',
        overflow: 'auto'
      }}>
        {/* Add custom styles for tabs */}
        <style>
          {`
            .live-transcribe-tabs .ant-tabs-nav {
              margin-bottom: 16px;
            }
            .live-transcribe-tabs .ant-tabs-tab {
              background: white !important;
              border: 1px solid #d9d9d9 !important;
              border-radius: 4px !important;
              margin: 0 8px 0 0 !important;
              padding: 6px 16px !important;
              transition: all 0.3s;
            }
            .live-transcribe-tabs .ant-tabs-tab:hover {
              color: #1890ff !important;
              border-color: #1890ff !important;
            }
            .live-transcribe-tabs .ant-tabs-tab.ant-tabs-tab-active {
              background: white !important;
              border-color: #1890ff !important;
            }
            .live-transcribe-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
              color: #1890ff !important;
            }
            .live-transcribe-tabs .ant-tabs-ink-bar {
              display: none !important;
            }
          `}
        </style>

        {/* Live Transcribe Tab */}
        <LiveTranscribeTab 
          clientId={selectedClientId || clientName} 
          isRecording={isRecording}
          onRecordingStateChange={(recording: boolean, initializing: boolean) => {
            setIsRecording(recording);
            setIsInitializing(initializing);
          }}
          onTranscriptStateChange={(hasTranscript: boolean) => {
            setHasExistingTranscript(hasTranscript);
          }}
          onClearCallback={(callback) => {
            setClearTranscript(() => callback);
          }}
          onTranscriptUpdate={(segments) => {
            setApiTranscriptSegments(segments.map(s => ({
              speaker: s.speaker,
              text: s.text,
              startTime: s.startTime,
              sentiment: s.sentiment
            })));
          }}
          isGeneratingDocument={isGeneratingDocument}
          generatedDocument={generatedDocument}
          generatedDocs={generatedDocs}
          activeTab={activeTab}
          setActiveTab={setActiveTabCallback}
          onCloseDocument={handleCloseGeneratedDoc}
          customVariables={customVariables}
          isTemplateVariablesCollapsed={isTemplateVariablesCollapsed}
        />

      </div>

      {/* Settings Drawer */}
      <Drawer
        title="Settings"
        placement="right"
        open={isSettingsVisible}
        onClose={() => setIsSettingsVisible(false)}
        width={isMobile ? '100%' : Math.min(400, width * 0.9)}
        bodyStyle={{ 
          padding: isMobile ? '12px' : '24px',
          overflowX: 'hidden'
        }}
      >
        {renderSettingsContent()}
      </Drawer>

      {/* Template Modal */}
      {renderTemplateModal()}

      {/* Add styles for magical button */}
      <style>
        {`
          .magical-generate-btn {
            background: linear-gradient(45deg, #722ed1, #1890ff) !important;
            box-shadow: 0 2px 8px rgba(114, 46, 209, 0.35) !important;
            transition: all 0.3s ease !important;
          }
          
          .magical-generate-btn:hover {
            background: linear-gradient(45deg, #531dab, #096dd9) !important;
            box-shadow: 0 4px 12px rgba(114, 46, 209, 0.45) !important;
            transform: translateY(-1px);
          }

          .magical-text {
            background: linear-gradient(45deg, #f0f0f0, #ffffff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        `}
      </style>

      {/* Error Alert */}
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          closable
          onClose={() => setError(null)}
          style={{ 
            borderRadius: 12,
            border: 'none',
            boxShadow: '0 2px 8px rgba(255, 0, 0, 0.1)',
            flexShrink: 0
          }}
        />
      )}

      {/* Client Selection Warning */}
      {!clientName && (
        <Alert
          message="Please Enter Client Name"
          description="You need to enter a client name before you can start recording or using other features. You can either select an existing client or type in a new name."
          type="warning"
          showIcon
          style={{ 
            borderRadius: 12,
            border: 'none',
            boxShadow: '0 2px 8px rgba(250, 173, 20, 0.1)',
            flexShrink: 0,
            marginBottom: '12px'
          }}
        />
      )}
    </div>
  );
}

export default LiveTranscribePage; 