import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Space, Button, Input, Select, Tag, Modal, Form, Radio, message, Card, Typography, Tooltip } from 'antd';
import { FileTextOutlined, EditOutlined, DeleteOutlined, PlusOutlined, GlobalOutlined, TeamOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/stores';
import { getTemplatesAction, createTemplateAction, updateTemplateAction, deleteTemplateAction } from 'src/stores/clients/clients-actions';
import { EUserType } from 'src/variables/enum-variables';
import { EUserProfile } from 'src/variables/storage';
import type { ColumnsType, TableProps } from 'antd/es/table';
import type { BaseTemplate, UITemplate, TemplateFormValues } from 'src/interfaces/template-interface';
import { TRootState } from 'src/stores';

const { Option } = Select;

const TemplatesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useSelector((state: TRootState) => state.user.profile);
  const userRole = profile?.role || '';

  const [templates, setTemplates] = useState<UITemplate[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [typeFilter, setTypeFilter] = useState<'all' | 'private' | 'clinic' | 'public'>('all');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState<UITemplate | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [form] = Form.useForm<TemplateFormValues>();
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    isMobile: window.innerWidth < 768
  });

  // Add window resize handler
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        isMobile: window.innerWidth < 768
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch templates
  const fetchTemplates = useCallback(async () => {
    setLoading(true);
    try {
      const result = await dispatch(getTemplatesAction({})).unwrap();
      const uiTemplates: UITemplate[] = result.map((template: BaseTemplate) => ({
        id: template.id,
        name: template.name,
        description: template.description,
        content: template.content,
        icon: <FileTextOutlined />,
        color: template.color || '#1890ff',
        tags: template.tags || [],
        isPrivate: template.isPrivate,
        clinicId: template.clinicId,
        createdBy: template.createdBy,
        createdAt: new Date(template.createdAt),
        updatedAt: new Date(template.updatedAt)
      }));
      setTemplates(uiTemplates);
    } catch (error) {
      message.error('Failed to load templates');
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  // Responsive columns configuration
  const getResponsiveColumns = (): ColumnsType<UITemplate> => {
    const baseColumns: ColumnsType<UITemplate> = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: UITemplate, b: UITemplate) => a.name.localeCompare(b.name),
        render: (text: string, record: UITemplate) => (
          <Space direction={screenSize.isMobile ? 'vertical' : 'horizontal'} align={screenSize.isMobile ? 'start' : 'center'}>
            <div style={{
              width: 32,
              height: 32,
              borderRadius: '6px',
              background: record.color,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff'
            }}>
              {record.icon}
            </div>
            <Space direction="vertical" size={0}>
              <Typography.Text strong>{text}</Typography.Text>
              <Typography.Text type="secondary" style={{ fontSize: '12px' }}>{record.description}</Typography.Text>
              {screenSize.isMobile && (
                <>
                  <Space size={[0, 4]} wrap>
                    {record.clinicId ? (
                      <Tag icon={<TeamOutlined />} color="cyan">Clinic</Tag>
                    ) : record.isPrivate ? (
                      <Tag icon={<LockOutlined />} color="red">Private</Tag>
                    ) : (
                      <Tag icon={<GlobalOutlined />} color="green">Public</Tag>
                    )}
                    {record.tags?.map((tag: string, index: number) => (
                      <Tag key={index} color="blue">{tag}</Tag>
                    ))}
                  </Space>
                  <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                    Updated: {record.updatedAt.toLocaleDateString()}
                  </Typography.Text>
                </>
              )}
            </Space>
          </Space>
        ),
      }
    ];

    if (!screenSize.isMobile) {
      baseColumns.push(
        {
          title: 'Type',
          key: 'type',
          filters: [
            { text: 'Private', value: 'private' },
            { text: 'Clinic', value: 'clinic' },
            { text: 'Public', value: 'public' },
          ],
          onFilter: (value: string | number | boolean, record: UITemplate) => {
            if (value === 'clinic') return !!record.clinicId;
            if (value === 'private') return record.isPrivate && !record.clinicId;
            return !record.isPrivate && !record.clinicId;
          },
          render: (_: unknown, record: UITemplate) => (
            record.clinicId ? (
              <Tag icon={<TeamOutlined />} color="cyan">Clinic</Tag>
            ) : record.isPrivate ? (
              <Tag icon={<LockOutlined />} color="red">Private</Tag>
            ) : (
              <Tag icon={<GlobalOutlined />} color="green">Public</Tag>
            )
          ),
        } as ColumnsType<UITemplate>[number],
        {
          title: 'Tags',
          key: 'tags',
          dataIndex: 'tags',
          render: (tags: string[]) => (
            <Space wrap size={[0, 8]}>
              {tags?.map((tag: string, index: number) => (
                <Tag key={index} color="blue">{tag}</Tag>
              ))}
            </Space>
          ),
        },
        {
          title: 'Last Updated',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          sorter: (a, b) => a.updatedAt.getTime() - b.updatedAt.getTime(),
          render: (date: Date) => date.toLocaleDateString(),
        }
      );
    }

    baseColumns.push({
      title: 'Actions',
      key: 'actions',
      width: screenSize.isMobile ? 100 : 150,
      render: (_: unknown, record: UITemplate): React.ReactElement | null => {
        const isOwner = record.createdBy === localStorage.getItem(EUserProfile.PROFILE_ID);
        return isOwner ? (
          <Space>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            >
              {!screenSize.isMobile && 'Edit'}
            </Button>
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record)}
            >
              {!screenSize.isMobile && 'Delete'}
            </Button>
          </Space>
        ) : null;
      },
    } as ColumnsType<UITemplate>[number]);

    return baseColumns;
  };

  // Handle table change
  const handleTableChange: TableProps<UITemplate>['onChange'] = (pagination, filters, sorter) => {
    // Handle pagination, filtering, and sorting if needed
  };

  // Handle template creation/update
  const handleSubmit = async (values: TemplateFormValues) => {
    try {
      setIsCreating(true);
      if (editingTemplate) {
        await dispatch(updateTemplateAction({
          id: editingTemplate.id,
          template: {
            ...values,
            isPrivate: values.isPrivate !== 'public',
            clinicId: values.isPrivate === 'clinic' ? profile?.id || undefined : undefined,
          }
        })).unwrap();
        message.success('Template updated successfully');
      } else {
        const templateData = {
          ...values,
          isPrivate: values.isPrivate !== 'public',
          clinicId: values.isPrivate === 'clinic' ? profile?.id || undefined : undefined,
          createdBy: localStorage.getItem(EUserProfile.PROFILE_ID) || '',
          icon: 'FileTextOutlined',
          color: '#1890ff',
        };
        await dispatch(createTemplateAction(templateData)).unwrap();
        message.success('Template created successfully');
      }
      
      setShowCreateModal(false);
      setEditingTemplate(null);
      form.resetFields();
      fetchTemplates();
    } catch (error) {
      message.error(editingTemplate ? 'Failed to update template' : 'Failed to create template');
    } finally {
      setIsCreating(false);
    }
  };

  // Handle template edit
  const handleEdit = (template: UITemplate) => {
    setEditingTemplate(template);
    form.setFieldsValue({
      name: template.name,
      description: template.description,
      content: template.content,
      isPrivate: template.clinicId ? 'clinic' : (template.isPrivate ? 'private' : 'public'),
      tags: template.tags || []
    });
    setShowCreateModal(true);
  };

  // Handle template delete
  const handleDelete = (template: UITemplate) => {
    Modal.confirm({
      title: 'Delete Template',
      content: 'Are you sure you want to delete this template? This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await dispatch(deleteTemplateAction(template.id)).unwrap();
          message.success('Template deleted successfully');
          fetchTemplates();
        } catch (error) {
          message.error('Failed to delete template');
        }
      }
    });
  };

  // Filter templates based on search and type
  const filteredTemplates = templates.filter(template => {
    const matchesSearch = searchQuery.toLowerCase().split(' ').every(term =>
      template.name.toLowerCase().includes(term) ||
      template.description.toLowerCase().includes(term) ||
      template.tags?.some(tag => tag.toLowerCase().includes(term))
    );

    if (!matchesSearch) return false;

    if (typeFilter === 'all') return true;
    if (typeFilter === 'clinic') return !!template.clinicId;
    if (typeFilter === 'private') return template.isPrivate && !template.clinicId;
    return !template.isPrivate && !template.clinicId;
  });

  return (
    <div style={{ padding: screenSize.isMobile ? '12px' : '24px' }}>
      <Card bodyStyle={{ padding: screenSize.isMobile ? '12px' : '24px' }}>
        {/* Header */}
        <div style={{ 
          marginBottom: screenSize.isMobile ? 16 : 24,
          display: 'flex',
          flexDirection: screenSize.isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: screenSize.isMobile ? 'stretch' : 'center',
          gap: screenSize.isMobile ? 12 : 16
        }}>
          <Space.Compact 
            style={{ 
              width: '100%',
              flexDirection: screenSize.isMobile ? 'column' : 'row',
              display: 'flex'
            }}
          >
            <Input.Search
              placeholder="Search templates..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ width: '100%' }}
            />
            <Select
              value={typeFilter}
              onChange={setTypeFilter}
              style={{ width: screenSize.isMobile ? '100%' : 120 }}
            >
              <Option value="all">All</Option>
              <Option value="private">Private</Option>
              {userRole !== EUserType.SOLO_PRACTITIONER && <Option value="clinic">Clinic</Option>}
              <Option value="public">Public</Option>
            </Select>
          </Space.Compact>

          <Button 
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditingTemplate(null);
              form.resetFields();
              setShowCreateModal(true);
            }}
            style={{ width: screenSize.isMobile ? '100%' : 'auto' }}
            disabled
          >
            Create Template
          </Button>
        </div>

        {/* Table */}
        <Table
          columns={getResponsiveColumns()}
          dataSource={filteredTemplates}
          rowKey="id"
          onChange={handleTableChange}
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} templates`,
            size: screenSize.isMobile ? 'small' : 'default'
          }}
          scroll={{ x: true }}
          size={screenSize.isMobile ? 'small' : 'middle'}
        />
      </Card>

      {/* Create/Edit Modal */}
      <Modal
        title={editingTemplate ? "Edit Template" : "Create Template"}
        open={showCreateModal}
        onCancel={() => {
          setShowCreateModal(false);
          setEditingTemplate(null);
          form.resetFields();
        }}
        footer={null}
        width={screenSize.isMobile ? '100%' : 800}
        style={{ top: screenSize.isMobile ? 0 : 100 }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            isPrivate: userRole === EUserType.SOLO_PRACTITIONER ? 'private' : 'clinic',
            tags: ['progress note'],
          }}
        >
          <Form.Item
            name="name"
            label="Template Name"
            rules={[{ required: true, message: 'Please enter template name' }]}
          >
            <Input placeholder="Enter template name" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter template description' }]}
          >
            <Input.TextArea 
              placeholder="Enter template description"
              autoSize={{ minRows: 2, maxRows: 4 }}
            />
          </Form.Item>

          <Form.Item
            name="content"
            label="Template Content"
            rules={[{ required: true, message: 'Please enter template content' }]}
          >
            <Input.TextArea 
              placeholder="Enter template content with placeholders like {{clientName}}"
              autoSize={{ minRows: screenSize.isMobile ? 4 : 6, maxRows: screenSize.isMobile ? 8 : 12 }}
            />
          </Form.Item>

          <Form.Item
            name="isPrivate"
            label="Template Type"
            rules={[{ required: true, message: 'Please select template type' }]}
          >
            <Radio.Group style={{ width: '100%' }}>
              {userRole === EUserType.SOLO_PRACTITIONER ? (
                <Space direction={screenSize.isMobile ? 'vertical' : 'horizontal'} style={{ width: '100%' }}>
                  <Radio.Button value="private">Private</Radio.Button>
                  <Radio.Button value="public">Public</Radio.Button>
                </Space>
              ) : (
                <Space direction={screenSize.isMobile ? 'vertical' : 'horizontal'} style={{ width: '100%' }}>
                  <Radio.Button value="clinic">Clinic</Radio.Button>
                  <Radio.Button value="private">Private</Radio.Button>
                  <Radio.Button value="public">Public</Radio.Button>
                </Space>
              )}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="tags"
            label="Tags"
            rules={[{ required: true, message: 'Please add at least one tag' }]}
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Add tags"
              tokenSeparators={[',']}
            />
          </Form.Item>

          <Form.Item>
            <Space style={{ width: '100%', justifyContent: screenSize.isMobile ? 'center' : 'flex-end' }} direction={screenSize.isMobile ? 'vertical' : 'horizontal'}>
              <Button 
                onClick={() => {
                  setShowCreateModal(false);
                  setEditingTemplate(null);
                  form.resetFields();
                }}
                style={{ width: screenSize.isMobile ? '100%' : 'auto' }}
              >
                Cancel
              </Button>
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={isCreating}
                style={{ width: screenSize.isMobile ? '100%' : 'auto' }}
              >
                {editingTemplate ? 'Update' : 'Create'} Template
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TemplatesPage; 