export enum ESidebarItemLabel {
  PRACTITIONER = 'Practitioner',
  MESSAGES = 'Messages',
  CLIENTS = 'Clients',
  HOMEWORK = 'Homework',
  FILES = 'Files',
  REPORT = 'Report',
  PSYCHOEDUCATION = 'Psychoeducation',
  SESSIONS = 'Sessions',
  TEMPLATES = 'Templates'
}

export type TSidebarItem = {
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  label: ESidebarItemLabel;
  path: string;
};
