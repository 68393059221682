import { Spin } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Grid, Paper, Typography, Box, Avatar, Divider } from '@mui/material';
import { Message, Assignment, People, FormatQuote, Announcement, TrendingUp } from '@mui/icons-material';
import { ReactElement } from 'react';
import React from 'react';

import { showErrorToast } from 'src/components/toast/Toast';
import { TCommonGetDashboardParams, TGetDashboardResponse } from 'src/interfaces/common-interface';
import ResponseError from 'src/interfaces/error-response-interface';
import { RoutePaths } from 'src/routes/routes-constants';
import { getOwnerDashboard } from 'src/services/owner-service';
import { getTrialTime } from 'src/services/payment-service';
import { getPractitionerDashboard } from 'src/services/practitioner-service';
import { TRootState } from 'src/stores';
import { getCurrentTimezone } from 'src/utils/common-utils';
import { EUserType } from 'src/variables/enum-variables';
import { TUserProfile } from 'src/stores/user/user-constants';
import ExpireTrialModal from './components/ExpireTrialModal';
import { getTheMessageOfTheDay } from 'src/services/motd-service';
import { TMessageOfTheDay } from 'src/interfaces/motd-interface';
import { AbstractWellness1, AbstractWellness2, MeditationPattern, WavePattern, HealingPattern } from 'src/assets/images';
import './Home.scss';

// Import the character images
import DashboardBlackMan from 'src/assets/images/dashboard-black-man.png';
import DashboardGirl from 'src/assets/images/dashboard-girl.png';
import DashboardOldMan from 'src/assets/images/dashboard-old-man.png';

interface DashboardCardProps {
  title: string;
  icon: ReactElement;
  content: string | number | ReactElement;
  color: string;
  characterImage?: string;
}

const DashboardCard = ({ title, icon, content, color, characterImage }: DashboardCardProps) => {
  const getPatternBackground = () => {
    switch (title) {
      case "Message of the Day":
        return `url(${AbstractWellness1})`;
      case "Quote of the Day":
        return `url(${MeditationPattern})`;
      case "Active Clients":
        return `url(${HealingPattern})`;
      case "Assigned Tasks":
        return `url(${WavePattern})`;
      case "Recent Messages":
        return `url(${AbstractWellness2})`;
      default:
        return 'none';
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: { xs: 3, sm: 4 },
        height: '100%',
        width: '100%',
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'column',
        background: `linear-gradient(135deg, #fff 0%, ${color}25 100%)`,
        transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
        position: 'relative',
        overflow: 'hidden',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        '&:hover': {
          transform: 'translateY(-8px) scale(1.02)',
          boxShadow: `0 12px 28px -8px ${color}70`,
          '&:before': {
            transform: 'translateX(0)',
          },
          '& .card-icon': {
            transform: 'scale(1.15) rotate(8deg)',
            boxShadow: `0 8px 16px -4px ${color}80`,
          },
          '& .card-title': {
            transform: 'translateX(8px)',
          },
          '& .pattern-bg': {
            opacity: 0.3,
            transform: 'scale(1.1) rotate(-2deg)',
          },
          '& .character-image': {
            transform: 'translateX(-10px) scale(1.05)',
            opacity: 0.9,
          }
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '6px',
          height: '100%',
          background: `linear-gradient(to bottom, ${color}, ${color}90)`,
          transform: 'translateX(-100%)',
          transition: 'transform 0.4s ease-in-out',
        }
      }}
    >
      {characterImage && (
        <Box
          className="character-image"
          sx={{
            position: 'absolute',
            right: { 
              xs: '-60px', 
              sm: title === "Message of the Day" ? '20px' : '-20px'
            },
            bottom: '-20px',
            height: '130%',
            width: 'auto',
            opacity: 0.8,
            transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
            filter: 'drop-shadow(0 4px 12px rgba(0, 0, 0, 0.1))',
            zIndex: 0,
            display: { xs: 'none', md: 'block' },
            transform: 'translateY(10%)',
            '& img': {
              height: '100%',
              width: 'auto',
              objectFit: 'contain',
              objectPosition: 'bottom right',
            }
          }}
        >
          <img src={characterImage} alt="" />
        </Box>
      )}
      <Box
        className="pattern-bg"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: getPatternBackground(),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.2,
          transition: 'all 0.4s ease-in-out',
          pointerEvents: 'none',
          mixBlendMode: 'soft-light',
          zIndex: 1,
        }}
      />
      <Box sx={{ position: 'relative', zIndex: 2, display: 'flex', alignItems: 'center', mb: 3, flexShrink: 0 }}>
        <Box 
          className="card-icon"
          sx={{ 
            backgroundColor: `${color}20`,
            p: 2,
            borderRadius: 2,
            mr: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: `0 6px 16px -8px ${color}40`,
            transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        >
          {React.cloneElement(icon, { sx: { color: color, fontSize: '2.5rem' } })}
        </Box>
        <Typography 
          className="card-title"
          variant="h6" 
          sx={{ 
            fontSize: { xs: '1.3rem', sm: '1.5rem' },
            fontWeight: 600,
            background: `linear-gradient(45deg, ${color}, ${color}99)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            transition: 'transform 0.4s ease-in-out',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box 
        sx={{ 
          position: 'relative',
          zIndex: 2,
          flexGrow: 1,
          overflow: 'auto',
          display: 'flex',
          alignItems: title === "Message of the Day" ? 'flex-start' : 'center',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: `${color}40`,
            borderRadius: '4px',
          },
        }}
      >
        {content}
      </Box>
    </Paper>
  );
};

const QuoteContent = ({ content, author }: { content: string; author?: string }) => (
  <Box
    sx={{
      position: 'relative',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      overflow: 'hidden',
      p: 2
    }}
  >
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'flex-start',
        position: 'relative',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <FormatQuote
          sx={{
            fontSize: '3rem',
            opacity: 0.2,
            color: '#9c27b0',
            transform: 'rotate(180deg)',
            marginRight: '8px',
          }}
        />
        <Typography
          variant="body1"
          component="blockquote"
          sx={{
            fontSize: '1.35rem',
            fontStyle: 'italic',
            color: 'text.secondary',
            fontWeight: 400,
            lineHeight: 1.4,
            letterSpacing: '0.015em',
            m: 0,
          }}
        >
          {content}
        </Typography>
        <FormatQuote
          sx={{
            fontSize: '3rem',
            opacity: 0.2,
            color: '#9c27b0',
            marginLeft: '8px',
          }}
        />
      </Box>
      {author && (
        <Typography
          variant="body2"
          sx={{
            fontSize: '1.1rem',
            color: 'text.secondary',
            opacity: 0.8,
            mt: 'auto',
            pt: 2,
            alignSelf: 'flex-end',
            fontStyle: 'italic',
            '&:before': {
              content: '"— "',
            },
          }}
        >
          {author}
        </Typography>
      )}
    </Box>
  </Box>
);

const USER_ROLE_DISPLAY = {
  [EUserType.CLINIC_OWNER]: 'Clinic Owner',
  [EUserType.SOLO_PRACTITIONER]: 'Solo Practitioner',
  [EUserType.OWNER]: 'Owner',
  [EUserType.PRACTITIONER]: 'Practitioner',
};

const WelcomeBanner = ({ profile }: { profile: TUserProfile | undefined }) => (
  <Paper
    elevation={0}
    sx={{
      p: { xs: 3, sm: 4 },
      width: '100%',
      height: '100%',
      borderRadius: 3,
      background: 'linear-gradient(135deg, #48ABE2 0%, #2196f3 100%)',
      color: 'white',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 20px 40px -12px rgba(72, 171, 226, 0.4)',
        '& .welcome-avatar': {
          transform: 'scale(1.1) rotate(8deg)',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
        '& .pattern-overlay': {
          opacity: 0.25,
          transform: 'scale(1.1) rotate(-2deg)',
        },
        '& .character-image': {
          transform: 'scale(1.05)',
          opacity: 0.95,
        }
      }
    }}
  >
    <Box
      className="pattern-overlay"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage: `url(${WavePattern})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.2,
        transition: 'all 0.4s ease-in-out',
        mixBlendMode: 'soft-light',
      }}
    />
    <Grid container sx={{ position: 'relative', zIndex: 1, height: '100%' }}>
      {/* Left Column - Content */}
      <Grid item xs={12} md={7} sx={{ 
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: { xs: 'center', sm: 'center' }, 
          gap: { xs: 2, sm: 3 },
          flexDirection: { xs: 'column', sm: 'row' }
        }}>
          <Avatar
            className="welcome-avatar"
            sx={{
              width: { xs: 72, sm: 84 },
              height: { xs: 72, sm: 84 },
              bgcolor: 'rgba(255, 255, 255, 0.2)',
              border: '2px solid rgba(255, 255, 255, 0.5)',
              flexShrink: 0,
              fontSize: { xs: '2.5rem', sm: '3rem' },
              transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            {profile?.firstName?.[0]}
          </Avatar>
          <Box sx={{ 
            flex: 1,
            textAlign: { xs: 'center', sm: 'left' }
          }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 600,
                fontSize: { xs: '2rem', sm: '2.75rem' },
                lineHeight: 1.2,
                mb: 1,
                textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              Welcome back, {profile?.firstName || 'User'}!
            </Typography>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                opacity: 0.9,
                fontSize: { xs: '1.1rem', sm: '1.35rem' },
                textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
              }}
            >
              {new Date().toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* Right Column - Character Image */}
      <Grid item xs={12} md={5} sx={{ 
        display: { xs: 'none', md: 'flex' },
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        position: 'relative'
      }}>
        <Box
          className="character-image"
          sx={{
            height: '130%',
            position: 'absolute',
            bottom: '-40px',
            opacity: 0.9,
            transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
            filter: 'drop-shadow(0 4px 12px rgba(0, 0, 0, 0.1))',
            '& img': {
              height: '100%',
              width: 'auto',
              objectFit: 'contain',
              objectPosition: 'center center',
            }
          }}
        >
          <img src={DashboardGirl} alt="" />
        </Box>
      </Grid>
    </Grid>
  </Paper>
);

const Home = () => {
  const navigate = useNavigate();
  const userId = useSelector((state: TRootState) => state.user.id);
  const profile = useSelector((state: TRootState) => state.user.profile);
  const quote = useSelector((state: TRootState) => state.user.quote);
  const isOwnerRole = profile?.role && profile?.role === EUserType.OWNER;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [motd, setMotd] = useState<TMessageOfTheDay | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const requestDashboardParams: TCommonGetDashboardParams = {
    timezome: getCurrentTimezone(),
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openExpireTrialModal, setOpenExpireTrialModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [dashboardDetail, setDashboardDetail] = useState<TGetDashboardResponse>();

  const fetchTrialTime = async () => {
    try {
      const trial = await getTrialTime();
      if (trial.trialEnd) {
        const remainingTrialTime = dayjs(trial.trialEnd).diff(dayjs(), 'd') + 1;

        if (remainingTrialTime <= 0) {
          setOpenExpireTrialModal(true);
        } else if (remainingTrialTime <= 3) {
          setCount(remainingTrialTime);
          setOpenExpireTrialModal(true);
        }
      }
    } catch (error) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
    }
  };

  const handleSubmitExpireTrialModal = useCallback(() => {
    setOpenExpireTrialModal(false);
    setTimeout(() => {
      navigate(RoutePaths.PRICING_PACKAGE);
    }, 100);
  }, [navigate]);

  const fetchMotd = useCallback(async () => {
    try {
      const response = await getTheMessageOfTheDay();
      console.log('response', response);
      setMotd(response);
    } catch (error) {
      showErrorToast('Failed to fetch Message of the Day');
    }
  }, []);

  const fetchDashboardData = useCallback(async () => {
    try {
      if (isOwnerRole === undefined) return;

      const response = isOwnerRole
        ? await getOwnerDashboard(requestDashboardParams)
        : await getPractitionerDashboard(requestDashboardParams);

      if (response) {
        setDashboardDetail(response);
      }
    } catch (error) {
      showErrorToast(`Get ${isOwnerRole ? 'owner' : 'practitioner'} dashboard failed!`);
    } finally {
      setIsLoading(false);
    }
  }, [isOwnerRole]);

  // Update initial data fetch to include MOTD
  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([
        fetchDashboardData(),
        fetchMotd(),
        profile?.role &&
        (profile.role === EUserType.OWNER || profile.role === EUserType.SOLO_PRACTITIONER)
          ? fetchTrialTime()
          : Promise.resolve(),
      ]);
    };

    initializeData();
  }, [profile?.role, isOwnerRole, fetchDashboardData, fetchMotd]);

  const options = {
    month: 'long', // Full month name (e.g., January)
    day: 'numeric', // Numeric day (e.g., 1, 2, 3)
    year: 'numeric', // Full year (e.g., 2023)
  } as Intl.DateTimeFormatOptions;

  return (
    <Box 
      className="Home" 
      sx={{
        width: { xs: '100%', md: 'calc(100% - 96px)' },
        height: 'calc(100vh - 64px)',
        backgroundColor: '#f8fafc',
        backgroundImage: 'linear-gradient(180deg, #ffffff 0%, #f8fafc 100%)',
        padding: { xs: '24px 16px', sm: '32px 24px', md: '32px' },
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        position: 'absolute',
        top: '64px',
        left: { xs: 0, md: '96px' },
        right: 0,
        bottom: 0,
      }}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Spin />
        </Box>
      ) : (
        <Box 
          sx={{ 
            width: '100%',
            maxWidth: '2000px',
            margin: '0 auto',
            height: '100%',
          }}
        >
          <Grid 
            container 
            spacing={3}
            sx={{ 
              width: '100%',
              margin: 0,
              height: '100%',
              '& > .MuiGrid-item': {
                display: 'flex',
              }
            }}
          >
            {/* Top Row: Welcome Banner and Message of the Day */}
            <Grid item xs={12} md={6} sx={{ height: '40%' }}>
              <WelcomeBanner profile={profile} />
            </Grid>

            <Grid item xs={12} md={6} sx={{ height: '40%' }}>
              <DashboardCard
                title="Message of the Day"
                icon={<Announcement />}
                content={
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start', 
                    gap: 2,
                    height: '100%',
                    overflow: 'auto',
                    p: 2
                  }}>
                    {motd ? (
                      <Typography 
                        variant="body1" 
                        dangerouslySetInnerHTML={{ __html: motd.patchNotes }}
                        sx={{ 
                          whiteSpace: 'pre-wrap',
                          '& p': { 
                            margin: 0,
                            fontSize: '1.25rem',
                            lineHeight: 1.6,
                            color: 'text.secondary'
                          }
                        }}
                      />
                    ) : (
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin />
                      </Box>
                    )}
                  </Box>
                }
                color="#48ABE2"
              />
            </Grid>

            {/* Bottom Row: Stats Cards */}
            <Grid item xs={12} sm={6} md={3} sx={{ height: '55%' }}>
              <Link 
                to="/clients" 
                style={{ textDecoration: 'none', width: '100%', height: '100%' }}
              >
                <DashboardCard
                  title="Active Clients"
                  icon={<People />}
                  content={
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      position: 'relative',
                      p: 2,
                      width: '100%'
                    }}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        flex: 1
                      }}>
                        <Typography 
                          variant="h2" 
                          sx={{ 
                            fontWeight: 600, 
                            color: '#45C7E8', 
                            fontSize: '4.5rem',
                            textAlign: 'center',
                            lineHeight: 1,
                            mb: 2
                          }}
                        >
                          {dashboardDetail?.statistic?.numberActiveClient || 0}
                        </Typography>
                      </Box>
                      <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        pb: 2
                      }}>
                        <Typography 
                          sx={{ 
                            fontSize: '1.1rem', 
                            color: 'text.secondary', 
                            fontWeight: 500,
                            textAlign: 'center',
                            opacity: 0.8
                          }}
                        >
                          active clients
                        </Typography>
                      </Box>
                    </Box>
                  }
                  color="#45C7E8"
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ height: '55%' }}>
              <DashboardCard
                title="Assigned Tasks"
                icon={<Assignment />}
                content={
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    position: 'relative',
                    p: 2,
                    width: '100%'
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      flex: 1
                    }}>
                      <Typography 
                        variant="h2" 
                        sx={{ 
                          fontWeight: 600, 
                          color: '#4B9FD1', 
                          fontSize: '4.5rem',
                          textAlign: 'center',
                          lineHeight: 1,
                          mb: 2
                        }}
                      >
                        {dashboardDetail?.statistic?.numberAssignedTask || 0}
                      </Typography>
                    </Box>
                    <Box sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      pb: 2
                    }}>
                      <Typography 
                        sx={{ 
                          fontSize: '1.1rem', 
                          color: 'text.secondary', 
                          fontWeight: 500,
                          textAlign: 'center',
                          opacity: 0.8
                        }}
                      >
                        tasks pending
                      </Typography>
                    </Box>
                  </Box>
                }
                color="#4B9FD1"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ height: '55%' }}>
              <Link 
                to="/messages" 
                style={{ textDecoration: 'none', width: '100%', height: '100%' }}
              >
                <DashboardCard
                  title="Recent Messages"
                  icon={<Message />}
                  content={
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      position: 'relative',
                      p: 2,
                      width: '100%'
                    }}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        flex: 1
                      }}>
                        <Typography 
                          variant="h2" 
                          sx={{ 
                            fontWeight: 600, 
                            color: '#3F93C0', 
                            fontSize: '4.5rem',
                            textAlign: 'center',
                            lineHeight: 1,
                            mb: 2
                          }}
                        >
                          {dashboardDetail?.newMessages?.length || 0}
                        </Typography>
                      </Box>
                      <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        pb: 2
                      }}>
                        <Typography 
                          sx={{ 
                            fontSize: '1.1rem', 
                            color: 'text.secondary', 
                            fontWeight: 500,
                            textAlign: 'center',
                            opacity: 0.8
                          }}
                        >
                          unread messages
                        </Typography>
                      </Box>
                    </Box>
                  }
                  color="#3F93C0"
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ height: '55%' }}>
              <DashboardCard
                title="Quote of the Day"
                icon={<FormatQuote />}
                content={<QuoteContent content={quote?.message || "Success is not final, failure is not fatal: it is the courage to continue that counts."} author={quote?.author} />}
                color="#4B9FD1"
              />
            </Grid>
          </Grid>

          <ExpireTrialModal
            open={openExpireTrialModal}
            onCancel={() => setOpenExpireTrialModal(false)}
            handleSubmit={handleSubmitExpireTrialModal}
            count={count}
          />
        </Box>
      )}
    </Box>
  );
};

export default Home;
