import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Button, Space, Alert, Typography, Card, List, Tag, Spin, Progress, Tooltip, Tabs, Input, Modal, message } from 'antd';
import { AudioOutlined, LoadingOutlined, AudioMutedOutlined, InfoCircleOutlined, SmileOutlined, FrownOutlined, MehOutlined, CloseOutlined, FileTextOutlined, EditOutlined, DeleteOutlined, MergeCellsOutlined, CheckOutlined, CopyOutlined, FilePdfOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandSparkles, faHatWizard } from '@fortawesome/free-solid-svg-icons';
import { getSocket } from 'src/configs/socket-config';
import './SessionsTab.scss';
import type { TabsProps } from 'antd';
import { exportToPDF } from 'src/utils/document-export';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

interface GeneratedDoc {
  id: string;
  content: string;
  name: string;
  isGenerated?: boolean;
}

interface TemplateVariable {
  name: string;
  value: string;
  type: 'system' | 'custom';
  description: string;
}

interface SystemVariable {
  key: string;
  description: string;
}

const SYSTEM_VARIABLES: SystemVariable[] = [
  { key: 'client_name', description: 'Client name' },
  { key: 'date', description: 'Current date' },
  { key: 'time', description: 'Current time' }
];

interface LiveTranscribeTabProps {
  clientId: string;
  isRecording: boolean;
  onRecordingStateChange: (recording: boolean, initializing: boolean) => void;
  onTranscriptStateChange: (hasTranscript: boolean) => void;
  onClearCallback: (callback: () => void) => void;
  onTranscriptUpdate: (segments: Array<{
    speaker: string;
    text: string;
    startTime: number;
    sentiment?: {
      score: number;
      label: 'positive' | 'negative' | 'neutral';
    };
  }>) => void;
  quickNotes?: Array<{
    timestamp: number;
    note: string;
    type: 'important' | 'followup' | 'observation';
  }>;
  selectedTemplate?: {
    id: string;
    name: string;
    content: string;
  } | null;
  onTemplateClose?: () => void;
  renderTemplateVariables?: () => React.ReactNode;
  isAddingVariable?: boolean;
  setIsAddingVariable?: (isAdding: boolean) => void;
  newVariableName?: string;
  setNewVariableName?: (name: string) => void;
  newVariableValue?: string;
  setNewVariableValue?: (value: string) => void;
  handleAddCustomVariable?: () => void;
  isGeneratingDocument?: boolean;
  generatedDocument?: string;
  generatedDocs: Array<{id: string; content: string; name: string; isGenerated?: boolean}>;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  onCloseDocument: (docId: string) => void;
  customVariables?: TemplateVariable[];
  isTemplateVariablesCollapsed?: boolean;
}

interface TranscriptionSegment {
  speaker: string;
  text: string;
  confidence: number;
  startTime: number;
  endTime: number;
  sentiment?: {
    score: number;  // -1 to 1 where -1 is negative, 0 is neutral, 1 is positive
    label: 'positive' | 'negative' | 'neutral';
  };
}

interface TranscriptionResult {
  sessionId: string;
  transcript: string;
  isPartial: boolean;
  speakers: TranscriptionSegment[];
}

interface CustomMediaRecorder {
  stream: MediaStream;
  audioContext: AudioContext;
  processor: ScriptProcessorNode;
  source: MediaStreamAudioSourceNode;
  stop: () => void;
}

interface SelectedSegment {
  id: number;
  segment: TranscriptionSegment;
}

const LiveTranscribeTab: React.FC<LiveTranscribeTabProps> = ({ 
  clientId, 
  isRecording, 
  onRecordingStateChange, 
  onTranscriptStateChange, 
  onClearCallback,
  onTranscriptUpdate,
  quickNotes = [],
  selectedTemplate,
  onTemplateClose,
  renderTemplateVariables,
  isAddingVariable,
  setIsAddingVariable,
  newVariableName,
  setNewVariableName,
  newVariableValue,
  setNewVariableValue,
  handleAddCustomVariable,
  isGeneratingDocument,
  generatedDocument,
  generatedDocs,
  activeTab,
  setActiveTab,
  onCloseDocument,
  customVariables = [],
  isTemplateVariablesCollapsed
}) => {
  const [error, setError] = useState<string | null>(null);
  const [showTemplateDrawer, setShowTemplateDrawer] = useState(false);
  const [transcriptionResults, setTranscriptionResults] = useState<TranscriptionSegment[]>([]);
  const [partialResults, setPartialResults] = useState<TranscriptionSegment[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showSentimentSummary, setShowSentimentSummary] = useState(true);
  const [templateContent, setTemplateContent] = useState(selectedTemplate?.content || '');
  const mediaRecorderRef = useRef<CustomMediaRecorder | null>(null);
  const sessionIdRef = useRef<string | null>(null);
  const socketRef = useRef(getSocket());
  const partialResultsRef = useRef<HTMLDivElement>(null);
  const transcriptContainerRef = useRef<HTMLDivElement>(null);
  const hasExistingTranscript = transcriptionResults.length > 0;
  const [editingSegmentId, setEditingSegmentId] = useState<number | null>(null);
  const [editedText, setEditedText] = useState('');
  const [selectedSegments, setSelectedSegments] = useState<SelectedSegment[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showGeneratedDoc, setShowGeneratedDoc] = useState(false);
  const prevTranscriptRef = useRef<{ speaker: string; text: string; startTime: number; }[]>([]);
  const lastEndTimeRef = useRef<number>(0);

  // Add effect to update lastEndTime when transcriptionResults change
  useEffect(() => {
    if (transcriptionResults.length > 0) {
      const lastSegment = transcriptionResults[transcriptionResults.length - 1];
      lastEndTimeRef.current = lastSegment.endTime || lastSegment.startTime;
    }
  }, [transcriptionResults]);

  // Add effect to reset lastEndTime when clearing transcript
  useEffect(() => {
    if (!hasExistingTranscript) {
      lastEndTimeRef.current = 0;
    }
  }, [hasExistingTranscript]);

  // Memoize sentiment summary calculation
  const sentimentSummary = useMemo(() => {
    if (transcriptionResults.length === 0) return null;

    const summary = {
      positive: 0,
      negative: 0,
      neutral: 0,
      total: 0
    };

    transcriptionResults.forEach(segment => {
      if (segment.sentiment) {
        summary[segment.sentiment.label]++;
        summary.total++;
      }
    });

    return summary;
  }, [transcriptionResults]);

  // Create a stable tooltip title
  const tooltipTitle = useMemo(() => 
    showSentimentSummary ? "Hide Sentiment Analysis" : "Show Sentiment Analysis"
  , [showSentimentSummary]);

  // Separate the button click handler
  const handleSentimentToggle = useCallback(() => {
    setShowSentimentSummary(prev => !prev);
  }, []);

  // Memoize the sentiment toggle button with stable props
  const sentimentToggleButton = useMemo(() => {
    if (!hasExistingTranscript) return null;

    return (
      <Button
        type={showSentimentSummary ? "primary" : "default"}
        icon={<SmileOutlined />}
        onClick={handleSentimentToggle}
        size="small"
        style={{ 
          borderRadius: '6px',
          padding: '4px 12px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          height: '32px'
        }}
      >
        Sentiment
      </Button>
    );
  }, [hasExistingTranscript, showSentimentSummary, handleSentimentToggle]);

  // Memoize the entire tooltip component
  const sentimentToggle = useMemo(() => {
    if (!hasExistingTranscript) return null;

    return (
      <Tooltip title={tooltipTitle}>
        {sentimentToggleButton}
      </Tooltip>
    );
  }, [hasExistingTranscript, tooltipTitle, sentimentToggleButton]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Effect to handle external recording control
  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else if (mediaRecorderRef.current) {
      stopRecording();
    }
  }, [isRecording]);

  async function startRecording() {
    try {
      setError(null);

      socketRef.current.cleanupTranscriptionListeners();

      if (!hasExistingTranscript) {
        setTranscriptionResults([]);
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          sampleSize: 16,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });

      const sessionId = await socketRef.current.startTranscription();
      sessionIdRef.current = sessionId;

      const audioContext = new AudioContext({ sampleRate: 16000 });
      const source = audioContext.createMediaStreamSource(stream);
      const processor = audioContext.createScriptProcessor(2048, 1, 1);
      source.connect(processor);
      processor.connect(audioContext.destination);

      processor.onaudioprocess = (e) => {
        if (sessionIdRef.current) {
          try {
            const inputData = e.inputBuffer.getChannelData(0);
            const pcmData = new Int16Array(inputData.length);
            for (let i = 0; i < inputData.length; i++) {
              const s = Math.max(-1, Math.min(1, inputData[i]));
              pcmData[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
            }

            // Split data into smaller chunks (16KB each) to prevent AWS Transcribe errors
            const CHUNK_SIZE = 16 * 1024; // 16KB chunks (half of server's max)
            for (let i = 0; i < pcmData.length; i += CHUNK_SIZE) {
              const chunk = pcmData.slice(i, i + CHUNK_SIZE);
              socketRef.current.streamAudio(sessionIdRef.current!, chunk);
            }
          } catch (err) {
            setError(err instanceof Error ? err.message : 'Error processing audio data');
          }
        }
      };

      mediaRecorderRef.current = {
        stream,
        audioContext,
        processor,
        source,
        stop: () => {
          processor.disconnect();
          source.disconnect();
          audioContext.close();
          stream.getTracks().forEach((track) => track.stop());
        },
      };

      socketRef.current.listenTranscriptionResults((result: TranscriptionResult) => {
        const formattedSpeakers = result.speakers.map(speaker => {
          const baseSegment = {
            ...speaker,
            speaker: speaker.speaker.startsWith('spk_') ? speaker.speaker : `spk_${speaker.speaker}`,
            // Ensure endTime is always a number by defaulting to startTime if not provided
            endTime: speaker.endTime || speaker.startTime
          };

          // Add the lastEndTime to timestamps when appending
          if (hasExistingTranscript) {
            return {
              ...baseSegment,
              startTime: baseSegment.startTime + lastEndTimeRef.current,
              endTime: baseSegment.endTime + lastEndTimeRef.current
            } as TranscriptionSegment;
          }

          return baseSegment as TranscriptionSegment;
        });

        if (result.isPartial) {
          setPartialResults(formattedSpeakers);
        } else if (formattedSpeakers.length > 0) {
          setTranscriptionResults(prev => [...prev, ...formattedSpeakers]);
          setPartialResults([]);
        }
      });

      socketRef.current.listenTranscriptionErrors((err: { message: string; code?: string }) => {
        const errorMessage = err.code 
          ? `Error (${err.code}): ${err.message}`
          : err.message || 'An error occurred during transcription';
        setError(errorMessage);
        onRecordingStateChange(false, false);
      });
    } catch (err) {
      onRecordingStateChange(false, false);
      setError(err instanceof Error ? err.message : 'Failed to start recording');
    }
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }

    // Add any partial results to the final transcript with Unknown speaker
    if (partialResults.length > 0) {
      const finalizedPartials = partialResults.map(segment => ({
        ...segment,
        speaker: 'spk_unknown',
        confidence: 0.6 // Medium confidence for partial results
      }));
      setTranscriptionResults(prev => [...prev, ...finalizedPartials]);
      setPartialResults([]);
    }

    if (sessionIdRef.current) {
      socketRef.current.stopTranscription(sessionIdRef.current);
      sessionIdRef.current = null;
    }

    onRecordingStateChange(false, false);
  };

  useEffect(() => {
    return () => {
      stopRecording();
      socketRef.current.cleanupTranscriptionListeners();
    };
  }, []);

  const scrollToBottom = useCallback(() => {
    if (transcriptContainerRef.current) {
      const container = transcriptContainerRef.current;
      const shouldAutoScroll = container.scrollHeight - container.scrollTop <= container.clientHeight + 100;
      
      if (isRecording || shouldAutoScroll) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  }, [isRecording]);

  // Effect to auto-scroll
  useEffect(() => {
    if (transcriptionResults.length > 0 || partialResults.length > 0) {
      const timer = setTimeout(() => {
        scrollToBottom();
      }, 100);
      
      return () => {
        clearTimeout(timer);
      };
    }
  }, [transcriptionResults, partialResults, scrollToBottom]);

  // Effect to notify parent of transcript state changes
  useEffect(() => {
    const hasTranscript = transcriptionResults.length > 0;
    onTranscriptStateChange(hasTranscript);
  }, [transcriptionResults.length, onTranscriptStateChange]);

  // Effect to provide clear callback to parent
  useEffect(() => {
    const clearCallback = () => {
      setTranscriptionResults([]);
      setPartialResults([]);
    };
    onClearCallback(clearCallback);
  }, []);

  // Update template content when selected template changes
  useEffect(() => {
    if (selectedTemplate) {
      setTemplateContent(selectedTemplate.content);
      setActiveTab('template');
    } else {
      setTemplateContent('');
      // Only change tab if we're currently on template tab
      if (activeTab === 'template') {
        setActiveTab('transcript');
      }
    }
  }, [selectedTemplate]); // Remove activeTab from dependencies to break circular dependency

  const calculateMergedConfidence = (segments: TranscriptionSegment[]): number => {
    // Weight confidence by text length
    const totalLength = segments.reduce((sum, seg) => sum + seg.text.length, 0);
    const weightedConfidence = segments.reduce(
      (sum, seg) => sum + (seg.confidence * seg.text.length) / totalLength,
      0
    );
    return weightedConfidence;
  };

  const calculateMergedSentiment = (segments: TranscriptionSegment[]): TranscriptionSegment['sentiment'] | undefined => {
    const validSentiments = segments.filter(s => s.sentiment).map(s => s.sentiment!);
    if (validSentiments.length === 0) return undefined;

    const totalLength = segments.reduce((sum, seg) => sum + seg.text.length, 0);
    const weightedScore = segments.reduce((sum, seg) => {
      if (!seg.sentiment) return sum;
      return sum + (seg.sentiment.score * seg.text.length) / totalLength;
      }, 0);

    return {
      score: weightedScore,
      label: weightedScore > 0.2 ? 'positive' : weightedScore < -0.2 ? 'negative' : 'neutral'
    };
  };

  const handleEditSegment = (index: number, segment: TranscriptionSegment) => {
    setEditingSegmentId(index);
    setEditedText(segment.text);
    setIsEditing(true);
  };

  const handleSaveEdit = (index: number) => {
    const updatedResults = [...transcriptionResults];
    updatedResults[index] = {
      ...updatedResults[index],
      text: editedText
    };
    setTranscriptionResults(updatedResults);
    setEditingSegmentId(null);
    setEditedText('');
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditingSegmentId(null);
    setEditedText('');
    setIsEditing(false);
  };

  const handleDeleteSegment = (index: number) => {
    const updatedResults = transcriptionResults.filter((_, i) => i !== index);
    setTranscriptionResults(updatedResults);
  };

  const handleToggleSegmentSelection = (index: number, segment: TranscriptionSegment) => {
    setSelectedSegments(prev => {
      const isSelected = prev.some(s => s.id === index);
      if (isSelected) {
        return prev.filter(s => s.id !== index);
      } else {
        return [...prev, { id: index, segment }];
      }
    });
  };

  const handleMergeSegments = () => {
    if (selectedSegments.length < 2) return;

    // Sort segments by their position in the transcript
    const sortedSegments = selectedSegments.sort((a, b) => a.id - b.id);
    
    // Create merged segment
    const mergedSegment: TranscriptionSegment = {
      speaker: sortedSegments[0].segment.speaker, // Use first segment's speaker
      text: sortedSegments.map(s => s.segment.text).join(' '),
      confidence: calculateMergedConfidence(sortedSegments.map(s => s.segment)),
      startTime: sortedSegments[0].segment.startTime,
      endTime: sortedSegments[sortedSegments.length - 1].segment.endTime,
      sentiment: calculateMergedSentiment(sortedSegments.map(s => s.segment))
    };

    // Create new transcript array with merged segment
    const updatedResults = transcriptionResults.filter(
      (_, index) => !selectedSegments.some(s => s.id === index)
    );
    updatedResults.splice(sortedSegments[0].id, 0, mergedSegment);

    setTranscriptionResults(updatedResults);
    setSelectedSegments([]);
  };

  const renderTranscriptionSegment = (segment: TranscriptionSegment, index: number, isPartial = false) => {
    const confidenceColor = segment.confidence >= 0.8 ? 'success' : 
                          segment.confidence >= 0.6 ? 'warning' : 'error';

    const speakerNumber = segment.speaker === 'spk_unknown' ? -1 :
                         segment.speaker.startsWith('spk_') 
                         ? parseInt(segment.speaker.split('_')[1]) + 1
                         : parseInt(segment.speaker) + 1;

    const speakerColor = speakerNumber === -1 ? '#8c8c8c' : 
                        ['#2196F3', '#FF9800', '#4CAF50', '#9C27B0', '#00BCD4'][speakerNumber % 5];
    const backgroundColor = speakerNumber === -1 ? 'rgba(0, 0, 0, 0.02)' :
                          [
                            'rgba(33, 150, 243, 0.05)',
                            'rgba(255, 152, 0, 0.05)',
                            'rgba(76, 175, 80, 0.05)',
                            'rgba(156, 39, 176, 0.05)',
                            'rgba(0, 188, 212, 0.05)'
                          ][speakerNumber % 5];

    const segmentNotes = quickNotes.filter(note => {
      return note.timestamp >= segment.startTime && note.timestamp <= segment.endTime;
    });

    const getSentimentInfo = () => {
      if (!segment.sentiment || isPartial) return null;
      
      const sentimentColor = 
        segment.sentiment.label === 'positive' ? '#52c41a' :
        segment.sentiment.label === 'negative' ? '#ff4d4f' : '#1890ff';
      
      const sentimentIcon = 
        segment.sentiment.label === 'positive' ? <SmileOutlined /> :
        segment.sentiment.label === 'negative' ? <FrownOutlined /> : <MehOutlined />;

      return { color: sentimentColor, icon: sentimentIcon };
    };

    const sentimentInfo = getSentimentInfo();

    return (
      <Card 
        size="small" 
        className="transcription-segment"
        style={{ 
          backgroundColor: isPartial ? 'rgba(0, 0, 0, 0.02)' : backgroundColor,
          borderRadius: 12,
          border: selectedSegments.some(s => s.id === index) ? '2px solid #1890ff' : 'none',
          boxShadow: isPartial ? 'none' : '0 2px 4px rgba(0,0,0,0.05)',
          transition: 'all 0.3s ease',
          width: '100%',
          cursor: !isPartial && !isEditing ? 'pointer' : 'default'
        }}
        onClick={() => !isPartial && !isEditing && handleToggleSegmentSelection(index, segment)}
      >
        <Space direction="vertical" style={{ width: '100%' }} size={8}>
          <div style={{ 
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '8px' : '12px',
            alignItems: isMobile ? 'flex-start' : 'center',
            width: '100%'
          }}>
            {/* Left group - Speaker and confidence */}
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              flexWrap: 'wrap',
              flex: isMobile ? '1 1 100%' : '1'
            }}>
              {isPartial ? (
                <Tag color="processing" icon={<LoadingOutlined />}>Live</Tag>
              ) : (
                <>
                  <Tag color={speakerColor} style={{ 
                    borderRadius: 16, 
                    padding: '4px 12px', 
                    fontSize: '14px', 
                    border: 'none',
                    whiteSpace: 'nowrap'
                  }}>
                    {speakerNumber === -1 ? 'Unknown Speaker' : `Speaker ${speakerNumber}`}
                  </Tag>
                  <Tag color={confidenceColor} style={{ 
                    borderRadius: 16, 
                    padding: '4px 12px', 
                    fontSize: '14px',
                    whiteSpace: 'nowrap'
                  }}>
                    {Math.round(segment.confidence * 100)}% confidence
                  </Tag>
                  {sentimentInfo && (
                    <Tag 
                      icon={sentimentInfo.icon}
                      color={sentimentInfo.color}
                      style={{ 
                        borderRadius: 16,
                        padding: '4px 12px',
                        fontSize: '14px',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {segment.sentiment?.label}
                    </Tag>
                  )}
                </>
              )}
            </div>

            {/* Right group - Time and actions */}
            {!isPartial && (
              <Space size={8} style={{ marginLeft: 'auto', flexShrink: 0 }}>
                <Text type="secondary" style={{ 
                  fontSize: '14px',
                  whiteSpace: 'nowrap'
                }}>
                  {new Date(segment.startTime * 1000).toISOString().substr(14, 5)}
                </Text>
                {editingSegmentId === index ? (
                  <Space>
                    <Tooltip title="Save">
                      <Button
                        type="text"
                        icon={<CheckOutlined />}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSaveEdit(index);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Cancel">
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCancelEdit();
                        }}
                      />
                    </Tooltip>
                  </Space>
                ) : (
                  <Space>
                    <Tooltip title="Edit">
                      <Button
                        type="text"
                        icon={<EditOutlined />}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditSegment(index, segment);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          Modal.confirm({
                            title: 'Delete Segment',
                            content: 'Are you sure you want to delete this segment?',
                            okText: 'Delete',
                            okType: 'danger',
                            cancelText: 'Cancel',
                            onOk: () => handleDeleteSegment(index)
                          });
                        }}
                      />
                    </Tooltip>
                  </Space>
                )}
              </Space>
            )}
          </div>

          {/* Transcript text */}
          {editingSegmentId === index ? (
            <Input.TextArea
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              autoSize={{ minRows: 2 }}
              onClick={(e) => e.stopPropagation()}
              style={{ 
                fontSize: isMobile ? '15px' : '16px', 
                lineHeight: '1.6',
                marginTop: 8
              }}
            />
          ) : (
            <Text style={{ 
              fontSize: isMobile ? '15px' : '16px', 
              lineHeight: '1.6',
              wordBreak: 'break-word'
            }}>
              {segment.text}
            </Text>
          )}
          
          {/* Quick Notes for this segment */}
          {segmentNotes.length > 0 && (
            <div style={{ 
              marginTop: 8,
              padding: '8px',
              background: 'rgba(0, 0, 0, 0.02)',
              borderRadius: 8
            }}>
              {segmentNotes.map((note, index) => (
                <div key={index} style={{ 
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  gap: isMobile ? '4px' : '8px',
                  marginBottom: index < segmentNotes.length - 1 ? 8 : 0,
                  padding: isMobile ? '4px 0' : 0
                }}>
                  <Tag color={
                    note.type === 'important' ? 'error' :
                    note.type === 'followup' ? 'processing' :
                    'default'
                  } style={{
                    whiteSpace: 'nowrap'
                  }}>
                    {note.type}
                  </Tag>
                  <Text style={{
                    flex: 1,
                    wordBreak: 'break-word'
                  }}>
                    {note.note}
                  </Text>
                </div>
              ))}
            </div>
          )}
        </Space>
      </Card>
    );
  };

  const renderTemplateContent = () => (
    <div style={{ 
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#fff',
      borderRadius: '12px',
      border: '1px solid rgba(0, 0, 0, 0.06)',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
      overflow: 'hidden',
      height: '100%'
    }}>
      {/* Template Variables Section */}
      <div style={{
        flexShrink: 0,
        maxHeight: isTemplateVariablesCollapsed ? '40px' : '40vh',
        overflow: 'hidden',
        borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
        transition: 'max-height 0.3s ease-in-out'
      }}>
        {renderTemplateVariables && renderTemplateVariables()}
      </div>

      {/* Template Editor */}
      <div style={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        <Input.TextArea
          value={templateContent}
          onChange={handleTemplateContentChange}
          style={{ 
            flex: 1,
            height: '100%',
            resize: 'none',
            fontSize: '16px',
            lineHeight: '1.6',
            padding: '16px',
            border: 'none'
          }}
        />
      </div>
    </div>
  );

  // Memoize handlers
  const handleTemplateContentChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTemplateContent(e.target.value);
  }, []);

  const handleCloseDocument = useCallback((docId: string, e?: React.MouseEvent) => {
    e?.stopPropagation();
    onCloseDocument(docId);
  }, [onCloseDocument]);

  // Memoize the mapped transcript data
  const mappedTranscriptData = useMemo(() => {
    // Calculate overall sentiment summary
    const summary = transcriptionResults.reduce((acc, segment) => {
      if (segment.sentiment) {
        acc[segment.sentiment.label]++;
        acc.total++;
      }
      return acc;
    }, { positive: 0, negative: 0, neutral: 0, total: 0 });

    // Create sentiment summary segment
    const sentimentSummarySegment = {
      speaker: "SYSTEM",
      text: `Sentiment Analysis Summary - Positive: ${Math.round((summary.positive / summary.total) * 100)}%, Neutral: ${Math.round((summary.neutral / summary.total) * 100)}%, Negative: ${Math.round((summary.negative / summary.total) * 100)}%`,
      startTime: 0,
      sentiment: {
        score: (summary.positive - summary.negative) / summary.total,
        label: (summary.positive > summary.negative ? 'positive' : summary.negative > summary.positive ? 'negative' : 'neutral') as 'positive' | 'negative' | 'neutral'
      }
    };

    // Map regular segments with sentiment
    const segmentsWithSentiment = transcriptionResults.map(segment => ({
      speaker: segment.speaker,
      text: segment.sentiment 
        ? `[Sentiment: ${segment.sentiment.label} (${Math.round(segment.sentiment.score * 100)}%)] ${segment.text}`
        : segment.text,
      startTime: segment.startTime,
      sentiment: segment.sentiment
    }));

    // Prepend sentiment summary to the segments
    return [sentimentSummarySegment, ...segmentsWithSentiment];
  }, [transcriptionResults]);

  // Effect to notify parent of transcript updates with stable reference check
  useEffect(() => {
    const hasChanges = JSON.stringify(mappedTranscriptData) !== JSON.stringify(prevTranscriptRef.current);
    if (hasChanges) {
      prevTranscriptRef.current = mappedTranscriptData;
      onTranscriptUpdate(mappedTranscriptData);
    }
  }, [mappedTranscriptData, onTranscriptUpdate]);

  const detectTemplateVariables = useCallback((content: string) => {
    const variableRegex = /\{\{([^}]+)\}\}/g;
    const matches = Array.from(content.matchAll(variableRegex));
    const variables: TemplateVariable[] = [];

    matches.forEach(match => {
      const varName = match[1].trim();
      const systemVar = SYSTEM_VARIABLES.find((v: SystemVariable) => v.key === varName);
      
      if (systemVar) {
        variables.push({
          name: varName,
          value: '', 
          type: 'system',
          description: systemVar.description
        });
      } else {
        // Check if we already have this custom variable
        const existingCustomVar = customVariables.find((v: TemplateVariable) => v.name === varName);
        if (existingCustomVar) {
          variables.push(existingCustomVar);
        } else {
          variables.push({
            name: varName,
            value: '',
            type: 'custom',
            description: 'Custom variable'
          });
        }
      }
    });

    return variables;
  }, [customVariables]);

  const handleExportDocument = (doc: GeneratedDoc) => {
    exportToPDF({
      title: doc.name,
      content: doc.content,
      generatedAt: new Date(),
      metadata: {
        wordCount: doc.content.split(/\s+/).length,
        estimatedReadTime: Math.ceil(doc.content.split(/\s+/).length / 200) // Assuming 200 words per minute reading speed
      }
    });
  };

  return (
    <div className="LiveTranscribeTab" style={{ 
      width: '100%', 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '12px',
      background: '#fff',
      position: 'relative',
      overflow: 'hidden'
    }}>
      {/* Error Alert */}
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          closable
          onClose={() => setError(null)}
          style={{ 
            borderRadius: 12,
            border: 'none',
            boxShadow: '0 2px 8px rgba(255, 0, 0, 0.1)',
            flexShrink: 0
          }}
        />
      )}

      {/* Main Content Area with Tabs */}
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden' }}>
        <Tabs 
          activeKey={activeTab} 
          onChange={setActiveTab}
          className="live-transcribe-tabs"
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          items={[
            {
              key: 'transcript',
              label: <><AudioOutlined /> Transcript</>,
              children: (
                <div style={{ 
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  background: '#fff',
                  borderRadius: '12px',
                  border: '1px solid rgba(0, 0, 0, 0.06)',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
                  overflow: 'hidden',
                  height: '100%'
                }}>
                  {transcriptionResults.length > 0 && (
                    <div style={{ 
                      padding: '12px',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                      background: '#fafafa',
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      gap: '12px',
                      alignItems: isMobile ? 'stretch' : 'center',
                      flexShrink: 0
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        flexWrap: 'wrap'
                      }}>
                        {sentimentToggle}
                        
                        {/* Merge button */}
                        {selectedSegments.length >= 2 && (
                          <Button
                            icon={<MergeCellsOutlined />}
                            onClick={handleMergeSegments}
                            type="primary"
                          >
                            Merge {selectedSegments.length} Segments
                          </Button>
                        )}
                      </div>
                    </div>
                  )}

                  <div 
                    ref={transcriptContainerRef}
                    style={{
                      flex: 1,
                      overflowY: 'auto',
                      padding: '12px',
                      position: 'relative',
                      height: 'calc(100vh - 200px)'
                    }}
                  >
                    {transcriptionResults.length === 0 && partialResults.length === 0 ? (
                      <div style={{ 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        minHeight: '200px',
                        background: 'rgba(0, 0, 0, 0.02)',
                        borderRadius: 12,
                        flexDirection: 'column',
                        gap: 16,
                        position: 'relative',
                        margin: 0
                      }}>
                        {isRecording ? (
                          <>
                            <Spin size="large" />
                            <Text type="secondary" style={{ 
                              fontSize: '16px',
                              textAlign: 'center',
                              padding: '0 16px'
                            }}>
                              Waiting for speech...
                            </Text>
                          </>
                        ) : (
                          <>
                            <AudioOutlined style={{ fontSize: '32px', opacity: 0.45 }} />
                            <Text type="secondary" style={{ 
                              fontSize: '16px',
                              textAlign: 'center',
                              padding: '0 16px'
                            }}>
                              Click {hasExistingTranscript ? 'Append' : 'Start Recording'} to begin transcription
                            </Text>
                          </>
                        )}
                      </div>
                    ) : (
                      <div style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: '12px'
                      }}>
                        {transcriptionResults.map((segment, index) => (
                          <div key={`${segment.startTime}-${index}`}>
                            {renderTranscriptionSegment(segment, index)}
                          </div>
                        ))}
                        {partialResults.map((segment, index) => (
                          <div key={`partial-${index}`}>
                            {renderTranscriptionSegment(segment, index, true)}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )
            },
            ...(selectedTemplate ? [{
              key: 'template',
              label: (
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <FileTextOutlined /> {selectedTemplate.name}
                  <Button
                    type="text"
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      onTemplateClose?.();
                    }}
                  />
                </span>
              ),
              children: renderTemplateContent()
            }] : []),
            ...generatedDocs.map(doc => ({
              key: `doc-${doc.id}`,
              label: (
                <span style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: '8px',
                  position: 'relative',
                  padding: '4px 8px',
                  minWidth: isGeneratingDocument && doc.content === '' ? '120px' : undefined,
                  ...(doc.isGenerated && doc.content !== '' ? {
                    background: 'linear-gradient(45deg, #722ed1, #1890ff)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    animation: 'gradientShift 3s ease infinite',
                    fontWeight: 500
                  } : {})
                }}>
                  {isGeneratingDocument && doc.content === '' ? (
                    <>
                      <div className="magic-loader" style={{
                        position: 'absolute',
                        inset: -2,
                        overflow: 'hidden',
                        pointerEvents: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        gap: '8px'
                      }}>
                        <div style={{
                          width: '16px',
                          height: '16px',
                          border: '2px solid transparent',
                          borderRadius: '50%',
                          background: 'linear-gradient(white, white) padding-box, linear-gradient(45deg, #722ed1, #1890ff) border-box',
                          animation: 'magicSpin 1s linear infinite',
                          boxShadow: '0 0 10px rgba(114, 46, 209, 0.3)'
                        }} />
                        <span style={{
                          background: 'linear-gradient(45deg, #722ed1, #1890ff)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          animation: 'gradientShift 3s ease infinite',
                          fontWeight: 500
                        }}>
                          Generating...
                        </span>
                      </div>
                      <div style={{ opacity: 0 }}>
                        <FileTextOutlined /> {doc.name}
                      </div>
                    </>
                  ) : (
                    <>
                      {doc.isGenerated && doc.content !== '' && (
                        <div className="sparkles-wrapper" style={{
                          position: 'absolute',
                          inset: -2,
                          overflow: 'hidden',
                          pointerEvents: 'none'
                        }}>
                          {[...Array(20)].map((_, i) => (
                            <div
                              key={i}
                              className="sparkle"
                              style={{
                                position: 'absolute',
                                width: '2px',
                                height: '2px',
                                borderRadius: '50%',
                                backgroundColor: i % 2 === 0 ? '#722ed1' : '#1890ff',
                                left: `${Math.random() * 100}%`,
                                top: `${Math.random() * 100}%`,
                                animation: `twinkle ${0.8 + Math.random() * 1}s ease-in-out infinite ${Math.random() * 1}s`,
                                boxShadow: `0 0 ${2 + Math.random() * 3}px ${i % 2 === 0 ? '#722ed1' : '#1890ff'}`
                              }}
                            />
                          ))}
                          {[...Array(10)].map((_, i) => (
                            <div
                              key={`star-${i}`}
                              className="star-sparkle"
                              style={{
                                position: 'absolute',
                                left: `${Math.random() * 100}%`,
                                top: `${Math.random() * 100}%`,
                                animation: `starTwinkle ${1 + Math.random() * 1}s ease-in-out infinite ${Math.random() * 1}s`
                              }}
                            >
                              <div style={{
                                width: '3px',
                                height: '3px',
                                transform: 'rotate(45deg)',
                                backgroundColor: i % 2 === 0 ? '#722ed1' : '#1890ff',
                                boxShadow: `0 0 ${2 + Math.random() * 3}px ${i % 2 === 0 ? '#722ed1' : '#1890ff'}`
                              }} />
                            </div>
                          ))}
                        </div>
                      )}
                      <FileTextOutlined style={doc.isGenerated ? {
                        color: '#722ed1',
                        filter: 'drop-shadow(0 0 2px rgba(114, 46, 209, 0.3))'
                      } : undefined} />
                      {doc.name}
                    </>
                  )}
                  <Button
                    type="text"
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={(e) => handleCloseDocument(doc.id, e)}
                    style={{ marginLeft: 'auto' }}
                  />
                </span>
              ),
              children: (
                <div style={{ 
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  background: '#fff',
                  borderRadius: '12px',
                  border: '1px solid rgba(0, 0, 0, 0.06)',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
                  overflow: 'hidden',
                  padding: '16px',
                  minHeight: 0
                }}>
                  {isGeneratingDocument && doc.content === '' ? (
                    <div style={{ 
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '16px'
                    }}>
                      <Spin size="large" />
                      <Text>Generating document...</Text>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                      {doc.isGenerated && (
                        <div style={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                          paddingBottom: '12px'
                        }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Text strong>{doc.name}</Text>
                            <Tag color="blue">
                              {doc.content.split(/\s+/).length} words
                            </Tag>
                            <Tag color="blue">
                              {Math.ceil(doc.content.split(/\s+/).length / 200)} min read
                            </Tag>
                          </div>
                          <Space>
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() => {
                                navigator.clipboard.writeText(doc.content);
                                message.success('Document copied to clipboard');
                              }}
                            >
                              Copy
                            </Button>
                            <Button
                              type="primary"
                              icon={<FilePdfOutlined />}
                              onClick={() => handleExportDocument(doc)}
                            >
                              Export as PDF
                            </Button>
                          </Space>
                        </div>
                      )}
                      <Input.TextArea
                        value={doc.content}
                        readOnly
                        style={{ 
                          flex: 1,
                          resize: 'none',
                          border: 'none',
                          padding: '0',
                          fontSize: '16px',
                          lineHeight: '1.6'
                        }}
                        autoSize={{ minRows: 20 }}
                      />
                    </div>
                  )}
                </div>
              )
            }))
          ]}
        />
      </div>
    </div>
  );
};

const keyframes = `
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes twinkle {
  0%, 100% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes starTwinkle {
  0%, 100% {
    opacity: 0;
    transform: scale(0.3) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: scale(1) rotate(180deg);
  }
}

@keyframes magicSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`;

const styleTag = document.createElement('style');
styleTag.innerHTML = keyframes;
document.head.appendChild(styleTag);

export default LiveTranscribeTab; 