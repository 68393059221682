import { AxiosResponse } from 'axios';

import {
  TMessageOfTheDay,
} from 'src/interfaces/motd-interface';
import { ApiClient } from './api-client';
import { TNotificationConfig } from 'src/stores/user/user-constants';

export const getTheMessageOfTheDay = async () => {
  const response: AxiosResponse<TMessageOfTheDay> = await ApiClient.get(`/api/v1/motd/latest`);
  return response.data;
};
