import { Layout, Space } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuOutlined, CloseOutlined, AudioOutlined, FileTextOutlined } from '@ant-design/icons';
import {
  ActiveClientIcon,
  ActiveFileIcon,
  ActiveHelpIcon,
  ActiveHomeworkIcon,
  ActiveMessageIcon,
  ActivePractitionerIcon,
  ActivePsyIcon,
  ActiveReportIcon,
  AntsaLogo,
  ClientIcon,
  FileIcon,
  FullAntsaLogo,
  HelpIcon,
  HomeworkIcon,
  MessageIcon,
  PractitionerIcon,
  PsyIcon,
  ReportIcon,
} from 'src/assets/icons';
import { BaseText } from 'src/components/typography';
import { RoutePaths } from 'src/routes/routes-constants';
import { TRootState } from 'src/stores';
import { EUserType } from 'src/variables/enum-variables';
import { SIDEBAR_ITEMS_BY_TYPE } from './sidebar-constants';
import { ESidebarItemLabel, TSidebarItem } from './sidebar-types';
import './Sidebar.scss';

const { Sider } = Layout;

interface IProps {
  className?: string;
  isShow: boolean;
  onCollapse?: (collapsed: boolean) => void;
}

export const SIDEBAR_ITEMS: { [key: string]: TSidebarItem } = {
  [ESidebarItemLabel.PRACTITIONER]: {
    icon: <PractitionerIcon />,
    activeIcon: <ActivePractitionerIcon />,
    label: ESidebarItemLabel.PRACTITIONER,
    path: RoutePaths.PRACTITIONER,
  },
  [ESidebarItemLabel.MESSAGES]: {
    icon: <MessageIcon />,
    activeIcon: <ActiveMessageIcon />,
    label: ESidebarItemLabel.MESSAGES,
    path: RoutePaths.MESSAGES,
  },
  [ESidebarItemLabel.CLIENTS]: {
    icon: <ClientIcon />,
    activeIcon: <ActiveClientIcon />,
    label: ESidebarItemLabel.CLIENTS,
    path: RoutePaths.CLIENTS,
  },
  [ESidebarItemLabel.HOMEWORK]: {
    icon: <HomeworkIcon />,
    activeIcon: <ActiveHomeworkIcon />,
    label: ESidebarItemLabel.HOMEWORK,
    path: RoutePaths.HOMEWORK,
  },
  [ESidebarItemLabel.FILES]: {
    icon: <FileIcon />,
    activeIcon: <ActiveFileIcon />,
    label: ESidebarItemLabel.FILES,
    path: RoutePaths.FILES,
  },
  [ESidebarItemLabel.REPORT]: {
    icon: <ReportIcon />,
    activeIcon: <ActiveReportIcon />,
    label: ESidebarItemLabel.REPORT,
    path: RoutePaths.REPORT,
  },
  [ESidebarItemLabel.PSYCHOEDUCATION]: {
    icon: <PsyIcon />,
    activeIcon: <ActivePsyIcon />,
    label: ESidebarItemLabel.PSYCHOEDUCATION,
    path: RoutePaths.PSYCHOEDUCATION,
  },
  [ESidebarItemLabel.SESSIONS]: {
    icon: <AudioOutlined style={{ fontSize: '20px' }} />,
    activeIcon: <AudioOutlined style={{ fontSize: '20px', color: '#1976d2' }} />,
    label: ESidebarItemLabel.SESSIONS,
    path: RoutePaths.LIVE_TRANSCRIBE,
  },
  [ESidebarItemLabel.TEMPLATES]: {
    icon: <FileTextOutlined style={{ fontSize: '20px' }} />,
    activeIcon: <FileTextOutlined style={{ fontSize: '20px', color: '#1976d2' }} />,
    label: ESidebarItemLabel.TEMPLATES,
    path: RoutePaths.TEMPLATES,
  },
};

const Sidebar = ({ className, isShow, onCollapse }: IProps) => {
  if (!isShow) {
    return null;
  }

  const navigate = useNavigate();
  const location = useLocation();
  const currentProfile = useSelector((state: TRootState) => state.user.profile);
  const currentProfileType = currentProfile?.role ?? EUserType.CLINIC_OWNER;
  const menuItems = SIDEBAR_ITEMS_BY_TYPE[currentProfileType]?.map((item: ESidebarItemLabel) => SIDEBAR_ITEMS[item]) || [];
  
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCollapse = (value: boolean) => {
    if (!isMobile) {
      setCollapsed(value);
      onCollapse?.(value);
    }
  };

  const toggleMobileMenu = () => {
    if (isMobile) {
      const newCollapsed = !collapsed;
      setCollapsed(newCollapsed);
      onCollapse?.(newCollapsed);
    }
  };

  return (
    <>
      {isMobile && (
        <button 
          className={`Sidebar__mobile-toggle ${!collapsed ? 'active' : ''}`}
          onClick={toggleMobileMenu}
          aria-label="Toggle menu"
        >
          {collapsed ? <MenuOutlined /> : <CloseOutlined />}
        </button>
      )}
      <Sider
        className={`Sidebar ${className ?? ''} ${isMobile ? 'mobile' : ''} ${!collapsed ? 'expanded' : ''}`}
        theme="light"
        width={340}
        collapsedWidth={isMobile ? 0 : 96}
        collapsed={collapsed}
        onCollapse={handleCollapse}
        onMouseEnter={() => {
          if (!isMobile && collapsed) {
            setIsHovered(true);
            setCollapsed(false);
          }
        }}
        onMouseLeave={() => {
          if (!isMobile && isHovered) {
            setIsHovered(false);
            setCollapsed(true);
          }
        }}
      >
        <Space className="Sidebar__logo" size={15} onClick={() => navigate(RoutePaths.HOME)}>
          <AntsaLogo />
          <FullAntsaLogo />
        </Space>
        <div className="Sidebar__menu">
          {menuItems.map((menu: TSidebarItem, index: number) => (
            <SidebarItem 
              item={menu} 
              key={index} 
              isActive={location.pathname === menu.path}
              onClick={() => isMobile && setCollapsed(true)}
            />
          ))}
        </div>
      </Sider>
      {isMobile && !collapsed && (
        <div className="Sidebar__overlay" onClick={toggleMobileMenu} />
      )}
    </>
  );
};

interface ISidebarItem {
  isActive?: boolean;
  item: TSidebarItem;
  onClick?: () => void;
}

const SidebarItem = ({ item, isActive, onClick }: ISidebarItem) => {
  const { label, icon, activeIcon, path } = item;
  return (
    <Link to={path} replace onClick={onClick}>
      <div className={`SidebarItem ${isActive ? 'active' : ''}`}>
        <span className="active-icon">{activeIcon}</span>
        <span className="icon">{icon}</span>
        <BaseText className="SidebarItem__label" type="subHeading">
          {label}
        </BaseText>
      </div>
    </Link>
  );
};

export default Sidebar;
export { SidebarItem };
