import { THomeworkSummaryScore } from 'src/interfaces/clients-interface';

export interface DassMetrics {
  current: number;
  min: number;
  max: number;
  average: number;
  trend: 'improving' | 'worsening' | 'stable';
  changePercent: number;
}

export interface DassStatistics {
  depression: DassMetrics;
  anxiety: DassMetrics;
  stress: DassMetrics;
}

export const calculateDassStatistics = (data: Array<{ 
  createdAt: string; 
  summary: THomeworkSummaryScore;
}>): DassStatistics => {
  if (!data.length) {
    return {
      depression: getEmptyMetrics(),
      anxiety: getEmptyMetrics(),
      stress: getEmptyMetrics()
    };
  }

  const sortedData = [...data].sort((a, b) => 
    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  return {
    depression: calculateMetrics(sortedData, 'Depression'),
    anxiety: calculateMetrics(sortedData, 'Anxiety'),
    stress: calculateMetrics(sortedData, 'Stress')
  };
};

const getEmptyMetrics = (): DassMetrics => ({
  current: 0,
  min: 0,
  max: 0,
  average: 0,
  trend: 'stable',
  changePercent: 0
});

const calculateMetrics = (
  data: Array<{ createdAt: string; summary: THomeworkSummaryScore }>,
  metric: 'Depression' | 'Anxiety' | 'Stress'
): DassMetrics => {
  const scores = data.map(entry => entry.summary?.[metric]?.score || 0);
  
  const current = scores[scores.length - 1];
  const min = Math.min(...scores);
  const max = Math.max(...scores);
  const average = scores.reduce((a, b) => a + b, 0) / scores.length;

  // Calculate trend using linear regression
  const xValues = Array.from({ length: scores.length }, (_, i) => i);
  const slope = calculateSlope(xValues, scores);
  
  // Calculate percent change from first to last assessment
  const firstScore = scores[0];
  const lastScore = scores[scores.length - 1];
  const changePercent = firstScore === 0 ? 0 : 
    ((lastScore - firstScore) / firstScore) * 100;

  return {
    current,
    min,
    max,
    average: Math.round(average * 10) / 10,
    trend: slope < -0.1 ? 'improving' : slope > 0.1 ? 'worsening' : 'stable',
    changePercent: Math.round(changePercent * 10) / 10
  };
};

const calculateSlope = (x: number[], y: number[]): number => {
  const n = x.length;
  if (n < 2) return 0;

  const sumX = x.reduce((a, b) => a + b, 0);
  const sumY = y.reduce((a, b) => a + b, 0);
  const sumXY = x.reduce((sum, xi, i) => sum + xi * y[i], 0);
  const sumXX = x.reduce((sum, xi) => sum + xi * xi, 0);

  return (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
}; 